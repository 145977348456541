import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import errorHandler from "../../../utils/errorHandler";
import referralsService from "../../../service/referralsService";
import { useMutation } from "@tanstack/react-query";
import BTNLoader from "../../../lib/components/BTNLoader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import pathnames from "../../../constants/pathnames";
import { Close } from "@mui/icons-material";

const initialValues = {
  description: "",
  type: "MANUAL",
  referrers: [],
  remunerationSelect: "",
};
const ProcessPayout = ({ referrers, isOpen, onClose, remunerationSelect }) => {
  const navigate = useNavigate();
  const [values, setValues] = React.useState(initialValues);

  React.useEffect(() => {
    if (!isOpen) return;
    setValues((prev) => ({
      ...prev,
      referrers: referrers,
      remunerationSelect: remunerationSelect,
    }));
  }, [isOpen, referrers, remunerationSelect]);

  const mutation = useMutation({
    mutationFn: (data) => referralsService.createPayoutWithRem(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      toast.success("Payout created successfully...");
      navigate(`${pathnames.PAYOUTS__PROCESS}/${data?.data?.id}`);
    },
  });

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      maxWidth="sm"
      PaperProps={{ component: "form", onSubmit: save }}
    >
      <div className="d-flex justify-content-between gap-4 p-3 py-2 pe-2">
        <div>
          <div className="fs-5 fw-medium">Payouts</div>
          <div className="fs-sm text-secondary">
            Issue Payments to Referrals from here.{" "}
          </div>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </div>
      <Divider className="bg-secondary-subtle" />
      <DialogContent>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
          <div className="">
            <label htmlFor="name">
              Payout Description <span className="text-danger">*</span>
            </label>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              required
              multiline
              rows={4}
              value={values?.description}
              InputProps={{ className: "bg-white" }}
              onChange={(e) => handleChange("description", e.target.value)}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} type="button">
          Cancel
        </Button>
        <Button
          disabled={mutation.isPending}
          startIcon={<BTNLoader isLoading={mutation.isPending} />}
          variant="contained"
          color="secondary"
          type="submit"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  function handleClose() {
    onClose();
    setValues(null);
  }

  function handleChange(name, value) {
    setValues((prev) => ({ ...prev, [name]: value }));
  }

  function save(e) {
    e.preventDefault();
    mutation.mutate(values);
  }
};

export default ProcessPayout;
