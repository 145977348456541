import React from "react";
import {
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import notificationService from "../../service/notificationService";
import { useQuery } from "@tanstack/react-query";
import EmptyState from "../../lib/components/EmptyState";
import moment from "moment";
import PreloaderTable from "../../lib/components/PreloaderTable";
import AppSelectField from "../../lib/components/AppSelectField";
import scrollHandler from "../../utils/scrollHandler";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

const AdminLogs = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "20";

  const logsQuery = useQuery({
    retry: false,
    queryKey: ["admin-logs", page, limit],
    queryFn: () => notificationService.get({ page, limit }),
  });

  const { isPending, data } = logsQuery;
  const logsData = data?.data?.data;

  return (
    <AppLayout>
      <Header title="Administrators Logs" description="" />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <IconButton
          className="bg-secondary-subtle mb-3"
          onClick={() => navigate(-1)}
        >
          <ArrowBack />
        </IconButton>
        {isPending ? (
          <PreloaderTable isLoading />
        ) : (
          <Paper>
            <EmptyState isEmpty={!isPending && !logsData?.length} />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Notifications</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logsData?.map((item, index) => (
                    <TableRow
                      key={index}
                      className={index % 2 === 0 ? "" : "bg-secondary-subtle"}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <span className="fw-medium">
                          {item?.replacers[0]?.name}
                        </span>
                        <span className="">
                          {item?.text
                            ?.replace("$1", "")
                            .replace("$2", item?.replacers[1]?.name)
                            .replace("$3", item?.replacers[2]?.name)
                            .replace("$4", item?.replacers[3]?.name)}
                        </span>
                      </TableCell>
                      <TableCell>{moment(item?.createdAt).fromNow()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
        <div className="my-3">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div>
              <AppSelectField
                value={limit}
                placeholder="Limit"
                onChange={(e) => handleChange(e, "limit")}
                items={[
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                ]}
              />
            </div>
            <div>
              <Pagination
                page={Number(page)}
                count={data?.data?.totalPages}
                onChange={(_, value) => {
                  handleChange(value, "page");
                }}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );

  function handleChange(e, name = "") {
    const value = name.includes("date")
      ? moment(e).format("YYYY-MM-DD")
      : name === "page"
      ? e
      : e.target.value;
    searchParams.set(name, value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
    if (name === "page") scrollHandler();
  }
};

export default AdminLogs;
