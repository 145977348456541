import React from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EmptyState from "../../../lib/components/EmptyState";

const UsersDataTable = ({ data, isLoading, onActivate, onDeactivate }) => {
  if (isLoading) return null;
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className="bg-light">
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {item?.firstName} {item?.lastName}
                </TableCell>
                <TableCell>{item?.email}</TableCell>
                <TableCell>
                  <Status status={item?.status} />
                </TableCell>
                <TableCell align="center">
                  {item?.status === "ACTIVE" ? (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => onDeactivate(item, "DEACTIVATE")}
                    >
                      Deactivate
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => onActivate(item, "ACTIVATE")}
                    >
                      Make Active
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </Paper>
  );
};

function Status({ status }) {
  if (status === "ACTIVE")
    return (
      <div className="badge rounded-pill  fw-medium fs-sm bg-primary text-primary bg-opacity-25">
        Active
      </div>
    );
  if (status === "INACTIVE")
    return (
      <div className="badge bg-danger text-danger rounded-pill fw-medium fs-sm bg-opacity-25">
        Inactive
      </div>
    );
  return null;
}

export default UsersDataTable;
