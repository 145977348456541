import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import Layout from "./components/Layout";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UPDATE_ADMIN_VALIDATION } from "../../validations/admins";
import { useMutation } from "@tanstack/react-query";
import userservice from "../../service/userservice";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import { AppContext } from "../../context/app-context";
import { Avatar, Button, TextField } from "@mui/material";
import { Upload } from "@mui/icons-material";
import BTNLoader from "../../lib/components/BTNLoader";
import _ from "lodash";

const defaultValues = {
  firstName: "",
  lastName: "",
  image: null,
  email: "",
};
const UserProfile = () => {
  const imageRef = React.useRef();
  const { refetch, user } = React.useContext(AppContext);
  const [imageURL, setImageURL] = React.useState("");

  const formHook = useForm({
    resolver: yupResolver(UPDATE_ADMIN_VALIDATION),
    mode: "all",
    defaultValues: defaultValues,
  });

  const errors = formHook.formState.errors;
  const control = formHook.control;
  const handleSubmit = formHook.handleSubmit;
  const reset = formHook.reset;

  React.useEffect(() => {
    if (!user) return;
    setImageURL(user?.avatarUploadMeta?.url);
    reset({ ..._.pick(user, "firstName", "lastName", "email"), image: "" });
  }, [user, reset]);

  const mutation = useMutation({
    mutationFn: (data) => userservice.update(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      onCreateSuccess(data);
    },
  });

  async function save(data) {
    mutation.mutate(data);
  }
  const { isPending } = mutation;
  return (
    <AppLayout>
      <Header
        title="Settings"
        description="Customize your account preferences and general settings here."
      />
      <Layout>
        <div className="p-4">
          <form onSubmit={handleSubmit(save)} style={{ maxWidth: 450 }}>
            <div className="mb-4">
              <div className="d-flex gap-3 align-items-center">
                <Avatar
                  style={{ width: 80, height: 80 }}
                  onClick={() => imageRef.current.click()}
                  src={imageURL}
                />

                <Button
                  onClick={() => imageRef.current.click()}
                  variant="outlined"
                  className="mb-2"
                  startIcon={<Upload />}
                >
                  Choose File
                </Button>
              </div>
              <Controller
                name="image"
                control={control}
                render={({ field }) => (
                  <input
                    className="d-none"
                    ref={imageRef}
                    type="file"
                    name="image"
                    id="image"
                    accept=".jpg, .png, .jpeg, .gif"
                    onChange={(e) => {
                      const image = e.target.files[0];
                      const imageURL = URL.createObjectURL(image);
                      field.onChange(image);
                      setImageURL(imageURL);
                    }}
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <label className="d-block">
                First Name
                <span className="text-danger">*</span>
              </label>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="Enter First Name"
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    helperText={errors["firstName"]?.message}
                    error={!!errors["firstName"]}
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <label className="d-block">
                Last Name
                <span className="text-danger">*</span>
              </label>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="Enter Last Name"
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    helperText={errors["lastName"]?.message}
                    error={!!errors["lastName"]}
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <label className="d-block">
                Email
                <span className="text-danger">*</span>
              </label>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="email"
                    size="small"
                    variant="outlined"
                    disabled
                    value={field.value}
                    onChange={(e) => null}
                    className="bg-light"
                  />
                )}
              />
            </div>
            <div className="mb-4 d-flex justify-content-end">
              <Button
                type="submit"
                disabled={isPending}
                startIcon={<BTNLoader isLoading={isPending} />}
                variant="contained"
                color={"secondary"}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </Layout>
    </AppLayout>
  );

  function onCreateSuccess(data) {
    toast.success(`Your profile is successfully updated`);
    refetch();
    reset(defaultValues);
  }
};

export default UserProfile;
