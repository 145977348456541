import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";
import paymentService from "../../service/paymentService";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import AppSelectField from "../../lib/components/AppSelectField";
import { Chip, InputAdornment, Pagination, Tab, Tabs } from "@mui/material";
import moment from "moment";
import PreloaderTable from "../../lib/components/PreloaderTable";
import PaymentsDataTable from "./components/payments-data-table";
import AppDateField from "../../lib/components/AppDateField";
import AppTextField from "../../lib/components/AppTextField";
import { Search } from "@mui/icons-material";
import scrollHandler from "../../utils/scrollHandler";

const tabList = [
  { label: "All", value: "ALL" },
  { label: "Monthly", value: "MONTHLY" },
  { label: "Quarterly", value: "QUARTERLY" },
  { label: "Bi-annually", value: "BIANNUALLY" },
  { label: "Annually", value: "ANNUALLY" },
];

const Payments = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "20";
  const period = searchParams.get("period") || "";
  const keyword = searchParams.get("keyword") || "";
  const dateFrom = searchParams.get("dateFrom") || null;
  const dateTo = searchParams.get("dateTo") || null;
  const status = searchParams.get("status") || "";

  const qKeys = [page, limit, period, dateFrom, dateTo, status];
  const queries = { page, limit, period, keyword, dateFrom, dateTo, status };

  const paymentsQuery = useQuery({
    retry: false,
    queryKey: ["payments", ...qKeys],
    queryFn: () => paymentService.get({ ...queries }),
  });

  const statsQuery = useQuery({
    retry: false,
    queryKey: ["payment-stats"],
    queryFn: paymentService.getInvoicesStats,
  });

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      paymentsQuery.refetch();
    }, 1000);
    return () => clearTimeout(timeoutId);
    //eslint-disable-next-line
  }, [keyword]);

  const { isPending: isLoading, data } = paymentsQuery;
  const { data: stats } = statsQuery;

  const paymentsData = data?.data;
  const statsData = stats?.data?.data;
  return (
    <AppLayout>
      <Header
        title="Payment History"
        description="See all payments made for the Jureb Subscription"
      />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <div className="row mb-5">
          <div className="col-6 col-md-3 col-lg-4 mb-3">
            <div>
              <AppTextField
                placeholder="Enter Text Here..."
                value={keyword}
                onChange={(e) => handleChange(e, "keyword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search className="text-secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppDateField
                label="Date From"
                value={dateFrom}
                onChange={(e) => handleChange(e, "dateFrom")}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppDateField
                label="Date To"
                value={dateTo}
                minDate={dateFrom}
                onChange={(e) => handleChange(e, "dateTo")}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppSelectField
                value={status}
                placeholder="Status"
                onChange={(e) => handleChange(e, "status")}
                items={[
                  { label: "All", value: "ALL" },
                  { label: "Success", value: "SUCCESS" },
                  { label: "Failed", value: "FAILED" },
                  { label: "Cancelled", value: "CANCELLED" },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="border-bottom mb-5">
          <Tabs value={period || "ALL"} onChange={handleChangeTab}>
            {tabList?.map((item, index) => {
              let value = 0;
              if (statsData) {
                value = statsData[item?.value.toLowerCase()];
              }
              return (
                <Tab
                  key={index}
                  value={item?.value}
                  label={<TabLabel title={item?.label} label={value} />}
                />
              );
            })}
          </Tabs>
        </div>
        <PreloaderTable isLoading={isLoading} />
        <PaymentsDataTable isLoading={isLoading} data={paymentsData?.data} />
        <div className="my-3">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div>
              <AppSelectField
                value={limit}
                placeholder="Limit"
                onChange={(e) => handleChange(e, "limit")}
                items={[
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                ]}
              />
            </div>
            <div>
              <Pagination
                page={Number(page)}
                count={paymentsData?.totalPages}
                onChange={(_, value) => {
                  handleChange(value, "page");
                }}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );

  function handleChangeTab(_, value) {
    searchParams.set("period", value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }

  function handleChange(e, name = "") {
    const value = name.includes("date")
      ? moment(e).format("YYYY-MM-DD")
      : name === "page"
      ? e
      : e.target.value;
    searchParams.set(name, value);
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    if (name === "page") scrollHandler();
  }
};

function TabLabel({ title, label }) {
  return (
    <div className="d-flex gap-3 align-items-center">
      <span>{title}</span>
      <Chip label={label} />
    </div>
  );
}

export default Payments;
