import React from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";

const AppDateField = ({
  onChange = (e) => null,
  value,
  maxDate = undefined,
  minDate = undefined,
  label = "",
  required = false,
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        minDate={minDate}
        maxDate={maxDate}
        label={label}
        inputFormat="MM/dd/yyyy"
        value={value}
        className="bg-white"
        onChange={onChange}
        {...props}
        renderInput={(params) => (
          <TextField fullWidth size="small" {...params} required={required} />
        )}
      />
    </LocalizationProvider>
  );
};

export default AppDateField;
