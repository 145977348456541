import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";
import errorHandler from "../../../utils/errorHandler";
import referralsService from "../../../service/referralsService";
import { useMutation } from "@tanstack/react-query";
import { Close, CloudUploadOutlined } from "@mui/icons-material";
import BTNLoader from "../../../lib/components/BTNLoader";
import { toast } from "react-toastify";
import SheetReader from "../../../service/sheetReader";
import AppDateField from "../../../lib/components/AppDateField";

const initialValues = {
  file: null,
  data: [],
  paidAt: null,
  fieldNames: [
    "Account Name",
    "Account Number",
    "Amount",
    "Amount Paid",
    "Bank",
    "Referrer Name",
    "Remuneration ID",
  ],
};
const CompletePayoutModal = ({ payout, isOpen, onClose, id, refetch }) => {
  const [values, setValues] = React.useState(initialValues);

  React.useEffect(() => {
    if (!values.file) return;
    handleSheetReader();
    //eslint-disable-next-line
  }, [values.file]);

  const mutation = useMutation({
    mutationFn: (data) => referralsService.completePayout(data, id),
    onError: ({ response }) => errorHandler(response),
    onSuccess: () => {
      refetch();
      toast.success("Payout completed successfully...");
      handleClose();
    },
  });

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      maxWidth="sm"
      PaperProps={{ component: "form", onSubmit: save }}
    >
      <div className="d-flex justify-content-between gap-4 p-3 py-2 pe-2">
        <div>
          <div className="fs-5 fw-medium">Payouts</div>
          <div className="fs-sm text-secondary">
            Issue Payments to Referrals from here.{" "}
          </div>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </div>
      <Divider className="bg-secondary-subtle" />
      <DialogContent>
        <div className="mb-3">
          <label htmlFor="name">
            Payment Date <span className="text-danger">*</span>
          </label>
          <AppDateField
            required
            label=""
            value={values.paidAt}
            onChange={(e) => handleChange(e, "paidAt")}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="name">
            Select File <span className="text-danger">*</span>
          </label>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            required
            type="file"
            onChange={(e) => handleChange(e, "file")}
            // onClick={(e) => (e.target.value = "")}
            inputProps={{
              accept: SheetReader.accept,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CloudUploadOutlined style={{ color: "#777" }} />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} type="button">
          Cancel
        </Button>
        <Button
          disabled={mutation.isPending}
          startIcon={<BTNLoader isLoading={mutation.isPending} />}
          variant="contained"
          color="secondary"
          type="submit"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  function handleClose() {
    onClose();
    setValues((prev) => ({ ...prev, file: null, data: [] }));
  }

  function save(e) {
    e.preventDefault();
    const payload = {
      id: id,
      paidAt: values.paidAt,
      remunerations: values.data.map((item) => {
        const data = {};
        data.id = item["Remuneration ID"];
        data.amount = item["Amount"];
        data.amountPaid = item["Amount Paid"];
        return data;
      }),
    };
    mutation.mutate(payload);
  }

  async function handleSheetReader() {
    const parameters = [values.file, values.fieldNames];
    const result = await SheetReader.read(...parameters);
    if (!result) return;
    setValues((prev) => ({ ...prev, data: result }));
  }

  function handleChange(e, name) {
    if (name === "file") {
      const file = e.target.files[0];
      if (!file) return;
      setValues((prev) => ({ ...prev, file: file }));
      return;
    }
    if (["paidAt"].includes(name)) {
      setValues((prev) => ({ ...prev, [name]: e }));
      return;
    }
    const value = e?.target?.value;
    setValues((prev) => ({ ...prev, [name]: value }));
  }
};

export default CompletePayoutModal;
