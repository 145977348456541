import { Avatar, Divider, Paper } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import pathnames from "../../../constants/pathnames";
import { ArrowForwardIos } from "@mui/icons-material";
import moment from "moment";

const RecentSubscribers = ({ data }) => {
  return (
    <Paper className="h-100 d-flex flex-column">
      <div className="d-flex p-3 gap-4 justify-content-between align-items-end">
        <div>
          <div className="fs-5 fw-medium">Recent Subscribers</div>
          <div className="fs-sm">Report Center</div>
        </div>
      </div>
      <Divider />
      <div className="d-flex flex-column justify-content-between">
        <div className="p-3 h-100">
          {data?.map((item, index) => {
            return (
              <div
                key={index}
                className="d-flex gap-4 mb-3 justify-content-between"
              >
                <div className="d-flex align-items-center gap-3">
                  <Avatar src={item?.organizationLogo} className="bg-primary">
                    {item?.organizationName?.charAt(0)}
                  </Avatar>
                  <div>
                    <div className="fw-medium fs-sm">
                      {item?.organizationName}
                    </div>
                    <div className="fs-xs">
                      {moment(item?.date).format("Do MMM, YYYY")}
                    </div>
                  </div>
                </div>
                <div>
                  <Link to={`/organizations/${item?.organizationId}`}>
                    View
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-auto">
        <Divider />
        <div className="p-3 text-end">
          <Link to={pathnames.ORGANIZATIONS}>
            See all <ArrowForwardIos className="fs-6" />
          </Link>
        </div>
      </div>
    </Paper>
  );
};

export default RecentSubscribers;
