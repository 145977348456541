import { MenuItem, Paper, Select } from "@mui/material";
import React from "react";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

const DailySubscribers = ({ period, setPeriod, data }) => {
  return (
    <Paper className="p-3 h-100">
      <div className="d-flex gap-4 justify-content-between align-items-end">
        <div>
          <div className="fs-5 fw-medium">Daily Subscribers</div>
          <div className="fs-sm">
            View how many subscribers you have daily, weekly and monthly
          </div>
        </div>
        <div>
          <Select
            fullWidth
            size="small"
            displayEmpty
            value={period}
            inputProps={{ className: "bg-white" }}
            onChange={(e) => setPeriod(e.target.value)}
          >
            <MenuItem value="DAILY">Daily</MenuItem>
            <MenuItem value="MONTHLY">Monthly</MenuItem>
            <MenuItem value="ANNUALLY">Annually</MenuItem>
          </Select>
        </div>
      </div>
      <div className="w-100 overflow-auto mt-3">
        <BarChart width={650} height={300} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="dateNameShort" />
          <YAxis dataKey="count" />
          <Bar barSize={30} dataKey="count" fill="#6C63F0" />
        </BarChart>
      </div>
    </Paper>
  );
};

export default DailySubscribers;
