import { Button, Paper, TextField } from "@mui/material";
import React from "react";
import JurebLogo from "../../lib/assets/images/jureb-logo.png";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FORGOT_PASS_VALIDATION } from "../../validations/auth";
import { useMutation } from "@tanstack/react-query";
import authservice from "../../service/authservice";
import BTNLoader from "../../lib/components/BTNLoader";
import errorHandler from "../../utils/errorHandler";
import { Link, useNavigate } from "react-router-dom";
import pathnames from "../../constants/pathnames";

const defaultValues = {
  email: "",
};

const ForgotPassword = () => {
  const navigate = useNavigate();

  const formHook = useForm({
    resolver: yupResolver(FORGOT_PASS_VALIDATION),
    mode: "all",
    defaultValues: defaultValues,
  });

  const errors = formHook.formState.errors;
  const control = formHook.control;
  const handleSubmit = formHook.handleSubmit;

  const mutation = useMutation({
    mutationFn: (data) => authservice.forgotPassword(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: () => {
      navigate(pathnames.FORGOT_PASSWORD_SUCCESS, { replace: true });
    },
  });

  async function save(data) {
    mutation.mutate(data);
  }

  const { isPending } = mutation;
  return (
    <div
      style={{ minHeight: "100vh" }}
      className="d-flex align-items-center bg-light"
    >
      <div className="container-xxl">
        <form action="" onSubmit={handleSubmit(save)}>
          <Paper className="border mx-auto my-5" style={{ maxWidth: 600 }}>
            <div className="p-3 mx-auto my-5" style={{ maxWidth: 400 }}>
              <div className="d-flex justify-content-center mb-3">
                <img src={JurebLogo} alt="Jureb" />
              </div>
              <div className="text-center fw-medium fs-4">Forgot Password</div>
              <div className="text-center  mb-4 fs-sm">
                Enter the email address you used to register with Jureb and
                we'll send you instructions to reset your password.
              </div>
              <div className="mb-5">
                <label htmlFor="" className="">
                  Email Address<span className="text-danger">*</span>
                </label>

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="email"
                      fullWidth
                      variant="outlined"
                      placeholder="Enter email address"
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      helperText={errors["email"]?.message}
                      error={!!errors["email"]}
                    />
                  )}
                />
              </div>

              <div className="mb-3 mx-auto" style={{ maxWidth: 300 }}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isPending}
                  variant="contained"
                  color="secondary"
                  startIcon={<BTNLoader isLoading={isPending} />}
                >
                  Send
                </Button>
                <div className="my-3 text-center">
                  <Link to={pathnames.SIGN_IN} className="text-primary">
                    Back to Login
                  </Link>
                </div>
              </div>
            </div>
          </Paper>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
