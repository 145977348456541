import React from "react";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import pathnames from "../constants/pathnames";

const cookie = Cookies.get("administrator_access_auth");
const PrivateRoute = ({ Component }) => {
  return !!cookie ? <Component /> : <Navigate to={pathnames.SIGN_IN} />;
};

export default PrivateRoute;
