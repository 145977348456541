import { Button, Paper, TextField } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import JurebLogo from "../../lib/assets/images/jureb-logo.png";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RESET_PASS_VALIDATION } from "../../validations/auth";
import { useMutation } from "@tanstack/react-query";
import authservice from "../../service/authservice";
import BTNLoader from "../../lib/components/BTNLoader";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import pathnames from "../../constants/pathnames";

const defaultValues = {
  password: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);

  const formHook = useForm({
    resolver: yupResolver(RESET_PASS_VALIDATION),
    mode: "all",
    defaultValues: defaultValues,
  });

  const errors = formHook.formState.errors;
  const control = formHook.control;
  const handleSubmit = formHook.handleSubmit;
  const token = searchParams.get("token");

  const mutation = useMutation({
    mutationFn: (data) => authservice.resetPassword(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: () => {
      toast.success("Password reset successful, signin to continue");
      setTimeout(() => navigate(pathnames.SIGN_IN, { replace: true }), 3000);
    },
  });

  const { isPending } = mutation;
  return (
    <div
      style={{ minHeight: "100vh" }}
      className="d-flex align-items-center bg-light"
    >
      <div className="container-xxl">
        <form action="" onSubmit={handleSubmit(save)}>
          <Paper className="border mx-auto my-5" style={{ maxWidth: 600 }}>
            <div className="p-3 mx-auto my-5" style={{ maxWidth: 400 }}>
              <div className="d-flex justify-content-center mb-3">
                <img src={JurebLogo} alt="Jureb" />
              </div>
              <div className="text-center fw-medium fs-4 mb-4">
                Reset Password
              </div>

              <div className="mb-3">
                <label htmlFor="">
                  Enter New Password<span className="text-danger">*</span>
                </label>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="password"
                      fullWidth
                      variant="outlined"
                      placeholder="Enter password"
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      helperText={errors["password"]?.message}
                      error={!!errors["password"]}
                    />
                  )}
                />
              </div>
              <div className="mb-5">
                <label htmlFor="">
                  Confirm Password<span className="text-danger">*</span>
                </label>
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="password"
                      fullWidth
                      variant="outlined"
                      placeholder="Confirm Password"
                      value={field.confirmPassword}
                      onChange={(e) => field.onChange(e.target.value)}
                      helperText={errors["confirmPassword"]?.message}
                      error={!!errors["confirmPassword"]}
                    />
                  )}
                />
              </div>

              <div className="mb-3 mx-auto" style={{ maxWidth: 300 }}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isPending}
                  variant="contained"
                  color="secondary"
                  startIcon={<BTNLoader isLoading={isPending} />}
                >
                  Save
                </Button>
                <div className="my-3 text-center">
                  <Link to={pathnames.SIGN_IN} className="text-primary">
                    Back to Login
                  </Link>
                </div>
              </div>
            </div>
          </Paper>
        </form>
      </div>
    </div>
  );

  async function save(data) {
    delete data.confirmPassword;
    data.token = token;
    mutation.mutate(data);
  }
};

export default ResetPassword;
