import React from "react";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";

const AppSelectField = ({
  value,
  onChange = (e) => null,
  items = [],
  placeholder,
  helperText = undefined,
  error = false,
  ...props
}) => {
  return (
    <FormControl error={error} fullWidth>
      <Select
        fullWidth
        size="small"
        displayEmpty
        value={value}
        onChange={onChange}
        inputProps={{ className: "bg-white" }}
        {...props}
      >
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
        {items?.map((item, index) => (
          <MenuItem value={item?.value} key={index}>
            {item?.label}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default AppSelectField;
