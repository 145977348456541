import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";
import errorHandler from "../../../utils/errorHandler";
import referralsService from "../../../service/referralsService";
import { useMutation } from "@tanstack/react-query";
import { Close } from "@mui/icons-material";
import BTNLoader from "../../../lib/components/BTNLoader";
import NumberFormatCustom from "../../../lib/components/CurrencyFormat";
import { toast } from "react-toastify";

const initialValues = {
  id: "",
  amount: "",
  referrerMeta: null,
  amountPayable: "",
};
const UpdateRemuneration = ({ referrer, isOpen, onClose, id, refetch }) => {
  const [values, setValues] = React.useState(initialValues);

  React.useEffect(() => {
    if (!isOpen) return;
    setValues({
      id: referrer?.id,
      amount: referrer?.amount,
      referrerMeta: referrer?.referrerMeta,
      amountPayable: referrer?.amountPayable,
    });
    //eslint-disable-next-line
  }, [isOpen, referrer]);

  const mutation = useMutation({
    mutationFn: (data) => referralsService.updatePayoutRemunerations(data, id),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      toast.success("Payout updated successfully...");
      refetch();
      onClose();
    },
  });
  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      maxWidth="sm"
      PaperProps={{ component: "form", onSubmit: save }}
    >
      <div className="d-flex justify-content-between gap-4 p-3 py-2 pe-2">
        <div>
          <div className="fs-5 fw-medium">Payouts</div>
          <div className="fs-sm text-secondary">
            Issue Payments to Referrals from here.{" "}
          </div>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </div>
      <Divider className="bg-secondary-subtle" />
      <DialogContent>
        <div className="mb-3">
          <label htmlFor="name">Name</label>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            value={`${values?.referrerMeta?.firstName} ${values?.referrerMeta?.lastName}`}
            InputProps={{ readOnly: true, className: "bg-light" }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="name">Total Available Amount</label>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            value={values?.amountPayable}
            InputProps={{
              readOnly: true,
              className: "bg-light",
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">
                  <span>₦</span>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="name">Payout Amount</label>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            required
            value={values?.amount}
            onChange={(e) => handleChange("amount", e.target.value)}
            InputProps={{
              className: "bg-white",
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">
                  <span>₦</span>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="border p-3 bg-light rounded">
          <div className="mb-3 d-flex justify-content-between">
            <div className="fs-sm">Bank Name</div>
            <div className="fw-medium fs-sm">{values?.referrerMeta?.bank}</div>
          </div>
          <div className="mb-3 d-flex justify-content-between">
            <div className="fs-sm">Account Name</div>
            <div className="fw-medium fs-sm">
              {values?.referrerMeta?.bankAccountName}
            </div>
          </div>
          <div className="mb-3 d-flex justify-content-between">
            <div className="fs-sm">Account Number</div>
            <div className="fw-medium fs-sm">
              {values?.referrerMeta?.bankAccountNumber}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} type="button">
          Cancel
        </Button>
        <Button
          disabled={mutation.isPending}
          startIcon={<BTNLoader isLoading={mutation.isPending} />}
          variant="contained"
          color="secondary"
          type="submit"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  function handleClose() {
    onClose();
    setValues(null);
  }

  function handleChange(name, value) {
    setValues((prev) => ({ ...prev, [name]: value }));
  }

  function save(e) {
    e.preventDefault();
    mutation.mutate([values]);
  }
};

export default UpdateRemuneration;
