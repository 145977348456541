import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EmptyState from "../../../lib/components/EmptyState";
import { Delete, Edit } from "@mui/icons-material";
import { moneyFormat } from "../../../utils/moneyFormat";

const CurrenciesDataTable = ({ data, isLoading, onEdit, onDelete }) => {
  if (isLoading) return null;
  return (
    <React.Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Currency</TableCell>
              <TableCell>Value</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{item?.currencyName}</TableCell>
                {item?.currencyName === "NGN" ? (
                  <TableCell>1</TableCell>
                ) : (
                  <TableCell>
                    <span className="fw-medium">1 {item?.currencyName} </span>
                    equals{" "}
                    <span className="fw-medium">
                      {moneyFormat(1 / item?.currencyValue, "NGN")} Nigerian
                      Naira
                    </span>{" "}
                  </TableCell>
                )}

                <TableCell align="center">
                  {item?.currencyName === "NGN" ? null : (
                    <div className="d-flex justify-content-center gap-3">
                      <IconButton
                        title={`Edit currency (${item?.currencyName})`}
                        size="small"
                        onClick={() => onEdit(item)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        title={`Delete currency (${item?.currencyName})`}
                        size="small"
                        color="error"
                        onClick={() => onDelete(item)}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </React.Fragment>
  );
};

export default CurrenciesDataTable;
