import fileDownloadService from "./fileDownloadService";
import httpservice from "./httpservice";

class ReferralService {
  constructor() {
    this.endpoints = {
      plans: "/admin/cp/billing/plans",
      referrer: "/admin/cp/referral/referrers/single",
      referrers: "/admin/cp/referral/referrers",
      referral: "/admin/cp/referral/referrals/single",
      referrals: "/admin/cp/referral/referrals",
      earnings: "/admin/cp/referral/earnings",
      referralsStats: "/admin/cp/referral/referrals/plan-stats",
      commission: "/admin/cp/billing/single",
      payouts: "/admin/cp/referral/payouts",
      payout: "/admin/cp/referral/payouts/single",
      remunerations: "/admin/cp/referral/payouts/remunerations",
      submitPayout: "/admin/cp/referral/payouts/submit",
      cancelPayout: "/admin/cp/referral/payouts/cancel",
      completePayout: "/admin/cp/referral/payouts/complete",
      downloadPayout: "/admin/cp/referral/payouts/download-disbursement-sheet",
      payoutSetting: "/admin/cp/meta/app-config",
      appConfig: "/admin/cp/meta/app-config",
    };
  }
  async get(query = {}) {
    const params = {};
    params.keyword = query.keyword;
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    if (query?.dateFrom) params.dateFrom = query.dateFrom;
    if (query?.dateTo) params.dateTo = query.dateTo;
    return await httpservice.get(this.endpoints.referrals);
  }
  async create(data) {
    return await httpservice.post(this.endpoints.referrers, data);
  }
  async getReferrers(query = {}) {
    const params = {};
    params.keyword = query.keyword;
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    if (query?.dateFrom) params.dateFrom = query.dateFrom;
    if (query?.dateTo) params.dateTo = query.dateTo;
    return await httpservice.get(this.endpoints.referrers, { params });
  }
  async getReferrer(query = {}) {
    const params = {};
    params.id = query.id;

    return await httpservice.get(this.endpoints.referrer, { params });
  }

  async getReferrals(query = {}) {
    const params = {};
    params.keyword = query.keyword;
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    if (query?.status) params.status = query.status;
    if (params.status === "ALL") delete params.status;
    if (query?.referrerId) params.referrerId = query.referrerId;
    return await httpservice.get(this.endpoints.referrals, { params });
  }
  async getReferral(query = {}) {
    const params = {};
    params.id = query.id;
    return await httpservice.get(this.endpoints.referral, { params });
  }
  async getEarnings(query = {}) {
    const params = {};
    if (query?.referralId) params.referralId = query?.referralId;
    if (query?.referrerId) params.referrerId = query?.referrerId;
    return await httpservice.get(this.endpoints.earnings, { params });
  }
  async getReferralsStats(query = {}) {
    const params = {};
    params.keyword = query.keyword;
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    if (query?.status) params.status = query.status;
    if (params.status === "ALL") delete params.status;
    if (query?.referrerId) params.referrerId = query.referrerId;
    return await httpservice.get(this.endpoints.referralsStats, { params });
  }
  async updateReferrer(data, query = { id: "" }) {
    const params = {};
    if (query.id) params.id = query.id;
    return await httpservice.put(this.endpoints.referrers, data, { params });
  }

  async getPlans() {
    return await httpservice.get(this.endpoints.plans);
  }
  async setCommision(data, id) {
    const formData = {
      new: { type: "PERCENTAGE", amount: data?.new },
      renewal: { type: "PERCENTAGE", amount: data?.renewal },
    };
    return await httpservice.put(this.endpoints.commission, formData, {
      params: { id },
    });
  }
  async createPayout(data) {
    return await httpservice.post(this.endpoints.payouts, data);
  }
  async payoutSetting(data) {
    const formData = { payout: { ...data } };
    return await httpservice.put(this.endpoints.payoutSetting, formData);
  }
  async getPayoutSetting() {
    return await httpservice.get(this.endpoints.payoutSetting);
  }

  async getPayout(id) {
    const params = { id };
    return await httpservice.get(this.endpoints.payout, { params });
  }
  async getPayouts(query = {}) {
    const params = {};
    params.keyword = query.keyword;
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    if (query?.status) params.status = query.status;
    if (query?.dateFrom) params.dateFrom = query.dateFrom;
    if (query?.dateTo) params.dateTo = query.dateTo;
    if (params.status === "ALL") delete params.status;
    return await httpservice.get(this.endpoints.payouts, { params });
  }
  async getPayoutRemunerations(query = {}, payoutId) {
    const params = { payoutId };
    params.keyword = query.keyword;
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    if (query?.status) params.status = query.status;
    if (query?.dateFrom) params.dateFrom = query.dateFrom;
    if (query?.dateTo) params.dateTo = query.dateTo;
    if (query?.referrerId) params.referrerId = query.referrerId;
    return await httpservice.get(this.endpoints.remunerations, { params });
  }
  async createPayoutWithRem(data) {
    const formData = {
      type: "MANUAL",
      description: data?.description || "",
    };
    if (data?.remunerationSelect === "ALL") {
      formData.remunerationSelect = "ALL";
    } else {
      formData.remunerations = data?.referrers?.map((item) => ({
        referrerId: item?.id,
        amount: item?.total?.earned - item?.total?.paid,
      }));
    }
    return await httpservice.post(this.endpoints.payouts, formData);
  }
  async updatePayoutRemunerations(data, payoutId) {
    const params = { payoutId };
    const formData = {
      remunerations: data?.map((item) => ({
        id: item?.id,
        amount: item?.amount,
      })),
    };
    return await httpservice.put(this.endpoints.remunerations, formData, {
      params,
    });
  }
  async submitPayout(data, id) {
    const params = { id };
    const formData = { ...data };
    return await httpservice.put(this.endpoints.submitPayout, formData, {
      params,
    });
  }
  async cancelPayout(data, id) {
    const params = { id };
    const formData = { ...data };
    return await httpservice.put(this.endpoints.cancelPayout, formData, {
      params,
    });
  }
  async completePayout(data, id) {
    const params = { id };
    const formData = { ...data };
    return await httpservice.put(this.endpoints.completePayout, formData, {
      params,
    });
  }
  async downloadPayout(id, callback) {
    this.endpoints.downloadPayout = this.endpoints.downloadPayout + "?id=" + id;
    return fileDownloadService.download(this.endpoints.downloadPayout, "disbursement-sheet.xlsx", callback);
  }

  async appConfig(data) {
    return await httpservice.put(this.endpoints.appConfig, data);
  }
  async getAppConfig() {
    return await httpservice.get(this.endpoints.appConfig);
  }

  async updateCode(data, id) {
    const payload = {};
    if (data?.code) payload.code = data.code;
    if (data?.codeRemove) payload.codeRemove = data.codeRemove;
    const params = { id };
    return await httpservice.put(this.endpoints.referrers, payload, { params });
  }
}

export default new ReferralService();
