import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";
import BTNLoader from "../../../lib/components/BTNLoader";
import { useMutation, useQuery } from "@tanstack/react-query";
import referralsService from "../../../service/referralsService";
import errorHandler from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import NumberFormatCustom from "../../../lib/components/CurrencyFormat";

const PAYOUT_TYPES = {
  amount: "AMOUNT",
  subscribers: "SUBSCRIBERS",
};

const PayoutSettings = ({ isOpen, onClose }) => {
  const [values, setValues] = React.useState({
    threshholdType: PAYOUT_TYPES.amount || "",
    threshholdAmount: "",
  });

  const settingQuery = useQuery({
    retry: false,
    enabled: !!isOpen,
    queryKey: ["payout-setting"],
    queryFn: () => referralsService.getPayoutSetting(),
  });

  const mutation = useMutation({
    mutationFn: (data) => referralsService.payoutSetting(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: () => {
      toast.success("Payout updated successfully.");
      settingQuery.refetch();
      onClose();
    },
  });

  const settingsData = settingQuery?.data?.data?.data;

  React.useEffect(() => {
    if (!settingsData) return;
    setValues(settingsData?.payout);
  }, [settingsData]);

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      maxWidth="sm"
      PaperProps={{ component: "form", onSubmit: handleSubmit }}
    >
      <div className="d-flex justify-content-between gap-4 p-3 py-2 pe-2">
        <div>
          <div className="fs-5 fw-medium">Payout Settings</div>
          <div className="fs-sm text-secondary">
            Indicate how much is to be paid out to referrers at a given time{" "}
          </div>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </div>
      <Divider className="bg-secondary-subtle" />
      <DialogContent>
        <div className="mb-3">
          <FormControl>
            <RadioGroup
              row={true}
              name="threshholdType"
              value={values?.threshholdType || ""}
              onChange={handleChange}
            >
              <FormControlLabel
                value={PAYOUT_TYPES.amount}
                control={<Radio color="secondary" />}
                label="By Amount"
              />
              <FormControlLabel
                value={PAYOUT_TYPES.subscribers}
                control={<Radio color="secondary" />}
                label="By Subscribed Users"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="mb-3">
          <label htmlFor="">
            Threshold Amount <span className="text-danger">*</span>
          </label>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            color="secondary"
            value={values?.threshholdAmount}
            name="threshholdAmount"
            onChange={handleChange}
            InputProps={{
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">
                  <span>₦</span>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} type="button">
          Cancel
        </Button>
        <Button
          disabled={mutation.isPending}
          startIcon={<BTNLoader isLoading={mutation.isPending} />}
          variant="contained"
          color="secondary"
          type="submit"
        >
          Save Settings
        </Button>
      </DialogActions>
    </Dialog>
  );

  function handleClose() {
    onClose();
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    mutation.mutate(values);
  }
};

export default PayoutSettings;
