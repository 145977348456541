import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useWindowOnIdle from "./hooks/useWindowOnIdle";
import routes from "./routes";
import PrivateRoute from "./components/private-route";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#343A40",
        dark: "#343A40",
      },
      secondary: {
        main: "#1F53D7",
      },
      danger: {
        main: "#FF0303",
      },
      success: {
        main: "#00DC7D",
      },
      gray: {
        main: "#BDBDBD",
      },
    },
    typography: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
    },
  });
  useWindowOnIdle();
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          {routes?.map(({ Component, ...item }, index) => (
            <Route
              key={index}
              path={item?.path}
              element={
                item?.private ? (
                  <PrivateRoute Component={Component} />
                ) : (
                  <Component />
                )
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
