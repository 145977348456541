import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../lib/components/Header";
import AppLayout from "../../lib/components/Layout/app-layout";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import organizationservice from "../../service/organizationservice";
import BTNLoader from "../../lib/components/BTNLoader";
import moment from "moment";
import EmptyState from "../../lib/components/EmptyState";
import PreloaderTable from "../../lib/components/PreloaderTable";

const SingleAdministrator = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const userQuery = useQuery({
    retry: false,
    queryKey: ["user-details", id],
    queryFn: () => organizationservice.getUser({ id }),
  });

  const { isPending, data } = userQuery;
  const userData = data?.data?.data;
  return (
    <AppLayout>
      <Header
        title="User Details"
        description="Get insights to accounts on Jureb here"
      />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <div className="d-flex justify-content-between">
          <IconButton
            className="bg-secondary-subtle mb-3"
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </IconButton>
          <BTNLoader isLoading={isPending} />
        </div>
        <div className="bg-white rounded border mb-4" style={{ maxWidth: 300 }}>
          <div className="p-3 mb-4">
            <div className="mb-3 fs-5 fw-medium">
              {userData?.lastName} {userData?.firstName}
            </div>
            <div className="mb-2 fs-sm">
              <span className="text-secondary me-2">Phone number:</span>
              <span>{userData?.phone}</span>
            </div>
            <div className="mb-2 fs-sm">
              <span className="text-secondary me-2">Email:</span>
              <span>{userData?.email}</span>
            </div>
            <div className="mb-2 fs-sm">
              <span className="text-secondary me-2">Organizations:</span>
              <span>{userData?.organizations?.length}</span>
            </div>
          </div>
        </div>
        <PreloaderTable isLoading={isPending} />
        {isPending ? null : (
          <Paper>
            <TableContainer>
              <Table>
                <TableHead className="bg-light">
                  <TableRow>
                    <TableCell>Business Name</TableCell>
                    <TableCell>Email Address</TableCell>
                    <TableCell>Join Date</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData?.organizations?.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell> {item?.name}</TableCell>
                      <TableCell>{item?.email}</TableCell>
                      <TableCell>
                        {moment(item?.createdAt).format("Do MMM, YYYY")}
                      </TableCell>
                      <TableCell>
                        {item?.profile?.role?.split("_").join(" ")}
                      </TableCell>
                      <TableCell>
                        <Status status={item?.status} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <EmptyState
              isEmpty={!isPending && !userData?.organizations?.length}
            />
          </Paper>
        )}
      </div>
    </AppLayout>
  );
};

function Status({ status }) {
  if (status === "ACTIVE")
    return (
      <div className="badge rounded-pill  fw-medium fs-sm bg-primary text-primary bg-opacity-25">
        Active
      </div>
    );
  if (status === "EXPIRED")
    return (
      <div className="badge bg-warning text-warning rounded-pill fw-medium fs-sm bg-opacity-25">
        Expired
      </div>
    );
  if (status === "INACTIVE")
    return (
      <div className="badge bg-danger text-danger rounded-pill fw-medium fs-sm bg-opacity-25">
        Inactive
      </div>
    );
  return null;
}

export default SingleAdministrator;
