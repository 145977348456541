import React from "react";
import Logo from "../../../lib/assets/images/jureb-logo.png";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import pathnames from "../../../constants/pathnames";
import { Link } from "react-router-dom";
import {
  CorporateFareOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import UsersIcon from "../../assets/icons/Users";
import PayoutIcon from "../../assets/icons/PayoutIcon";

const SIDEBAR_LINKS = [
  { name: "Overview", path: pathnames.DASHBOARD, Icon: GridViewOutlinedIcon },
  { name: "Users", path: pathnames.USERS, Icon: GroupOutlinedIcon },
  {
    name: "Organizations",
    path: pathnames.ORGANIZATIONS,
    Icon: CorporateFareOutlined,
  },
  { name: "Payments", path: pathnames.PAYMENTS, Icon: CreditCardOutlinedIcon },
  { name: "Referrers", path: pathnames.REFERRERS, Icon: UsersIcon },
  { name: "Payouts", path: pathnames.PAYOUTS, Icon: PayoutIcon },
  {
    name: "Push Notification",
    path: pathnames.PUSH_NOTIFICATIONS,
    Icon: ChatBubbleOutlineOutlinedIcon,
  },
];

const SIDEBAR_LINKS_II = [
  {
    name: "Administrators",
    path: pathnames.ADMINISTRATORS,
    Icon: SupervisorAccountOutlined,
  },
  {
    name: "Settings",
    path: pathnames.SETTINGS_PROFILE,
    Icon: SettingsOutlinedIcon,
  },
];

const AppLayout = ({ children }) => {
  const pathname = window.location.pathname;
  return (
    <div className="d-flex" style={{ minHeight: "100vh", overflow: "hidden" }}>
      <div
        style={{ maxWidth: 240, height: "100vh", overflowY: "auto" }}
        className="d-none d-lg-block border-end w-100"
      >
        <div className="p-3" style={{ height: "calc(100vh - 100px)" }}>
          <div className="mb-4 d-flex justify-content-center align-items-center">
            <img src={Logo} alt="Jureb" />
          </div>
          <div className="d-flex flex-column justify-content-between gap-4 h-100">
            <div className="">
              <div className="text-secondary fs-xs mb-3 fw-medium">
                MAIN MENU
              </div>
              {SIDEBAR_LINKS.map(({ Icon, ...item }, index) => {
                const cname =
                  "text-nowrap d-flex gap-3 fw-medium fs-sm align-items-center p-3 text-dark sidebar-link";
                return (
                  <div key={index}>
                    <Link
                      to={item?.path}
                      className={
                        pathname && pathname.includes(item?.path)
                          ? `${cname} sidebar-link active`
                          : cname
                      }
                    >
                      <Icon />
                      <span>{item?.name}</span>
                    </Link>
                  </div>
                );
              })}
            </div>
            <div>
              <div className="text-secondary fs-xs mb-3 fw-medium">
                PREFERENCES
              </div>
              {SIDEBAR_LINKS_II.map(({ Icon, ...item }, index) => {
                const cname =
                  "d-flex gap-3 fs-sm fw-medium align-items-center p-3 text-dark sidebar-link";
                return (
                  <div key={index}>
                    <Link
                      to={item?.path}
                      className={
                        pathname && pathname.includes(item?.path)
                          ? `${cname} sidebar-link active`
                          : cname
                      }
                    >
                      <Icon />
                      <span>{item?.name}</span>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        id="section-right"
        className="flex-grow-1"
        style={{ height: "100vh", overflowY: "auto" }}
      >
        {children}
      </div>
    </div>
  );
};

export default AppLayout;
