import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import PersonsIcon from "../../lib/assets/icons/referral__icon-3.svg";
import MoneyIcon from "../../lib/assets/icons/referral__icon-2.svg";
import MoneyIconBlue from "../../lib/assets/icons/referral__icon-5.svg";
import MoneyCashIcon from "../../lib/assets/icons/referral__icon-1.svg";
import { moneyFormat } from "../../utils/moneyFormat";
import scrollHandler from "../../utils/scrollHandler";
import { Link, useNavigate } from "react-router-dom";
import AppTextField from "../../lib/components/AppTextField";
import { Button, InputAdornment, Pagination } from "@mui/material";
import { LayersOutlined, Search } from "@mui/icons-material";
import AppDateField from "../../lib/components/AppDateField";
import AppSelectField from "../../lib/components/AppSelectField";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import referralsService from "../../service/referralsService";
import PayoutsDataTable from "./components/payouts-data-table";
import PreloaderTable from "../../lib/components/PreloaderTable";
import pathnames from "../../constants/pathnames";

const currency = "NGN";

const Payouts = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "20";
  const keyword = searchParams.get("keyword") || "";
  const dateFrom = searchParams.get("dateFrom") || null;
  const dateTo = searchParams.get("dateTo") || null;
  const status = searchParams.get("status") || "";

  const qKeys = [page, limit, dateFrom, dateTo, status];
  const query = { page, limit, keyword, dateFrom, dateTo, status };

  const payoutsQuery = useQuery({
    retry: false,
    queryKey: ["payouts", ...qKeys],
    queryFn: () => referralsService.getPayouts(query),
  });

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      payoutsQuery.refetch();
    }, 1000);
    return () => clearTimeout(timeoutId);
    //eslint-disable-next-line
  }, [keyword]);

  const payoutsData = payoutsQuery?.data?.data;
  return (
    <AppLayout>
      <Header
        title="Payouts"
        description="Get insights to Referrers that have been paid from this page"
      />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <div className="row mb-5 d-none">
          <StatsCard
            title={"Total Number of Referrer"}
            amount={2500}
            Icon={PersonsIcon}
          />
          <StatsCard
            title={"Total Commissions Earned"}
            amount={moneyFormat(50901, currency)}
            Icon={MoneyIcon}
          />
          <StatsCard
            title={"Total Commissions Paid"}
            amount={moneyFormat(50901, currency)}
            Icon={MoneyCashIcon}
          />
          <StatsCard
            title={"Available Balance"}
            amount={moneyFormat(50901, currency)}
            Icon={MoneyIconBlue}
          />
        </div>
        <div className="row mb-3">
          <div className="col-6 col-md-3 col-lg-3 mb-3">
            <div>
              <AppTextField
                placeholder="Enter Text Here..."
                value={keyword}
                onChange={(e) => handleChange(e, "keyword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search className="text-secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppDateField
                label="Date From"
                value={dateFrom}
                onChange={(e) => handleChange(e, "dateFrom")}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppDateField
                label="Date To"
                value={dateTo}
                minDate={dateFrom}
                onChange={(e) => handleChange(e, "dateTo")}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppSelectField
                value={status}
                placeholder="Status"
                onChange={(e) => handleChange(e, "status")}
                items={[
                  { label: "All", value: "ALL" },
                  { label: "Draft", value: "DRAFT" },
                  { label: "Pending", value: "PENDING" },
                  { label: "Paid", value: "PAID" },
                  { label: "Canceled", value: "CANCELED" },
                ]}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-3 mb-3">
            <div className="d-flex gap-4 justify-content-end">
              <Button
                endIcon={<LayersOutlined />}
                variant="outlined"
                color="secondary"
                className="bg-white"
                component={Link}
                to={pathnames.REFERRERS}
              >
                Process Payouts
              </Button>
            </div>
          </div>
        </div>
        <PreloaderTable isLoading={payoutsQuery.isLoading} />
        <PayoutsDataTable
          isLoading={payoutsQuery.isLoading}
          data={payoutsData?.data}
        />
        <div className="my-3">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div>
              <AppSelectField
                value={limit}
                placeholder="Limit"
                onChange={(e) => handleChange(e, "limit")}
                items={[
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                ]}
              />
            </div>
            <div>
              <Pagination
                page={Number(page)}
                count={payoutsData?.totalPages}
                onChange={(_, value) => {
                  handleChange(value, "page");
                }}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );

  function handleChange(e, name = "") {
    const value = name.includes("date")
      ? moment(e).format("YYYY-MM-DD")
      : name === "page"
      ? e
      : e.target.value;
    searchParams.set(name, value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
      { scrollY: 0 }
    );
    if (name === "page") scrollHandler();
  }
};

function StatsCard({ title, amount, Icon }) {
  return (
    <div className="col-12 col-md-3 mb-3">
      <div className="border bg-white rounded p-3 h-100 d-flex flex-column justify-content-between">
        <div className="d-flex gap-3 align-items-center mb-3">
          <div className="flex-grow-1 fs-sm">{title}</div>
          <div>
            <img src={Icon} alt={title} width={50} height={50} />
          </div>
        </div>
        <div className="fs-4 fw-medium">{amount}</div>
      </div>
    </div>
  );
}

export default Payouts;
