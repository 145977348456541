import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import Layout from "./components/Layout";
import currencyservice from "../../service/currencyservice";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Add, ErrorOutline } from "@mui/icons-material";
import { Button } from "@mui/material";
import PreloaderTable from "../../lib/components/PreloaderTable";
import CurrenciesDataTable from "./components/currencies-data-table";
import AddCurrency from "./components/add-currency";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import ConfirmationDialog from "../../lib/components/ConfirmationDialog";

const CurrencyRates = () => {
  const [isAddNew, setIsAddNew] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [selectedCurrency, setSelectedCurrency] = React.useState(null);

  const selectedCurrenciesQuery = useQuery({
    retry: false,
    queryKey: ["selected-currencies"],
    queryFn: currencyservice.get,
  });

  const mutation = useMutation({
    mutationFn: (data) => currencyservice.delete(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      selectedCurrenciesQuery.refetch();
      toast.success("Currency deleted successfully.");
      setIsDelete(false);
    },
  });

  const { data, isPending: isLoading } = selectedCurrenciesQuery;
  const currenciesData = data?.data?.data;

  return (
    <AppLayout>
      <Header
        title="Settings"
        description="Customize your account preferences and general settings here."
      />
      <Layout>
        <div className="p-4">
          <div className="d-flex mb-4 gap-3 align-items-center">
            <div className="flex-grow-1 text-primary fs-5 fw-medium">
              <ErrorOutline /> Currencies
            </div>
            <div>
              <Button
                onClick={onAdd}
                startIcon={<Add />}
                variant="contained"
                color="secondary"
              >
                Add Currency
              </Button>
            </div>
          </div>
          <PreloaderTable isLoading={isLoading} />
          <CurrenciesDataTable
            isLoading={isLoading}
            data={currenciesData}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </div>
      </Layout>
      <AddCurrency
        isOpen={isAddNew}
        key={selectedCurrency?.id}
        onClose={onCloseDialog}
        refetch={selectedCurrenciesQuery.refetch}
        currency={selectedCurrency}
      />
      <ConfirmationDialog
        isOpen={isDelete}
        title="Delete Currency"
        isLoading={mutation.isPending}
        onClose={() => setIsDelete(false)}
        onSubmit={handleDeleteCurrency}
        body={`You have selected to delete this currency <span class="fw-medium">"${selectedCurrency?.currencyName}</span>". Select "<span class="fw-medium">Yes, delete</span>" to continue.`}
        actionTitle="Yes, delete"
      />
    </AppLayout>
  );

  function onDelete(data) {
    setSelectedCurrency(data);
    setIsDelete(true);
  }
  function onEdit(data) {
    setSelectedCurrency(data);
    setIsAddNew(true);
  }
  function onAdd() {
    setIsAddNew(true);
    setSelectedCurrency(null);
  }

  function onCloseDialog() {
    setIsAddNew(false);
    setSelectedCurrency(null);
  }

  function handleDeleteCurrency() {
    mutation.mutate(selectedCurrency?.id);
  }
};

export default CurrencyRates;
