import * as Yup from "yup";

const CREATE_ADMIN_VALIDATION = Yup.object({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  email: Yup.string()
    .trim()
    .email("Email must be valid")
    .required("Email address is required"),
});
const UPDATE_ADMIN_VALIDATION = Yup.object({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  image: Yup.mixed().label("Profile Image"),
});

const UPDATE_ADMIN_PASS_VALIDATION = Yup.object({
  password: Yup.string().required().label("Password"),
  newPassword: Yup.string().required().label("New Password"),
  confirmNewPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("newPassword")], "Passwords does not match")
    .label("Confirm New Password"),
});

export {
  CREATE_ADMIN_VALIDATION,
  UPDATE_ADMIN_VALIDATION,
  UPDATE_ADMIN_PASS_VALIDATION,
};
