import React from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
// import { Link } from "react-router-dom";
import EmptyState from "../../../lib/components/EmptyState";
import { Delete } from "@mui/icons-material";

const NotificationsDataTable = ({ data, isLoading, onDeleteNotification }) => {
  if (isLoading) return null;

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className="bg-light">
            <TableRow>
              <TableCell>Message</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Delivery Type(s)</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <TableRow
                key={item?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="fw-medium">{item?.title}</TableCell>
                <TableCell>
                  <Status status={item?.status} />
                </TableCell>

                <TableCell>
                  {moment(item?.createdAt).format("Do MMM, YYYY")}
                </TableCell>
                <TableCell>
                  {item?.deliveryTypes
                    .map((item) => item?.replace("_", " "))
                    .join(", ")}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    size="small"
                    color="error"
                    disabled={item?.status === "SENT"}
                    onClick={() => onDeleteNotification(item)}
                    title={`Delete notification (${item?.title})`}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </Paper>
  );
};

function Status({ status }) {
  if (status === "SENT")
    return (
      <div className="badge rounded-pill  fw-medium fs-sm bg-success text-success bg-opacity-25">
        Sent
      </div>
    );
  if (status === "SCHEDULED")
    return (
      <div className="badge bg-warning text-warning rounded-pill fw-medium fs-sm bg-opacity-25">
        Scheduled
      </div>
    );
  return null;
}

export default NotificationsDataTable;
