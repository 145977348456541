import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import scrollHandler from "../../utils/scrollHandler";
import { useNavigate, useParams } from "react-router-dom";
import AppTextField from "../../lib/components/AppTextField";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { ArrowBack, Search } from "@mui/icons-material";
import moment from "moment";
import { useMutation, useQuery } from "@tanstack/react-query";
import referralsService from "../../service/referralsService";
import PreloaderTable from "../../lib/components/PreloaderTable";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import ConfirmPayoutDataTable from "./components/confirm-payout-data-table";
import BTNLoader from "../../lib/components/BTNLoader";

const ConfirmPayout = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "20";
  const keyword = searchParams.get("keyword") || "";
  const status = searchParams.get("status") || "";
  const [referrers, setReferrers] = React.useState([]);

  const qKeys = [page, limit, status, id];
  const query = { page, limit, keyword, status };

  const payoutQuery = useQuery({
    retry: false,
    queryKey: ["payout", id],
    queryFn: () => referralsService.getPayout(id),
  });
  const remunerationsQuery = useQuery({
    retry: false,
    queryKey: ["remunerations", ...qKeys],
    queryFn: () => referralsService.getPayoutRemunerations(query, id),
  });

  const mutation = useMutation({
    mutationFn: (data) => referralsService.completePayout(data, id),
    onError: ({ response }) => errorHandler(response),
    onSuccess: () => {
      toast.success("Payout completed successfully...");
      navigate(-1);
    },
  });

  const payout = payoutQuery?.data?.data?.data;
  const remData = remunerationsQuery?.data?.data;

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      remunerationsQuery.refetch();
    }, 1000);
    return () => clearTimeout(timeoutId);
    //eslint-disable-next-line
  }, [keyword]);

  React.useEffect(() => {
    if (!remData) return;
    setReferrers(remData?.data?.map((item) => ({ ...item, isChecked: false })));
  }, [remData]);

  return (
    <AppLayout>
      <Header title="Confirm Payout" description="" />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <IconButton
          className="bg-secondary-subtle mb-3"
          onClick={() => navigate(-1)}
        >
          <ArrowBack />
        </IconButton>
        <div className="row mb-3">
          <div className="col-12 col-md-6 mb-3">
            <div style={{ maxWidth: 400 }}>
              <AppTextField
                placeholder="Enter Text Here..."
                value={keyword}
                onChange={(e) => handleChange(e, "keyword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search className="text-secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 mb-3">
            <div className="d-flex flex-wrap gap-4 justify-content-md-end">
              {["CANCELED", "PAID"].includes(payout?.status) ? null : (
                <Button
                  onClick={save}
                  variant="contained"
                  color="secondary"
                  className={!payout ? "d-none" : ""}
                  disabled={mutation.isPending}
                  startIcon={<BTNLoader isLoading={mutation.isPending} />}
                >
                  Complete Payout
                </Button>
              )}
            </div>
          </div>
        </div>
        <PreloaderTable isLoading={remunerationsQuery.isLoading} />
        <ConfirmPayoutDataTable
          data={referrers}
          setReferrers={setReferrers}
          isLoading={remunerationsQuery.isLoading}
          disabled={["CANCELED", "PAID"].includes(payout?.status)}
        />
      </div>
    </AppLayout>
  );

  function save() {
    const filterReferrers = referrers?.filter((item) => item?.isChecked);
    if (!filterReferrers?.length) {
      toast.error("Please select referrers to proceed");
      return;
    }
    const payload = {
      id: id,
      paidAt: new Date(),
      remunerations: filterReferrers?.map((item) => {
        const data = {};
        data.id = item?.id;
        data.amount = item?.amount;
        data.amountPaid = item?.amount;
        return data;
      }),
    };
    mutation.mutate(payload);
  }
  function handleChange(e, name = "") {
    const value = name.includes("date")
      ? moment(e).format("YYYY-MM-DD")
      : name === "page"
      ? e
      : e.target.value;
    searchParams.set(name, value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
      { scrollY: 0 }
    );
    if (name === "page") scrollHandler();
  }
};

export default ConfirmPayout;
