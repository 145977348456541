import React from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import EmptyState from "../../../lib/components/EmptyState";
import { Delete } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const TaxDataTable = ({
  data,
  isLoading,
  handleDeleteTax,
  handleChangeTaxDate,
  handleChangeTaxName,
  handleChangeTaxRate,
  handleChangeTaxAction,
}) => {
  if (isLoading) return null;
  return (
    <React.Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tax Name</TableCell>
              <TableCell>Rates(%)</TableCell>
              <TableCell>Date to be Paid</TableCell>
              <TableCell>Active</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder=""
                    value={item?.name}
                    onChange={(e) => handleChangeTaxName(item?.id, e)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    type="number"
                    variant="outlined"
                    placeholder=""
                    value={item?.rate}
                    InputProps={{
                      inputProps: { min: 0, max: 100, step: 0.01 },
                    }}
                    onChange={(e) => handleChangeTaxRate(item?.id, e)}
                  />
                </TableCell>
                <TableCell>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Date"
                      inputFormat="dd/MM/yyyy"
                      value={item?.date}
                      InputProps={{ required: true }}
                      onChange={(e) => handleChangeTaxDate(item?.id, e)}
                      renderInput={(params) => (
                        <TextField
                          className="bg-white"
                          fullWidth
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    required
                    size="small"
                    label={`${item?.rate || 0}%`}
                    control={
                      <Checkbox
                        size="small"
                        color="secondary"
                        checked={item?.action}
                        onChange={(e) => handleChangeTaxAction(item?.id, e)}
                      />
                    }
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleDeleteTax(item?.id)}
                    title={`Delete tax (${item?.name})`}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </React.Fragment>
  );
};

export default TaxDataTable;
