import _ from "lodash";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

class SheetReader {
  static accept = ".xlsx, .xlsb, .xlsm, .xls, .xml, .csv";
  static result = [];

  static read(file = null, fieldNames = [], sheet = 0) {
    return new Promise((resolve, reject) => {
      try {
        this.handleError(file, fieldNames);
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
          const binarystr = new Uint8Array(e.target.result);
          const wb = XLSX.read(binarystr, {
            type: "array",
            raw: true,
            cellFormula: false,
          });

          const wsname = wb.SheetNames[sheet];
          const data = XLSX.utils.sheet_to_json(wb.Sheets[wsname]);
          this.result = data
            .map((item) => _.pick(item, fieldNames))
            .filter((item) => Object.keys(item).length);
          resolve(this.result);
        };
      } catch (error) {
        reject(error);
      }
    });
  }

  static handleError(file, fieldNames) {
    if (!file) {
      toast.error("No file selected");
      throw new Error("No file selected");
    }
    if (!fieldNames.length) {
      toast.error("Field names required");
      throw new Error("Field names required");
    }
  }
}

export default SheetReader;
