import * as Yup from "yup";

const CREATE_NOTIFICATION_VALIDATION = Yup.object({
  title: Yup.string().required().label("Title"),
  date: Yup.date().required().label("Date"),
  time: Yup.date().required().label("Time"),
  deliveryTypes: Yup.array(Yup.string()).min(1).label("Delivery Types"),
  recipients: Yup.array(Yup.string()).min(1).label("Recipients"),
  subscriptionPlans: Yup.array(Yup.string()).min(1).label("Sub Plans"),
  image: Yup.mixed().label("Image"),
  text: Yup.string().label("Email Content"),
  message: Yup.string()
    .test("Message", "Text should not exceed 120 words", (value) => {
      const plainText = value.replace(/<[^>]*>/g, ""); // Remove HTML tags using a regular expression
      const words = plainText.split(/\s+/); // Split the plain text into words using whitespace as a delimiter
      const filteredWords = words.filter((word) => word !== ""); // Filter out empty strings
      return filteredWords.length <= 120; // Check if the word count does not exceed 120
    })
    .label("Message"),
});

export { CREATE_NOTIFICATION_VALIDATION };
