import React from "react";
import EmptyIMG from "../assets/images/empty-state-1.png";

const EmptyState = ({ isEmpty }) => {
  if (isEmpty)
    return (
      <div className="my-5 d-flex justify-content-center">
        <div className="d-flex flex-column align-items-center">
          <img src={EmptyIMG} alt="No Data" />
          <div className="text-center my-3">Nothig to display</div>
        </div>
      </div>
    );
  return null;
};

export default EmptyState;
