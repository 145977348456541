import React from "react";
import {
  Avatar,
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  AccountCircleOutlined,
  ExitToAppOutlined,
  LockOutlined,
  NotificationsNoneOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import Notifications from "./Notifications";
import pathnames from "../../constants/pathnames";
import useUserData from "../../hooks/useUserData";
import Logo from "../../lib/assets/images/jureb-logo.png";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import {
  CorporateFareOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import UsersIcon from "../assets/icons/Users";
import PayoutIcon from "../assets/icons/PayoutIcon";
import MenuIcon from "@mui/icons-material/Menu";

const pathname = window.location.pathname;
const Header = ({ title, description }) => {
  const { user } = useUserData();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isNotification, setIsNotification] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const SIDEBAR_LINKS = [
    { name: "Overview", path: pathnames.DASHBOARD, Icon: GridViewOutlinedIcon },
    { name: "Users", path: pathnames.USERS, Icon: GroupOutlinedIcon },
    {
      name: "Organizations",
      path: pathnames.ORGANIZATIONS,
      Icon: CorporateFareOutlined,
    },
    {
      name: "Payments",
      path: pathnames.PAYMENTS,
      Icon: CreditCardOutlinedIcon,
    },
    { name: "Referrers", path: pathnames.REFERRERS, Icon: UsersIcon },
    { name: "Payouts", path: pathnames.PAYOUTS, Icon: PayoutIcon },
    {
      name: "Push Notification",
      path: pathnames.PUSH_NOTIFICATIONS,
      Icon: ChatBubbleOutlineOutlinedIcon,
    },
  ];

  const SIDEBAR_LINKS_II = [
    {
      name: "Administrators",
      path: pathnames.ADMINISTRATORS,
      Icon: SupervisorAccountOutlined,
    },
    {
      name: "Settings",
      path: pathnames.SETTINGS_PROFILE,
      Icon: SettingsOutlinedIcon,
    },
  ];
  return (
    <div className="border-bottom p-3 sticky-top bg-white">
      <nav className="d-flex align-items-center justify-content-between">
        <div>
          <div className="d-flex align-items-center gap-4">
            <IconButton onClick={toggleDrawer} className="d-lg-none">
              <MenuIcon />
            </IconButton>
            <div>
              <div className="fw-medium fs-4">{title}</div>
              <div className="d-none d-md-block">{description}</div>
            </div>
          </div>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <div>
            <IconButton onClick={(e) => setIsNotification(e.currentTarget)}>
              <NotificationsNoneOutlined />
            </IconButton>
          </div>
          <Link
            to={pathnames.SETTINGS_PROFILE}
            className="d-flex gap-2 align-items-center"
          >
            <Avatar src={user?.avatarUploadMeta?.url} className="bg-primary">
              {user?.firstName?.charAt(0)}
              {user?.lastName?.charAt(0)}
            </Avatar>
            <div className="text-dark text-nowrap">
              {user?.firstName} {user?.lastName?.charAt(0)}.
            </div>
          </Link>
          <div>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <KeyboardArrowDownIcon />
            </IconButton>
          </div>
        </div>
      </nav>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem disabled>Account Settings</MenuItem>
        <MenuItem>
          <Link
            to={pathnames.SETTINGS_PROFILE}
            className="text-dark d-flex align-items-center"
          >
            <ListItemIcon>
              <AccountCircleOutlined />
            </ListItemIcon>
            Profile
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to={pathnames.SETTINGS_TAX_RATES}
            className="text-dark d-flex align-items-center"
          >
            <ListItemIcon>
              <SettingsOutlined />
            </ListItemIcon>
            Settings
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to={pathnames.SETTINGS_CHANGE_PASSWORD}
            className="text-dark d-flex align-items-center"
          >
            <ListItemIcon>
              <LockOutlined />
            </ListItemIcon>
            Password and Security
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem>
          <Link
            to={pathnames.SIGN_OUT}
            className="text-dark d-flex align-items-center"
          >
            <ListItemIcon>
              <ExitToAppOutlined />
            </ListItemIcon>
            Logout
          </Link>
        </MenuItem>
      </Menu>
      <Notifications
        anchorEl={isNotification}
        onClose={() => setIsNotification(null)}
      />
      <Drawer anchor={"left"} open={isOpen} onClose={() => setIsOpen(false)}>
        <div>
          <div className="p-3" style={{ height: "calc(100vh - 100px)" }}>
            <div className="mb-4 d-flex justify-content-center align-items-center">
              <img src={Logo} alt="Jureb" />
            </div>
            <div className="d-flex flex-column justify-content-between gap-4 h-100">
              <div className="">
                <div className="text-secondary fs-xs mb-3 fw-medium">
                  MAIN MENU
                </div>
                {SIDEBAR_LINKS.map(({ Icon, ...item }, index) => {
                  const cname =
                    "text-nowrap d-flex gap-3 fw-medium fs-sm align-items-center p-3 text-dark sidebar-link";
                  return (
                    <div key={index}>
                      <Link
                        to={item?.path}
                        className={
                          pathname && pathname.includes(item?.path)
                            ? `${cname} sidebar-link active`
                            : cname
                        }
                      >
                        <Icon />
                        <span>{item?.name}</span>
                      </Link>
                    </div>
                  );
                })}
              </div>
              <div>
                <div className="text-secondary fs-xs mb-3 fw-medium">
                  PREFERENCES
                </div>
                {SIDEBAR_LINKS_II.map(({ Icon, ...item }, index) => {
                  const cname =
                    "d-flex gap-3 fs-sm fw-medium align-items-center p-3 text-dark sidebar-link";
                  return (
                    <div key={index}>
                      <Link
                        to={item?.path}
                        className={
                          pathname && pathname.includes(item?.path)
                            ? `${cname} sidebar-link active`
                            : cname
                        }
                      >
                        <Icon />
                        <span>{item?.name}</span>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );

  function toggleDrawer(event) {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(true);
  }
};

export default Header;
