import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import referralsService from "../../service/referralsService";
import moment from "moment";
import scrollHandler from "../../utils/scrollHandler";
import AppTextField from "../../lib/components/AppTextField";
import { Button, InputAdornment, Menu, MenuItem, Pagination } from "@mui/material";
import { Add, KeyboardArrowDown, LayersOutlined, Search, SettingsOutlined } from "@mui/icons-material";
import AppDateField from "../../lib/components/AppDateField";
import PreloaderTable from "../../lib/components/PreloaderTable";
import AppSelectField from "../../lib/components/AppSelectField";
import CommissionSettings from "./components/commission-settings";
import ReferrersDataTable from "./components/referrers-data-table";
import PayoutSettings from "./components/payout-settings";
import ProcessPayout from "../payouts/components/process-payout";
import DiscountSettings from "./components/discount-settings";
import CreateReferrer from "./components/create-referrer";

const menuProps = {
  commission: false,
  payout: false,
  menu: null,
  paymentIssue: false,
  discount: false,
  create: false,
  remunerationSelect: "",
};
const Referrals = () => {
  const navigate = useNavigate();
  const [isSettings, setIsSettings] = React.useState(menuProps);
  const [selectedReferrers, setSelectedReferrers] = React.useState([]);
  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "20";
  const keyword = searchParams.get("keyword") || "";
  const dateFrom = searchParams.get("dateFrom") || null;
  const dateTo = searchParams.get("dateTo") || null;

  const qKeys = [page, limit, dateFrom, dateTo];
  const queries = { page, limit, keyword, dateFrom, dateTo };

  const referrersQuery = useQuery({
    retry: false,
    queryKey: ["referrers", ...qKeys],
    queryFn: () => referralsService.getReferrers({ ...queries }),
  });

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      referrersQuery.refetch();
    }, 1000);
    return () => clearTimeout(timeoutId);
    //eslint-disable-next-line
  }, [keyword]);

  const { isPending: isLoading, data } = referrersQuery;
  const referralData = data?.data;

  return (
    <AppLayout>
      <Header title="Referrers" description="Get insights to users on Jureb's referral program here" />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <div className="d-flex mb-5 justify-content-end">
          <Button variant="contained" color="secondary" startIcon={<Add />} onClick={() => handleMenu("create", true)}>
            Create Referrer
          </Button>
        </div>
        <div className={`row mb-2`}>
          <div className="col-6 col-md-3 col-lg-4 mb-3">
            <div>
              <AppTextField
                placeholder="Enter Text Here..."
                value={keyword}
                onChange={(e) => handleChange(e, "keyword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search className="text-secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppDateField label="Date From" value={dateFrom} onChange={(e) => handleChange(e, "dateFrom")} />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppDateField
                label="Date To"
                value={dateTo}
                minDate={dateFrom}
                onChange={(e) => handleChange(e, "dateTo")}
              />
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-4 mb-3">
            <div className="d-flex gap-4 justify-content-lg-end">
              <Button
                endIcon={<LayersOutlined />}
                variant="outlined"
                color="secondary"
                className="bg-white"
                disabled={!selectedReferrers?.length && !isSettings.remunerationSelect}
                onClick={() => handleMenu("paymentIssue", true)}
              >
                Bulk Payouts
              </Button>
              <Button
                onClick={(e) => handleMenu("menu", e.currentTarget)}
                startIcon={<SettingsOutlined />}
                endIcon={<KeyboardArrowDown />}
                variant="text"
                color="secondary"
              >
                Settings
              </Button>
              <Menu anchorEl={isSettings.menu} open={Boolean(isSettings.menu)} onClose={() => handleMenu("menu", null)}>
                <MenuItem disabled>Settings</MenuItem>
                <MenuItem onClick={() => handleMenu("commission", true)}>Commission Settings</MenuItem>
                <MenuItem onClick={() => handleMenu("payout", true)}>Payout Settings</MenuItem>
                <MenuItem onClick={() => handleMenu("discount", true)}>Subscribers Discount</MenuItem>
              </Menu>
            </div>
          </div>
        </div>

        <PreloaderTable isLoading={isLoading} />
        <ReferrersDataTable
          handleMenu={handleMenu}
          isLoading={isLoading}
          data={referralData?.data}
          selected={selectedReferrers}
          setSelected={setSelectedReferrers}
          remunerationSelect={isSettings.remunerationSelect}
        />
        <div className="my-3">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div>
              <AppSelectField
                value={limit}
                placeholder="Limit"
                onChange={(e) => handleChange(e, "limit")}
                items={[
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                ]}
              />
            </div>
            <div>
              <Pagination
                page={Number(page)}
                count={referralData?.totalPages}
                onChange={(_, value) => {
                  handleChange(value, "page");
                }}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
      <PayoutSettings isOpen={isSettings.payout} onClose={() => handleMenu("payout", false)} />
      <CommissionSettings isOpen={isSettings.commission} onClose={() => handleMenu("commission", false)} />
      <DiscountSettings isOpen={isSettings.discount} onClose={() => handleMenu("discount", false)} />

      <CreateReferrer
        refetch={() => referrersQuery.refetch()}
        isOpen={isSettings.create}
        onClose={() => handleMenu("create", false)}
      />
      <ProcessPayout
        referrers={selectedReferrers}
        isOpen={isSettings.paymentIssue}
        remunerationSelect={isSettings.remunerationSelect}
        onClose={() => handleMenu("paymentIssue", false)}
      />
    </AppLayout>
  );

  function handleMenu(name, value) {
    if (["commission", "payout", "discount"].includes(name)) {
      setIsSettings((p) => ({ ...p, menu: false, [name]: value }));
      return;
    }
    setIsSettings((p) => ({ ...p, [name]: value }));
  }

  function handleChange(e, name = "") {
    const value = name.includes("date") ? moment(e).format("YYYY-MM-DD") : name === "page" ? e : e.target.value;
    searchParams.set(name, value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
      { scrollY: 0 }
    );
    if (name === "page") scrollHandler();
  }
};

export default Referrals;
