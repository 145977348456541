import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import Layout from "./components/Layout";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UPDATE_ADMIN_PASS_VALIDATION } from "../../validations/admins";
import { useMutation } from "@tanstack/react-query";
import userservice from "../../service/userservice";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import { Button, TextField } from "@mui/material";
import BTNLoader from "../../lib/components/BTNLoader";

const defaultValues = {
  password: "",
  newPassword: "",
  confirmNewPassword: "",
};
const UserPassword = () => {
  const formHook = useForm({
    resolver: yupResolver(UPDATE_ADMIN_PASS_VALIDATION),
    mode: "all",
    defaultValues: defaultValues,
  });

  const errors = formHook.formState.errors;
  const control = formHook.control;
  const handleSubmit = formHook.handleSubmit;
  const reset = formHook.reset;

  const mutation = useMutation({
    mutationFn: (data) => userservice.changePassword(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      onCreateSuccess(data);
    },
  });

  async function save(data) {
    mutation.mutate(data);
  }
  const { isPending } = mutation;
  return (
    <AppLayout>
      <Header
        title="Settings"
        description="Customize your account preferences and general settings here."
      />
      <Layout>
        <div className="p-4">
          <div className="fw-medium fs-4 mb-3">Change Password</div>
          <form onSubmit={handleSubmit(save)} style={{ maxWidth: 450 }}>
            <div className="mb-4">
              <label className="d-block">
                Current Password
                <span className="text-danger">*</span>
              </label>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    type="password"
                    variant="outlined"
                    placeholder="************"
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    helperText={errors["password"]?.message}
                    error={!!errors["password"]}
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <label className="d-block">
                New Password
                <span className="text-danger">*</span>
              </label>
              <Controller
                name="newPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="password"
                    placeholder="************"
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    helperText={errors["newPassword"]?.message}
                    error={!!errors["newPassword"]}
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <label className="d-block">
                Confirm New Password
                <span className="text-danger">*</span>
              </label>
              <Controller
                name="confirmNewPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="password"
                    placeholder="************"
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    helperText={errors["confirmNewPassword"]?.message}
                    error={!!errors["confirmNewPassword"]}
                  />
                )}
              />
            </div>

            <div className="mb-4 d-flex justify-content-end">
              <Button
                type="submit"
                disabled={isPending}
                startIcon={<BTNLoader isLoading={isPending} />}
                variant="contained"
                color={"secondary"}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </Layout>
    </AppLayout>
  );

  function onCreateSuccess(data) {
    toast.success(`Password updated successfully`);
    reset(defaultValues);
  }
};

export default UserPassword;
