import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EmptyState from "../../../lib/components/EmptyState";

const AdministratorsDataTable = ({ data, isLoading }) => {
  if (isLoading) return null;
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className="bg-light">
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell> {item?.name}</TableCell>
                <TableCell>{item?.email}</TableCell>
                <TableCell>{item?.position}</TableCell>
                <TableCell>{item?.role}</TableCell>
                <TableCell>
                  <Status status={item?.status} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </Paper>
  );
};

function Status({ status }) {
  if (status === "ACTIVE")
    return (
      <div className="badge rounded-pill  fw-medium fs-sm bg-primary text-primary bg-opacity-25">
        Active
      </div>
    );
  if (status === "EXPIRED")
    return (
      <div className="badge bg-warning text-warning rounded-pill fw-medium fs-sm bg-opacity-25">
        Expired
      </div>
    );
  if (status === "INACTIVE")
    return (
      <div className="badge bg-danger text-danger rounded-pill fw-medium fs-sm bg-opacity-25">
        Inactive
      </div>
    );
  return null;
}

export default AdministratorsDataTable;
