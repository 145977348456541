import axios from "axios";
import Cookies from "js-cookie";
import { backendURL } from "../config";

const token = Cookies.get("administrator_access_auth");

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
  }
  return Promise.reject(error);
});

const http = axios.create({
  baseURL: `${backendURL}/api/v1`,
});

http.defaults.headers = {
  Authorization: `Bearer ${token}`,
};

const payload = {
  get: http.get,
  post: http.post,
  put: http.put,
  delete: http.delete,
  all: http.all,
  axios: http,
};

export default payload;
