import React from "react";
import { Link } from "react-router-dom";
import pathnames from "../../../constants/pathnames";

const Layout = ({ children }) => {
  const pathname = window.location.pathname;
  const activeClass = "d-block fs-sm fw-medium text-primary";
  const inactiveClass = "d-block fs-sm fw-medium text-dark";
  return (
    <div className="d-flex p-4 bg-light" style={{ minHeight: "100%" }}>
      <div style={{ width: 280 }} className="d-none d-md-block">
        <div className="mb-4 mt-3">
          <div className="fs-xs text-secondary mb-1">Profile</div>
          <Link
            to={pathnames.SETTINGS_PROFILE}
            className={
              pathname.includes(pathnames.SETTINGS_PROFILE)
                ? activeClass
                : inactiveClass
            }
          >
            User Profile
          </Link>
        </div>
        <div className="mb-4 mt-3">
          <div className="fs-xs text-secondary mb-1">Rates</div>
          <Link
            to={pathnames.SETTINGS_TAX_RATES}
            className={
              pathname.includes(pathnames.SETTINGS_TAX_RATES)
                ? activeClass
                : inactiveClass
            }
          >
            Tax Rate
          </Link>
          <Link
            to={pathnames.SETTINGS_CURRENCY_RATES}
            className={
              pathname.includes(pathnames.SETTINGS_CURRENCY_RATES)
                ? activeClass
                : inactiveClass
            }
          >
            Currency Rate
          </Link>
        </div>
        <div className="mb-4 mt-3">
          <div className="fs-xs text-secondary mb-1">Security</div>
          <Link
            to={pathnames.SETTINGS_CHANGE_PASSWORD}
            className={
              pathname.includes(pathnames.SETTINGS_CHANGE_PASSWORD)
                ? activeClass
                : inactiveClass
            }
          >
            Change Password
          </Link>
        </div>
      </div>
      <div
        className="bg-white w-100 mb-5 border rounded-3"
        style={{ minHeight: "100%" }}
      >
        {children}
      </div>
    </div>
  );
};

export default Layout;
