import React from "react";
import { ADD_CURRENCY_VALIDATION } from "../../../validations/settings";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import currencyservice from "../../../service/currencyservice";
import errorHandler from "../../../utils/errorHandler";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import AppSelectField from "../../../lib/components/AppSelectField";
import BTNLoader from "../../../lib/components/BTNLoader";

const defaultValues = {
  currencyName: "",
  currencyValue: "",
};
const AddCurrency = ({ currency, isOpen, onClose, refetch }) => {
  const id = currency?.id;
  const [rates, setRates] = React.useState({});
  const formHook = useForm({
    resolver: yupResolver(ADD_CURRENCY_VALIDATION),
    mode: "all",
    defaultValues: defaultValues,
  });
  const errors = formHook.formState.errors;
  const control = formHook.control;
  const handleSubmit = formHook.handleSubmit;
  const reset = formHook.reset;
  const setValue = formHook.setValue;

  const currenciesQuery = useQuery({
    retry: false,
    enabled: isOpen,
    queryKey: ["available-currencies"],
    queryFn: currencyservice.getAll,
  });

  const mutation = useMutation({
    mutationFn: (data) => currencyservice.create(data, id),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      onCreateSuccess(data);
    },
  });

  const { isPending } = mutation;
  const { data } = currenciesQuery;
  const currenciesData = data?.data?.data?.rates;

  React.useEffect(() => {
    if (!currency) return;
    reset({
      currencyName: currency?.currencyName,
      currencyValue: 1 / Number(currency?.currencyValue),
    });
  }, [currency, reset]);

  React.useEffect(() => {
    if (!currenciesData) return;
    setRates(currenciesData);
  }, [currenciesData]);

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        maxWidth="xs"
        PaperProps={{
          className: "w-100",
          component: "form",
          onSubmit: handleSubmit(save),
        }}
      >
        <div className="d-flex justify-content-between align-items-center gap-4 p-3 py-2 pe-2">
          <div className="fs-5 fw-medium">Currency Rate</div>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <Divider className="bg-secondary-subtle" />
        <DialogContent className="h-100 overflow-auto">
          <div className="mb-4">
            <label className="d-block">
              Currency
              <span className="text-danger">*</span>
            </label>
            <Controller
              name="currencyName"
              control={control}
              render={({ field }) => (
                <AppSelectField
                  placeholder={"Currency"}
                  value={field.value}
                  error={!!errors["currencyName"]}
                  onChange={(e) => handleChangeValue(e, field.onChange)}
                  items={formatCurrencyData(currenciesData)}
                  helperText={errors["currencyName"]?.message}
                />
              )}
            />
          </div>
          <div className="mb-4">
            <label className="d-block">
              Value
              <span className="text-danger">*</span>
            </label>
            <Controller
              name="currencyValue"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  placeholder="Enter currency rate"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  helperText={errors["currencyValue"]?.message}
                  error={!!errors["currencyValue"]}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onClose}>
            Close
          </Button>
          <Button
            type="submit"
            disabled={isPending}
            startIcon={<BTNLoader isLoading={isPending} />}
            variant="contained"
            color={"secondary"}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  function onCreateSuccess(data) {
    toast.success(`Currency saved successfully.`);
    refetch();
    reset(defaultValues);
    onClose();
  }

  function save(data) {
    mutation.mutate(data);
  }

  function formatCurrencyData(data) {
    try {
      if (!data) return [];
      return Object.keys(data).map((item) => ({
        label: item,
        value: item,
      }));
    } catch (error) {
      console.error("CURRENCY LIST FORMATTER:", error);
    }
  }

  function handleChangeValue(e, onChange) {
    try {
      if (!rates) return;
      onChange(e.target.value);
      setValue("currencyValue", 1 / Number(rates[e.target.value]));
    } catch (error) {
      console.error("CURRENCY CHANGE HANDLER:", error);
    }
  }
};

export default AddCurrency;
