import { TextField } from "@mui/material";
import React from "react";

const AppTextField = ({
  value,
  onChange = (e) => null,
  placeholder,
  ...props
}) => {
  return (
    <TextField
      fullWidth
      size="small"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="bg-white"
      {...props}
    />
  );
};

export default AppTextField;
