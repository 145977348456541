import httpservice from "./httpservice";

class AdministratorsService {
  create(data) {
    return httpservice.post("/admin/cp/administrators", data);
  }
  get(query = {}) {
    const params = {};
    params.keyword = query.keyword;
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    if (query?.status && query?.status !== "ALL") params.status = query.status;
    return httpservice.get("/admin/cp/administrators", { params });
  }

  getStats() {
    return httpservice.get("/admin/cp/administrators/status-stats");
  }
  activate(id) {
    const params = { id };
    const route = "/admin/cp/administrators/activate";

    return httpservice.put(route, {}, { params });
  }
  deactivate(id) {
    const params = { id };
    const route = "/admin/cp/administrators/deactivate";
    return httpservice.put(route, {}, { params });
  }
}

export default new AdministratorsService();
