import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
} from "@mui/material";
import React from "react";
import BTNLoader from "./BTNLoader";

const ConfirmationDialog = ({
  isOpen,
  onClose,
  title,
  body,
  type,
  actionTitle,
  isLoading,
  align = "",
  onSubmit,
}) => {
  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth="xs">
      <div className="d-flex justify-content-between align-items-center gap-4 p-3 py-2 pe-2">
        <div className="fs-5 fw-medium">{title || "Confirm"}</div>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <Divider className="bg-secondary-subtle" />
      <DialogContent>
        <div
          dangerouslySetInnerHTML={{ __html: body }}
          className={`my-3 ${align === "left" ? "text-left" : "text-center"}`}
        ></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={onSubmit}
          disabled={isLoading}
          startIcon={<BTNLoader isLoading={isLoading} />}
          variant="contained"
          color={type === "confirm" ? "secondary" : "error"}
        >
          {actionTitle || "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
