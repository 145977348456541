import { useMutation } from "@tanstack/react-query";
import React from "react";
import pushNotificationService from "../../../service/pushNotificationService";
import errorHandler from "../../../utils/errorHandler";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CREATE_NOTIFICATION_VALIDATION } from "../../../validations/notification";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Close, Image, UploadFile } from "@mui/icons-material";
import BTNLoader from "../../../lib/components/BTNLoader";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { toast } from "react-toastify";
import moment from "moment";
import PreviewNotification from "./notification-previewer";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

const defaultValues = {
  title: "",
  date: null,
  time: null,
  message: "",
  text: "",
  deliveryTypes: [],
  recipients: [],
  subscriptionPlans: [],
  image: null,
};

const DELIVERY_TYPES = [
  { label: "In App", value: "IN_APP" },
  { label: "Email", value: "EMAIL" },
  { label: "Sms", value: "SMS" },
  { label: "Push", value: "PUSH" },
];

const SUBSCRIPTION_PLANS = [
  { label: "Simple Start", value: "SIMPLE START" },
  { label: "Standard", value: "STANDARD" },
  { label: "Premium", value: "PREMIUM" },
];

const RECIPIENTS = [
  { label: "Employees", value: "EMPLOYEE" },
  { label: "Organizations", value: "ORGANIZATION" },
];

const CreateNotifications = ({ isOpen, onClose, refetch }) => {
  const imageRef = React.useRef();
  const [imageURL, setImageURL] = React.useState("");
  const [isPreview, setIsPreview] = React.useState(false);
  const [isMobileView, setIsMobileView] = React.useState(false);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

  const formHook = useForm({
    resolver: yupResolver(CREATE_NOTIFICATION_VALIDATION),
    mode: "all",
    defaultValues: defaultValues,
  });

  const errors = formHook.formState.errors;
  const control = formHook.control;
  const handleSubmit = formHook.handleSubmit;
  const reset = formHook.reset;
  const getValues = formHook.getValues;
  const setValue = formHook.setValue;
  const watch = formHook.watch;

  const deliveryTypes = watch("deliveryTypes");
  console.log(deliveryTypes);
  const mutation = useMutation({
    mutationFn: (data) => pushNotificationService.create(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      onCreateSuccess(data);
    },
  });

  React.useEffect(() => {
    if (!deliveryTypes.length) return;
    if (!deliveryTypes.includes("EMAIL")) setValue("message", "");

    //eslint-disable-next-line
  }, [deliveryTypes]);

  const { isPending } = mutation;
  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        maxWidth="sm"
        PaperProps={{
          className: "w-100",
          component: "form",
          onSubmit: handleSubmit(save),
        }}
      >
        <div className="d-flex justify-content-between align-items-center gap-4 p-3 py-2 pe-2">
          <div className="fs-5 fw-medium">New Message</div>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <Divider className="bg-secondary-subtle" />

        <DialogContent className="h-100 overflow-auto">
          <PreviewNotification
            isPreview={isPreview}
            isMobileView={isMobileView}
            setIsMobileView={setIsMobileView}
            data={{
              title: getValues("title"),
              message: getValues("message"),
              image: imageURL,
            }}
          />
          <div className={isPreview ? "d-none" : "d-block"}>
            <div className="row">
              <div className="col-12 col-md-6 mb-4">
                <div>
                  <label className="d-block">
                    Title
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Enter title"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        helperText={errors["title"]?.message}
                        error={!!errors["title"]}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4">
                <div>
                  <label className="d-block">
                    Recipients
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="recipients"
                    control={control}
                    render={({ field }) => (
                      <MultipleSelectMenu
                        placeholder={"Recipients"}
                        value={field.value}
                        error={!!errors["recipients"]}
                        onChange={(e) => field.onChange(e.target.value)}
                        items={RECIPIENTS}
                        helperText={errors["recipients"]?.message}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-4">
                <div>
                  <label className="d-block">
                    Subscription Plans
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="subscriptionPlans"
                    control={control}
                    render={({ field }) => (
                      <MultipleSelectMenu
                        placeholder={"Plans"}
                        value={field.value}
                        error={!!errors["subscriptionPlans"]}
                        onChange={(e) => field.onChange(e.target.value)}
                        items={SUBSCRIPTION_PLANS}
                        helperText={errors["subscriptionPlans"]?.message}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4">
                <div>
                  <label className="d-block">
                    Delivery Types
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="deliveryTypes"
                    control={control}
                    render={({ field }) => (
                      <MultipleSelectMenu
                        placeholder={"Delivery Types"}
                        value={field.value}
                        error={!!errors["deliveryTypes"]}
                        onChange={(e) => field.onChange(e.target.value)}
                        items={DELIVERY_TYPES}
                        helperText={errors["deliveryTypes"]?.message}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-4">
                <div>
                  <label className="d-block">
                    Date
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <FormControl error={!!errors.date} fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            value={field.value}
                            onChange={(e) => field.onChange(e)}
                            minDate={new Date()}
                            renderInput={(params) => (
                              <TextField className="bg-white" fullWidth size="small" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                        <FormHelperText>{errors["date"]?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4">
                <div>
                  <label className="d-block">
                    Time
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="time"
                    control={control}
                    render={({ field }) => (
                      <FormControl error={!!errors.time} fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            value={field.value}
                            minTime={null}
                            onChange={(e) => field.onChange(e)}
                            renderInput={(params) => (
                              <TextField fullWidth className="bg-white" size="small" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                        <FormHelperText>{errors["time"]?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
              </div>
            </div>
            {!["IN_APP", "SMS", "PUSH"].some((item) => deliveryTypes.includes(item)) ? null : (
              <div className="mb-4">
                <div>
                  <label className="d-block">
                    Message <span className="fs-xs">(120 words limit)</span>
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="message"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        size="small"
                        variant="outlined"
                        placeholder="Enter message here"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        helperText={errors["message"]?.message}
                        error={!!errors["message"]}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            {deliveryTypes.includes("EMAIL") ? (
              <div className="mb-4">
                <div>
                  <label className="d-block">
                    Email Content
                    <span className="text-danger">*</span>
                  </label>

                  <Controller
                    name="text"
                    control={control}
                    render={({ field }) => (
                      <FormControl error={!!errors.text} fullWidth>
                        <div className="border rounded">
                          <Editor
                            editorState={editorState}
                            editorClassName="p-3"
                            onEditorStateChange={onEditorStateChange}
                          />
                        </div>

                        <FormHelperText>{errors["text"]?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
              </div>
            ) : null}
            <div className="mb-4">
              <div>
                <label className="d-block">Image</label>
                <div className="border rounded overflow-hidden">
                  <div
                    style={{
                      backgroundImage: `url(${imageURL})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                    className="d-flex p-4 flex-column justify-content-center align-items-center"
                  >
                    <Image className="text-secondary fs-1" />
                    <Button
                      onClick={() => imageRef.current.click()}
                      variant="text"
                      color="secondary"
                      className="mb-2"
                      startIcon={<UploadFile />}
                    >
                      Choose File
                    </Button>
                    <div className="fs-xs">PNG, JPG, GIF up to 1MB</div>
                  </div>
                  <Controller
                    name="image"
                    control={control}
                    render={({ field }) => (
                      <input
                        className="d-none"
                        ref={imageRef}
                        type="file"
                        name="image"
                        id="image"
                        accept=".jpg, .png, .jpeg, .gif"
                        onChange={(e) => {
                          const image = e.target.files[0];
                          const imageURL = URL.createObjectURL(image);
                          field.onChange(image);
                          setImageURL(imageURL);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {isPreview ? (
            <Button type="button" onClick={() => setIsPreview(false)}>
              Back
            </Button>
          ) : (
            <Button type="button" disabled={!getValues("title")} onClick={() => setIsPreview(true)}>
              Preview
            </Button>
          )}
          <Button
            type="submit"
            disabled={isPending}
            startIcon={<BTNLoader isLoading={isPending} />}
            variant="contained"
            color={"secondary"}
          >
            Send Message
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  async function save(data) {
    if (data?.deliveryTypes?.includes("EMAIL") && !data?.text) {
      return toast.error("Email content is required.");
    } else if (!data?.deliveryTypes?.includes("EMAIL") && !data?.message) {
      return toast.error("Message is required.");
    }
    mutation.mutate(data);
  }

  function onEditorStateChange(editorState) {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const result = draftToHtml(convertToRaw(contentState));
    setValue("text", result);
  }

  function onCreateSuccess(data) {
    const date = moment(data?.data?.dateTime).format("Do MMM, YYYY");
    const time = moment(data?.data?.dateTime).format("LT");
    toast.success(`Scheduled notification for ${date} at ${time} has been successfully queued.`);
    refetch();
    reset(defaultValues);
    setImageURL("");
    setIsPreview(false);
    onClose();
  }
};

function MultipleSelectMenu(props) {
  const { value, onChange, items, error, helperText, placeholder } = props;
  return (
    <FormControl error={error} fullWidth>
      <Select
        multiple
        fullWidth
        size="small"
        displayEmpty
        value={value}
        onChange={onChange}
        renderValue={(selected) => selected.map((item) => item?.replace("_", " ")).join(", ")}
        inputProps={{ className: "bg-white" }}
      >
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
        {items?.map((item, index) => (
          <MenuItem value={item?.value} key={index}>
            <Checkbox size="small" checked={value.indexOf(item?.value) > -1} />
            <ListItemText primary={item?.label} />
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default CreateNotifications;
