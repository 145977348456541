import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EmptyState from "../../../lib/components/EmptyState";
import moment from "moment";
import { Link } from "react-router-dom";
import pathnames from "../../../constants/pathnames";

const UsersDataTable = ({ data, isLoading }) => {
  if (isLoading) return null;
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className="bg-light">
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell align="center">Sign-Up Platform</TableCell>
              <TableCell>Last Signed In</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link
                    to={`${pathnames.USERS}/${item?.id}`}
                    className="fw-medium"
                  >
                    {item?.firstName} {item?.lastName}
                  </Link>
                </TableCell>
                <TableCell>{item?.email}</TableCell>
                {item?.phone?.startsWith("+") ? (
                  <TableCell>{item?.phone}</TableCell>
                ) : (
                  <TableCell>
                    <span className={item?.phone ? "" : "d-none"}>
                      +{item?.phone}
                    </span>
                  </TableCell>
                )}

                <TableCell align="center">
                  {item?.tracking?.signup?.platform?.type}
                </TableCell>
                <TableCell>
                  {item?.tracking?.signin?.platform?.device}

                  <br />
                  {moment(item?.tracking?.signin?.updatedAt).format(
                    "YYYY-MM-DD LT"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </Paper>
  );
};

export default UsersDataTable;
