import httpservice from "./httpservice";
class StatService {
  overviewStats() {
    return httpservice.get("/admin/cp/meta/overview");
  }
  dailySubscribers(query = { period: "DAILY" }) {
    const params = {};
    if (query.period) params.period = query.period;
    return httpservice.get("/admin/cp/meta/subscribers-count-by-period", {
      params,
    });
  }
}

export default new StatService();
