import httpservice from "./httpservice";

class Currency {
  async create(data, id) {
    if (data) data.currencyValue = 1 / Number(data?.currencyValue);
    return id
      ? await httpservice.put(`/admin/cp/currencyrates/update?id=${id}`, data)
      : await httpservice.post("/admin/cp/currencyrates/create", data);
  }
  async delete(id) {
    return await httpservice.delete(`/admin/cp/currencyrates/delete?id=${id}`);
  }
  async get() {
    return await httpservice.get("/admin/cp/currencyrates/get-available");
  }
  async getAll() {
    return await httpservice.get(
      "/admin/cp/currencyrates/available-currencies"
    );
  }
}

export default new Currency();
