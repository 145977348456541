import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
} from "@mui/material";
import React from "react";
import BTNLoader from "../../../lib/components/BTNLoader";
import { useMutation, useQuery } from "@tanstack/react-query";
import referralsService from "../../../service/referralsService";
import errorHandler from "../../../utils/errorHandler";
import { toast } from "react-toastify";

const CommissionSettings = ({ isOpen, onClose }) => {
  const [isEdit, setIsEdit] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [values, setValues] = React.useState({
    new: "",
    renewal: "",
  });

  const id = selectedPlan?.id;

  const plansQuery = useQuery({
    retry: false,
    enabled: isOpen,
    queryKey: ["commission-plans"],
    queryFn: () => referralsService.getPlans(),
  });

  const mutation = useMutation({
    mutationFn: (data) => referralsService.setCommision(data, id),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      plansQuery.refetch();
      toast.success("Commission updated successfully.");
      setSelectedPlan(null);
      setIsEdit(false);
    },
  });

  React.useEffect(() => {
    if (!selectedPlan) return;
    setValues({
      new: selectedPlan?.commission?.new?.amount || "",
      renewal: selectedPlan?.commission?.renewal?.amount || "",
    });
  }, [selectedPlan]);

  const { data, isLoading } = plansQuery;
  const subscriptionPlans = data?.data?.data || [];

  if (isEdit)
    return (
      <Dialog
        onClose={handleClose}
        open={isOpen}
        maxWidth="sm"
        PaperProps={{ component: "form", onSubmit: handleSubmit }}
      >
        <div className="d-flex justify-content-between gap-4 p-3 py-2 pe-2">
          <div>
            <div className="fs-5 fw-medium">Commission Settings</div>
            <div className="fs-sm text-secondary">
              Indicate how much referrers are to gain on plans subscribed
            </div>
          </div>
          <div>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </div>
        <Divider className="bg-secondary-subtle" />
        <DialogContent>
          <div className="mb-3">
            <label htmlFor="">
              First Time Subscription <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <span className="input-group-text">%</span>
              <input
                type="number"
                className="form-control"
                aria-label="First Time Subscription"
                name="new"
                required
                value={values.new}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="">
              Subsequent Subscription <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <span className="input-group-text">%</span>
              <input
                required
                type="number"
                className="form-control"
                aria-label="Subsequent Subscription"
                value={values.renewal}
                name="renewal"
                onChange={handleChange}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} type="button">
            Cancel
          </Button>
          <Button
            disabled={mutation.isPending}
            startIcon={<BTNLoader isLoading={mutation.isPending} />}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  return (
    <Dialog onClose={handleClose} open={isOpen} maxWidth="sm">
      <div className="d-flex justify-content-between gap-4 p-3 py-2 pe-2">
        <div>
          <div className="fs-5 fw-medium">Commission Settings</div>
          <div className="fs-sm text-secondary">
            Indicate how much referrers are to gain on plans subscribed
          </div>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </div>
      <Divider className="bg-secondary-subtle" />
      <DialogContent>
        <div>
          {isLoading ? (
            <div className="py-5 d-flex justify-content-center">
              <BTNLoader isLoading />
            </div>
          ) : null}
          {subscriptionPlans.map((item) => (
            <div key={item?.name} className="d-flex border p-3 rounded mb-4">
              <div className="flex-grow-1">
                <div className="fw-medium fs-5">{item?.name}</div>
                <div className="fs-sm text-secondary">
                  First Time Subsctiption{" "}
                  {typeof item?.commission?.new?.amount === "number"
                    ? item?.commission?.new?.amount
                    : null}
                  %
                </div>
                <div className="fs-sm text-secondary">
                  Subsequent Subscription{" "}
                  {typeof item?.commission?.renewal?.amount === "number"
                    ? item?.commission?.renewal?.amount
                    : null}
                  %
                </div>
              </div>
              <div>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => {
                    setIsEdit(true);
                    setSelectedPlan(item);
                  }}
                >
                  Edit
                </Button>
              </div>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );

  function handleClose() {
    onClose();
    setSelectedPlan(null);
    setTimeout(() => setIsEdit(false), 500);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    mutation.mutate(values);
  }
};

export default CommissionSettings;
