import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import { Chip, InputAdornment, Pagination, Tab, Tabs } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AppDateField from "../../lib/components/AppDateField";
import AppSelectField from "../../lib/components/AppSelectField";
import AppTextField from "../../lib/components/AppTextField";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import organizationservice from "../../service/organizationservice";
import moment from "moment";
import PreloaderTable from "../../lib/components/PreloaderTable";
import SubscribersTable from "./components/subscriber-data-table";
import scrollHandler from "../../utils/scrollHandler";

const tabList = [
  { label: "All", value: "ALL" },
  { label: "Monthly", value: "MONTHLY" },
  { label: "Quarterly", value: "QUARTERLY" },
  { label: "Bi-annually", value: "BIANNUALLY" },
  { label: "Annually", value: "ANNUALLY" },
];

const Organizations = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "20";
  const plan = searchParams.get("plan") || "";
  const keyword = searchParams.get("keyword") || "";
  const dateFrom = searchParams.get("dateFrom") || null;
  const dateTo = searchParams.get("dateTo") || null;
  const subscriptionStatus = searchParams.get("subscriptionStatus") || "";
  const state = searchParams.get("state") || "";
  const isFreeTrial = searchParams.get("isFreeTrial") || "";

  const queries = {
    page,
    limit,
    plan,
    keyword,
    dateFrom,
    dateTo,
    subscriptionStatus,
    state,
    isFreeTrial,
  };
  const qKeys = [page, limit, plan, dateFrom, dateTo, subscriptionStatus, state, isFreeTrial];

  const organizationsQuery = useQuery({
    retry: false,
    queryKey: ["organizations", ...qKeys],
    queryFn: () => organizationservice.get({ ...queries }),
  });

  const statsQuery = useQuery({
    retry: false,
    enabled: !state,
    queryKey: ["subs-period-stats"],
    queryFn: organizationservice.subscriptionPeriodStats,
  });

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      organizationsQuery.refetch();
    }, 1000);
    return () => clearTimeout(timeoutId);
    //eslint-disable-next-line
  }, [keyword]);

  const { isPending: isLoading, data } = organizationsQuery;
  const { data: stats } = statsQuery;

  const organizationData = data?.data;
  const statsData = stats?.data?.data;

  return (
    <AppLayout>
      <Header title="Subscribers" description="Get insights to accounts on Jureb here" />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <div className="row mb-5">
          <div className="col-6 col-md-3 col-lg-4 mb-3">
            <div>
              <AppTextField
                placeholder="Enter Text Here..."
                value={keyword}
                onChange={(e) => handleChange(e, "keyword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon className="text-secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppDateField label="Date From" value={dateFrom} onChange={(e) => handleChange(e, "dateFrom")} />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppDateField
                label="Date To"
                value={dateTo}
                minDate={dateFrom}
                onChange={(e) => handleChange(e, "dateTo")}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppSelectField
                value={subscriptionStatus}
                placeholder="Status"
                onChange={(e) => handleChange(e, "subscriptionStatus")}
                items={[
                  { label: "All", value: "ALL" },
                  { label: "Active", value: "ACTIVE" },
                  { label: "Inactive", value: "INACTIVE" },
                  { label: "Expired", value: "EXPIRED" },
                ]}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppSelectField
                value={isFreeTrial}
                placeholder="Subscription"
                onChange={(e) => handleChange(e, "isFreeTrial")}
                items={[
                  { label: "All", value: "ALL" },
                  { label: "Paid Subscribers", value: "false" },
                  { label: "Free Trial Subscribers", value: "true" },
                ]}
              />
            </div>
          </div>
        </div>
        <div className={`border-bottom mb-5 ${state ? "d-none" : ""}`}>
          <Tabs value={plan || "ALL"} onChange={handleChangeTab}>
            {tabList?.map((item, index) => {
              let value = 0;
              if (statsData) {
                value = statsData[item?.value.toLowerCase()];
              }
              return <Tab key={index} value={item?.value} label={<TabLabel title={item?.label} label={value} />} />;
            })}
          </Tabs>
        </div>
        <PreloaderTable isLoading={isLoading} />
        <SubscribersTable isLoading={isLoading} data={organizationData?.data} />
        <div className="my-3">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div>
              <AppSelectField
                value={limit}
                placeholder="Limit"
                onChange={(e) => handleChange(e, "limit")}
                items={[
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                ]}
              />
            </div>
            <div>
              <Pagination
                page={Number(page)}
                count={organizationData?.totalPages}
                onChange={(_, value) => {
                  handleChange(value, "page");
                }}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );

  function handleChangeTab(_, value) {
    searchParams.set("plan", value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }

  function handleChange(e, name = "") {
    const value = name.includes("date") ? moment(e).format("YYYY-MM-DD") : name === "page" ? e : e.target.value;
    searchParams.set(name, value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
    if (name === "page") scrollHandler();
  }
};

function TabLabel({ title, label }) {
  return (
    <div className="d-flex gap-3 align-items-center">
      <span>{title}</span>
      <Chip label={label} />
    </div>
  );
}

export default Organizations;
