import React from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import pathnames from "../../../constants/pathnames";
import { Download } from "@mui/icons-material";
import EmptyState from "../../../lib/components/EmptyState";

const InvoicesDataTable = ({ data, isLoading }) => {
  if (isLoading) return null;
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className="bg-light">
            <TableRow>
              <TableCell>Invoice Number</TableCell>
              <TableCell>BIlling Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Plan</TableCell>
              <TableCell>Period</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <TableRow
                key={item?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{item?.number}</TableCell>
                <TableCell>
                  {moment(item?.paidAt).format("Do MMM, YYYY")}
                </TableCell>
                <TableCell>
                  <Status status={item?.status} />
                </TableCell>
                <TableCell>{item?.subscriptionMeta?.planName}</TableCell>
                <TableCell>{item?.subscriptionMeta?.billingInterval}</TableCell>
                <TableCell>
                  <Link to={`${pathnames.ORGANIZATIONS_INVOICES}/${item?.id}`}>
                    <Button
                      variant="text"
                      color="secondary"
                      endIcon={<Download />}
                    >
                      Download{" "}
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </Paper>
  );
};

function Status({ status }) {
  if (status === "PAID")
    return (
      <div className="badge rounded-pill  fw-medium fs-sm bg-success text-success bg-opacity-25">
        Active
      </div>
    );
  if (status === "EXPIRED")
    return (
      <div className="badge bg-warning text-warning rounded-pill fw-medium fs-sm bg-opacity-25">
        Expired
      </div>
    );
  return null;
}

export default InvoicesDataTable;
