import { toast } from "react-toastify";

const errorPayload = (ex) => {
  return {
    message: Array.isArray(ex?.data?.message)
      ? ex?.data.message[0]
      : ex?.data?.message?.message
      ? ex?.data?.message?.message
      : ex?.data?.message || ex?.data || "An unexpected error occurred.",
  };
};

function errorHandler(ex) {
  const isConnected = window.navigator.onLine;
  if (!isConnected) return;
  const error = errorPayload(ex).message;
  let errorMessage = "";
  if (Array?.isArray(error)) {
    errorMessage = Object.values(error[0])[0];
  } else if (typeof error === "object") {
    errorMessage = Object.values(error)[0];
  } else if (typeof error === "string" && error?.length < 255) {
    errorMessage = String(error);
  }

  if (
    typeof ex?.data?.message === "string" &&
    ex?.data?.message?.trim()?.toLowerCase()?.includes("authentication")
  ) {
    toast.error(errorMessage);
    window.location.replace("/signout");
  } else if (ex && ex.status >= 400 && ex.status < 500) {
    toast.error(errorMessage);
  } else if (ex && ex.status >= 500) {
    toast.error("An unexpected error occurred.");
  } else if (typeof ex === "string") {
    toast.error(ex);
  }
}

export default errorHandler;
