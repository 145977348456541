import React from "react";
import { Link } from "react-router-dom";
import JurebLogo from "../../lib/assets/icons/logo.svg";
import MailSentIcon from "../../lib/assets/icons/mail-sent.svg";
import { Paper } from "@mui/material";
import pathnames from "../../constants/pathnames";

const ForgotPasswordSuccess = () => {
  return (
    <div
      style={{ minHeight: "100vh" }}
      className="d-flex align-items-center bg-light"
    >
      <div className="container-xxl">
        <Paper className="border mx-auto my-5" style={{ maxWidth: 600 }}>
          <div className="p-3 mx-auto my-5" style={{ maxWidth: 400 }}>
            <div className="d-flex justify-content-center mb-3">
              <img src={JurebLogo} alt="Jureb" />
            </div>
            <div className="text-center fw-medium fs-4 mb-4">
              Check your Mail
            </div>
            <div className="text-center my-3 fs-sm">
              We have sent an email to your inbox with directions on how to
              reset your password.
            </div>
            <div className="d-flex justify-content-center my-3 mb-5">
              <img src={MailSentIcon} alt="mail sent" />
            </div>
            <div className="mb-5 text-center">
              <Link to={pathnames.SIGN_IN} className="text-primary">
                Back to Login
              </Link>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccess;
