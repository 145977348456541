import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import pathnames from "../../../constants/pathnames";
import EmptyState from "../../../lib/components/EmptyState";
import { moneyFormat } from "../../../utils/moneyFormat";

const PaymentsDataTable = ({ data, isLoading }) => {
  if (isLoading) return null;

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className="bg-light">
            <TableRow>
              <TableCell>Client</TableCell>
              <TableCell>Payment Id</TableCell>
              <TableCell>Payment Date</TableCell>
              <TableCell>Amount Paid</TableCell>
              <TableCell>Plan</TableCell>
              <TableCell>Period</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <TableRow
                key={item?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link
                    to={`${pathnames.ORGANIZATIONS}/${item?.organizationId}`}
                    className="fw-medium"
                  >
                    {item?.organizationMeta?.name}
                  </Link>
                </TableCell>
                <TableCell>{item?.paymentId}</TableCell>
                <TableCell>
                  {moment(item?.paidAt).format("Do MMM, YYYY")}
                </TableCell>
                <TableCell>{moneyFormat(item?.amountPaid)}</TableCell>
                <TableCell align="left">
                  {item?.subscriptionMeta?.planName}
                </TableCell>
                <TableCell align="left">
                  {item?.subscriptionMeta?.billingInterval}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </Paper>
  );
};

export default PaymentsDataTable;
