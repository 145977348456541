import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import organizationservice from "../../service/organizationservice";
import { Button, Divider, IconButton, Pagination } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import moment from "moment";
import EastIcon from "@mui/icons-material/East";
import AppDateField from "../../lib/components/AppDateField";
import AppSelectField from "../../lib/components/AppSelectField";
import InvoicesDataTable from "./components/invoices-data-table";
import pathnames from "../../constants/pathnames";
import PreloaderTable from "../../lib/components/PreloaderTable";
import ConfirmationDialog from "../../lib/components/ConfirmationDialog";
import errorHandler from "../../utils/errorHandler";
import UpgradeSubscription from "./components/upgrade-subscription";
import BTNLoader from "../../lib/components/BTNLoader";

const OrganizationDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isCancel, setIsCancel] = React.useState(false);
  const [isUpgrade, setIsUpgrade] = React.useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "10";
  const plan = searchParams.get("plan") || "";
  const period = searchParams.get("period") || "";
  const dateFrom = searchParams.get("dateFrom") || null;
  const dateTo = searchParams.get("dateTo") || null;

  const qKeys = [page, limit, plan, period, dateFrom, dateTo, id];
  const queries = { page, limit, plan, period, dateFrom, dateTo, id };

  const organizationQuery = useQuery({
    retry: false,
    queryKey: ["organizations-details", id],
    queryFn: () => organizationservice.getOne({ id }),
  });

  const invoicesQuery = useQuery({
    retry: false,
    queryKey: ["organizations-invoice", ...qKeys],
    queryFn: () => organizationservice.getInvoices({ ...queries }),
  });

  const cancelMutation = useMutation({
    mutationFn: (data) => organizationservice.cancelSubscription(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      setIsCancel(false);
      organizationQuery.refetch();
    },
  });

  const { data, isPending } = organizationQuery;
  const { data: invoicesRes, isPending: isLoadingInvoice } = invoicesQuery;
  const organization = data?.data?.data;
  const invoicesData = invoicesRes?.data;

  return (
    <AppLayout>
      <Header
        title="Subscribers"
        description="Get insights to accounts on Jureb here"
      />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <IconButton
          className="bg-secondary-subtle mb-3"
          onClick={() => navigate(-1)}
        >
          <ArrowBack />
        </IconButton>
        <div>
          <div className="d-flex justify-content-between">
            <div className="fs-4 fw-medium mb-3">{organization?.name}</div>
            <BTNLoader isLoading={isPending} />
          </div>
          <div className="row">
            <div className="col-12 col-md-5 mb-4">
              <div className="bg-white rounded border">
                <div className="p-3">
                  <div className="d-flex gap-4 mb-2">
                    <div className="flex-grow-1 fw-medium fs-5">
                      {organization?.subscription?.planName}{" "}
                      {organization?.subscription?.isFreeTrial ? (
                        <span>
                          - <span className="text-danger">(Free Trial)</span>
                        </span>
                      ) : null}
                    </div>
                    <div className="">
                      <Status status={organization?.subscription?.status} />
                    </div>
                  </div>
                  <div className="mb-2 fs-sm">
                    <span className="text-secondary me-2">Billed:</span>
                    <span>{organization?.subscription?.billingInterval}</span>
                  </div>
                  <div className="mb-2 fs-sm">
                    <span className="text-secondary me-2">Active Users:</span>
                    <span>{organization?.membersCount} member(s)</span>
                  </div>
                  <div className="mb-2 fs-sm">
                    <span className="text-secondary me-2">
                      Subscription Date:
                    </span>
                    <span>
                      {moment(organization?.subscription?.startAt).format(
                        "Do MMM, YYYY"
                      )}
                    </span>
                  </div>
                  <div className="mb-2 fs-sm">
                    <span className="text-secondary me-2">Due Date:</span>
                    <span>
                      {moment(organization?.subscription?.endAt).format(
                        "Do MMM, YYYY"
                      )}
                    </span>
                  </div>
                </div>
                <Divider />
                <div className="p-1 px-3 d-flex justify-content-between align-items-center gap-3">
                  <Button
                    variant="text"
                    className="text-danger"
                    onClick={() => setIsCancel(true)}
                  >
                    Cancel Plan
                  </Button>
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => setIsUpgrade(true)}
                    endIcon={<EastIcon sx={{ color: "blue" }} />}
                  >
                    Change Plan{" "}
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4">
              <div>
                <div className="mb-3">
                  <div className="text-secondary fs-sm">Date Created:</div>
                  <div className="fs-sm fw-medium">
                    {moment(organization?.createAt).format("Do MMM, YYYY")}
                  </div>
                </div>
                <div className="mb-3">
                  <div className="text-secondary fs-sm">Admin Name:</div>
                  <div className="fs-sm fw-medium">
                    {organization?.owner?.lastName}{" "}
                    {organization?.owner?.firstName}
                  </div>
                </div>
                <div className="mb-3">
                  <div className="text-secondary fs-sm">Billing Address:</div>
                  <div className="fs-sm fw-medium">
                    {organization?.address?.houseNo}{" "}
                    {organization?.address?.street}{" "}
                    {organization?.address?.city} {organization?.address?.lga}{" "}
                    {organization?.address?.state}{" "}
                    {organization?.address?.country}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4">
              <div>
                <div className="mb-3">
                  <div className="text-secondary fs-sm">Email:</div>
                  <div className="fs-sm fw-medium">{organization?.email}</div>
                </div>
                <div className="mb-3">
                  <div className="text-secondary fs-sm">Organization ID:</div>
                  <div className="fs-sm fw-medium">{organization?.id}</div>
                </div>
                <div className="mb-3">
                  <div className="text-secondary fs-sm">Sign Up Platform:</div>
                  <div className="fs-sm fw-medium">
                    {organization?.owner?.tracking?.signup?.platform?.type}
                  </div>
                </div>
                <div className="mb-3">
                  <div className="text-secondary fs-sm">Sign Up Device:</div>
                  <div className="fs-sm fw-medium">
                    {organization?.owner?.tracking?.signup?.platform?.type}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="invoice-section">
            <div className="fs-4 fw-medium mt-3 mb-1">Invoices</div>
            <div className="d-flex">
              <div className="flex-grow-1">
                <div className="row mb-3">
                  <div className="col-6 col-md-3 col-lg-2 mb-3">
                    <div>
                      <AppDateField
                        label="Date From"
                        value={dateFrom}
                        onChange={(e) => handleChange(e, "dateFrom")}
                      />
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-2 mb-3">
                    <div>
                      <AppDateField
                        label="Date To"
                        value={dateTo}
                        minDate={dateFrom}
                        onChange={(e) => handleChange(e, "dateTo")}
                      />
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-2 mb-3">
                    <div>
                      <AppSelectField
                        value={plan}
                        placeholder="Plan"
                        onChange={(e) => handleChange(e, "plan")}
                        items={[
                          { label: "All", value: "ALL" },
                          { label: "Simple Start", value: "simple start" },
                          { label: "Standard", value: "standard" },
                          { label: "Premium", value: "premium" },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-2 mb-3">
                    <div>
                      <AppSelectField
                        value={period}
                        placeholder="Period"
                        onChange={(e) => handleChange(e, "period")}
                        items={[
                          { label: "All", value: "ALL" },
                          { label: "Monthly", value: "monthly" },
                          { label: "Quarterly", value: "quarterly" },
                          { label: "Bi-Annually", value: "biannually" },
                          { label: "Annually", value: "annually" },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Link
                className="fw-medium d-block"
                to={`${pathnames.ORGANIZATIONS_ADMINS}/${id}`}
              >
                View Users
              </Link>
            </div>
            <PreloaderTable isLoading={isLoadingInvoice} />
            <InvoicesDataTable data={invoicesData?.data} />
            <div className="my-3">
              <div className="d-flex align-items-center gap-4 justify-content-between">
                <div>
                  <AppSelectField
                    value={limit}
                    placeholder="Limit"
                    onChange={(e) => handleChange(e, "limit")}
                    items={[
                      { label: "10", value: "10" },
                      { label: "20", value: "20" },
                      { label: "30", value: "30" },
                      { label: "40", value: "40" },
                      { label: "50", value: "50" },
                    ]}
                  />
                </div>
                <div>
                  <Pagination
                    page={Number(page)}
                    count={invoicesData?.totalPages}
                    onChange={(_, value) => {
                      handleChange(value, "page");
                    }}
                    shape="rounded"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        align="left"
        type="cancel"
        isOpen={isCancel}
        actionTitle="Cancel Plan"
        title="Cancel Plan"
        onSubmit={cancelSubscription}
        onClose={() => setIsCancel(false)}
        isLoading={cancelMutation.isPending}
        body="If you cancel this Jureb subscription, the user will lose access to the benefits of this plan. Team members would also not be able to log into their accounts. Please note that cancellation takes effect at the end of the current subscription."
      />
      <UpgradeSubscription
        id={id}
        isOpen={isUpgrade}
        onClose={() => setIsUpgrade(false)}
        refetch={organizationQuery.refetch}
      />
    </AppLayout>
  );

  async function cancelSubscription() {
    cancelMutation.mutate({ organizationId: id });
  }

  function handleChange(e, name = "") {
    const value = name.includes("date")
      ? moment(e).format("YYYY-MM-DD")
      : name === "page"
      ? e
      : e.target.value;
    searchParams.set(name, value);
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    if (name === "page") window.scrollTo(0, 0);
  }
};

function Status({ status }) {
  if (status === "ACTIVE")
    return (
      <div className="badge rounded-pill  fw-medium fs-sm bg-primary text-primary bg-opacity-25">
        Active
      </div>
    );
  if (status === "EXPIRED")
    return (
      <div className="badge bg-warning text-warning rounded-pill fw-medium fs-sm bg-opacity-25">
        Expired
      </div>
    );
  if (status === "INACTIVE")
    return (
      <div className="badge bg-danger text-danger rounded-pill fw-medium fs-sm bg-opacity-25">
        Inactive
      </div>
    );
  return null;
}

export default OrganizationDetails;
