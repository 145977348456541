import httpservice from "./httpservice";
class NotificationService {
  get(query = {}) {
    const params = {};
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    return httpservice.get("/admin/cp/activities/admin", { params });
  }
}

export default new NotificationService();
