import React from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EmptyState from "../../../lib/components/EmptyState";
import moment from "moment";
import { moneyFormat } from "../../../utils/moneyFormat";
import pathnames from "../../../constants/pathnames";
import { Link } from "react-router-dom";

const PayoutsDataTable = ({ data, isLoading }) => {
  if (isLoading) return null;
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className="bg-light">
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Total Paid</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <TableRow
                key={item?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{item?.description}</TableCell>
                <TableCell>
                  {moment(item?.paidAt).format("Do MMM, YYYY")}
                </TableCell>
                <TableCell>{moneyFormat(item?.total, "NGN")}</TableCell>
                <TableCell>
                  <Status status={item?.status} />
                </TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={`${
                      item?.status === "DRAFT"
                        ? pathnames.PAYOUTS__PROCESS
                        : pathnames.PAYOUTS
                    }/${item?.id}`}
                    color="secondary"
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </Paper>
  );
};

function Status({ status }) {
  if (status === "PAID")
    return (
      <div className="badge rounded-pill  fw-medium fs-sm bg-success text-success bg-opacity-25">
        Paid
      </div>
    );
  if (status === "CANCELED")
    return (
      <div className="badge bg-danger text-danger rounded-pill fw-medium fs-sm bg-opacity-25">
        Canceled
      </div>
    );
  if (status === "PENDING")
    return (
      <div className="badge bg-warning text-warning rounded-pill fw-medium fs-sm bg-opacity-25">
        Pending
      </div>
    );
  if (status === "DRAFT")
    return (
      <div className="badge bg-info text-info rounded-pill fw-medium fs-sm bg-opacity-25">
        Draft
      </div>
    );
  return null;
}

export default PayoutsDataTable;
