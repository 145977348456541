import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import { useQuery } from "@tanstack/react-query";
import statsservice from "../../service/statsservice";
import Image1 from "./assets/images/total-users.png";
import Image2 from "./assets/images/active-users.png";
import Image3 from "./assets/images/total-revenue.png";
import { Paper } from "@mui/material";
import { moneyFormat } from "../../utils/moneyFormat";
import DailySubscribers from "./components/daily-subscribers";
import RecentSubscribers from "./components/recent-subscribers";
import UserLogs from "./components/user-logs";
import SubscribersByLocation from "./components/subscribers-by-location";

const Dashboard = () => {
  const [period, setPeriod] = React.useState("DAILY");
  const overviewQuery = useQuery({
    queryKey: ["overview-stats"],
    queryFn: statsservice.overviewStats,
  });

  const dailySubscribersQuery = useQuery({
    queryKey: ["daily-subscribers", period],
    queryFn: () => statsservice.dailySubscribers({ period }),
  });

  const { data } = overviewQuery;
  const { data: dailySubs } = dailySubscribersQuery;
  const topStats = data?.data?.data?.topStats;

  const dailySubscribers = dailySubs?.data?.data;
  const userLogs = data?.data?.data?.logs;
  const recentSubscribers = data?.data?.data?.recentSubscribers?.slice(0, 4);
  const subscribersByLocation = data?.data?.data?.subscribersByLocation;

  return (
    <AppLayout>
      <Header title="Overview" description="Get insights to everything happening on Jureb here" />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <div className="row">
          <div className="col-12 col-md-4 mb-4">
            <Paper className="p-3 bg-white">
              <div className="mb-3">Total Users</div>
              <div className="d-flex justify-content-between align-items-end">
                <div className="fs-4 fw-medium">{topStats?.usersCount?.toLocaleString()}</div>
                <div className="">
                  <img src={Image1} alt="Total Users" />
                </div>
              </div>
            </Paper>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <Paper className="p-3 bg-white">
              <div className="mb-3">Active Users</div>
              <div className="d-flex justify-content-between align-items-end">
                <div className="fs-4 fw-medium">{topStats?.activeUsersCount?.toLocaleString()}</div>
                <div className="">
                  <img src={Image2} alt="Active Users" />
                </div>
              </div>
            </Paper>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <Paper className="p-3 bg-white">
              <div className="mb-3">Total Income</div>
              <div className="d-flex justify-content-between align-items-end">
                <div className="fs-4 fw-medium">{moneyFormat(topStats?.totalRevenue)}</div>
                <div className="">
                  <img src={Image3} alt="Total" />
                </div>
              </div>
            </Paper>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-8 mb-4">
            <DailySubscribers period={period} setPeriod={setPeriod} data={dailySubscribers} />
          </div>
          <div className="col-12 col-lg-4 mb-4">
            <RecentSubscribers data={recentSubscribers} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-5 mb-4">
            <UserLogs data={userLogs} />
          </div>
          <div className="col-12 col-lg-7 mb-4">
            <SubscribersByLocation data={subscribersByLocation} />
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Dashboard;
