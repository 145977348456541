import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import AppTextField from "../../lib/components/AppTextField";
import { useNavigate, useParams } from "react-router-dom";
import AppSelectField from "../../lib/components/AppSelectField";
import { IconButton, InputAdornment, Pagination } from "@mui/material";
import { ArrowBack, Search } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import organizationservice from "../../service/organizationservice";
import PreloaderTable from "../../lib/components/PreloaderTable";
import AdministratorsDataTable from "./components/administrators-data-table";
import scrollHandler from "../../utils/scrollHandler";

const OrganizationAdministrators = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "20";
  const keyword = searchParams.get("keyword") || "";
  const status = searchParams.get("status") || "";

  const qKeys = [page, limit, status];
  const queries = { page, limit, keyword, status, organizationId: id };

  const usersQuery = useQuery({
    retry: false,
    queryKey: ["organizations-administrators", ...qKeys],
    queryFn: () => organizationservice.getAdministrators(queries),
  });

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      usersQuery.refetch();
    }, 1000);
    return () => clearTimeout(timeoutId);
    //eslint-disable-next-line
  }, [keyword]);

  const { isPending: isLoading, data } = usersQuery;
  const usersData = data?.data;

  return (
    <AppLayout>
      <Header
        title="Administrators"
        description="Get insights to accounts on Jureb here"
      />

      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <IconButton
          className="bg-secondary-subtle mb-3"
          onClick={() => navigate(-1)}
        >
          <ArrowBack />
        </IconButton>
        <div className="row mb-5">
          <div className="col-6 col-md-3 col-lg-4 mb-3">
            <div>
              <AppTextField
                placeholder="Enter Text Here..."
                value={keyword}
                onChange={(e) => handleChange(e, "keyword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search className="text-secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppSelectField
                value={status}
                placeholder="Status"
                onChange={(e) => handleChange(e, "status")}
                items={[
                  { label: "All", value: "ALL" },
                  { label: "Active", value: "ACTIVE" },
                  { label: "Inactive", value: "INACTIVE" },
                ]}
              />
            </div>
          </div>
        </div>
        <PreloaderTable isLoading={isLoading} />
        <AdministratorsDataTable isLoading={isLoading} data={usersData?.data} />
        <div className="my-3">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div>
              <AppSelectField
                value={limit}
                placeholder="Limit"
                onChange={(e) => handleChange(e, "limit")}
                items={[
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                ]}
              />
            </div>
            <div>
              <Pagination
                page={Number(page)}
                count={usersData?.totalPages}
                onChange={(_, value) => {
                  handleChange(value, "page");
                }}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );

  function handleChange(e, name = "") {
    const value = name === "page" ? e : e.target.value;
    searchParams.set(name, value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
    if (name === "page") scrollHandler();
  }
};

export default OrganizationAdministrators;
