export const moneyFormat = (amount, currency = "") => {
  let defaultCurrency = "NGN";
  if (!!currency) defaultCurrency = currency;

  try {
    let number = Number(amount);
    let locale = "en-NG";

    if (isNaN(number)) number = 0;
    if (!defaultCurrency) {
      return new Intl.NumberFormat({
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number);
    }

    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: defaultCurrency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  } catch (error) {
    console.error("Currency format error:", error);
  }
};
