import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import EmptyState from "../../../lib/components/EmptyState";
import moment from "moment";
import pathnames from "../../../constants/pathnames";
import { moneyFormat } from "../../../utils/moneyFormat";

const ReferralsDataTable = ({ data, isLoading }) => {
  if (isLoading) return null;
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className="bg-light">
            <TableRow>
              <TableCell>Client</TableCell>
              <TableCell>Email Address</TableCell>
              <TableCell>Joined Date</TableCell>
              <TableCell>Plan</TableCell>
              <TableCell>Commission</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <TableRow
                key={item?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link
                    to={`${pathnames.ORGANIZATIONS}/${item?.organizationId}`}
                    className="fw-medium"
                  >
                    {item?.organizationMeta?.name}
                  </Link>
                </TableCell>
                <TableCell>{item?.organizationMeta?.email}</TableCell>
                <TableCell>
                  {moment(item?.organizationMeta?.createdAt).format(
                    "Do MMM, YYYY"
                  )}
                </TableCell>
                <TableCell>
                  {item?.organizationMeta?.subscriptionPlanName}
                </TableCell>
                <TableCell>
                  {moneyFormat(item?.earning?.total, "NGN")}
                </TableCell>
                <TableCell>
                  <Status status={item?.organizationMeta?.subscriptionStatus} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </Paper>
  );
};

function Status({ status }) {
  if (status === "ACTIVE")
    return (
      <div className="badge rounded-pill  fw-medium fs-sm bg-primary text-primary bg-opacity-25">
        Active
      </div>
    );
  if (status === "EXPIRED")
    return (
      <div className="badge bg-danger text-danger rounded-pill fw-medium fs-sm bg-opacity-25">
        Expired
      </div>
    );
  if (status === "INACTIVE")
    return (
      <div className="badge bg-warning text-warning rounded-pill fw-medium fs-sm bg-opacity-25">
        Inactive
      </div>
    );
  return null;
}

export default ReferralsDataTable;
