import React from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import pathnames from "../../../constants/pathnames";
import EmptyState from "../../../lib/components/EmptyState";

const ReferrersDataTable = ({
  data,
  isLoading,
  selected,
  setSelected,
  remunerationSelect,
  handleMenu,
  page = 1,
}) => {
  const eligibleReferrers = data?.filter(
    (item) =>
      item?.total?.earned - (item?.total?.paid + item?.total?.pending) > 0 &&
      !!item?.bank
  );

  React.useEffect(() => {
    if (isLoading) return;
    if (remunerationSelect !== "ALL") return;
    handleSelectAll();
    //eslint-disable-next-line
  }, [page, isLoading]);

  if (isLoading) return null;
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className="bg-light">
            <TableRow>
              <TableCell>
                <span className="d-flex gap-1 align-items-center">
                  <Checkbox
                    color="secondary"
                    size="small"
                    indeterminate={
                      !!selected?.length &&
                      selected?.length !== eligibleReferrers?.length
                    }
                    checked={remunerationSelect === "ALL"}
                    onChange={(e) => handleSelectAll(e)}
                  />
                  <span>Client</span>
                </span>
              </TableCell>
              <TableCell>Email Address</TableCell>
              <TableCell>Join Date</TableCell>
              <TableCell>Paid Invitees</TableCell>
              <TableCell align="center">Total Invited</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <TableRow
                key={item?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="">
                  <span className="d-flex gap-1 align-items-center">
                    <Checkbox
                      color="secondary"
                      size="small"
                      disabled={
                        item?.total?.earned -
                          (item?.total?.paid + item?.total?.pending) <=
                          0 || !item?.bank
                      }
                      checked={isExist(item?.id)}
                      onChange={(e) => handleSelect(item, e)}
                    />
                    <Link
                      to={`${pathnames.REFERRERS}/${item?.id}`}
                      className="fw-medium"
                    >
                      {item?.accountMeta?.firstName}{" "}
                      {item?.accountMeta?.lastName}
                    </Link>
                  </span>
                </TableCell>
                <TableCell className="">{item?.accountMeta?.email}</TableCell>
                <TableCell className="">
                  {moment(item?.createdAt).format("Do MMM, YYYY")}
                </TableCell>
                <TableCell className="">{item?.count?.paid}</TableCell>
                <TableCell className="">{item?.count?.referral}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </Paper>
  );

  function handleSelect(item, e) {
    const checked = e.target.checked;
    if (
      item?.total?.earned - (item?.total?.paid + item?.total?.pending) <= 0 ||
      !item?.bank
    ) {
      return;
    }
    if (checked) {
      item.checked = true;
      setSelected((p) => [...p, item]);
      return;
    }
    const filteredItems = selected.filter((f) => f?.id !== item?.id);
    setSelected(filteredItems);
  }
  function handleSelectAll(e) {
    const checked = e ? e.target.checked : true;
    if (checked) {
      handleMenu("remunerationSelect", "ALL");
      const result = data
        ?.map((item) => ({ ...item, checked: true }))
        ?.filter(
          (item) =>
            item?.total?.earned - (item?.total?.paid + item?.total?.pending) >
              0 && !!item?.bank
        );
      setSelected(result);
      return;
    }
    setSelected([]);
    handleMenu("remunerationSelect", "");
  }

  function isExist(id) {
    const ids = selected?.map((item) => item?.id);
    if (ids?.includes(id)) return true;
    return false;
  }
};

export default ReferrersDataTable;
