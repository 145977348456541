import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import pathnames from "../../../constants/pathnames";
import EmptyState from "../../../lib/components/EmptyState";

const SubscribersTable = ({ data, isLoading }) => {
  if (isLoading) return null;
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className="bg-light">
            <TableRow>
              <TableCell>Client</TableCell>
              <TableCell>Email Address</TableCell>
              <TableCell>Joined Date</TableCell>
              <TableCell>Contact Person</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Members</TableCell>
              <TableCell>Sign-up Platform</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <TableRow key={item?.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>
                  <Link to={`${pathnames.ORGANIZATIONS}/${item?.id}`} className="fw-medium">
                    {item?.name}
                  </Link>
                </TableCell>
                <TableCell>{item?.email}</TableCell>
                <TableCell>{moment(item?.joinDate).format("Do MMM, YYYY")}</TableCell>
                <TableCell>
                  <Link to={`${pathnames.USERS}/${item?.owner?.accountId}`} className="fw-medium">
                    {item?.owner?.firstName} {item?.owner?.lastName}
                  </Link>
                </TableCell>
                <TableCell>
                  <Status status={item?.subscription?.status} />
                </TableCell>
                <TableCell>{item?.membersCount} user(s)</TableCell>
                <TableCell>{item?.owner?.tracking?.signup?.platform?.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </Paper>
  );
};

function Status({ status }) {
  if (status === "ACTIVE")
    return <div className="badge rounded-pill  fw-medium fs-sm bg-primary text-primary bg-opacity-25">Active</div>;
  if (status === "EXPIRED")
    return <div className="badge bg-danger text-danger rounded-pill fw-medium fs-sm bg-opacity-25">Expired</div>;
  if (status === "INACTIVE")
    return <div className="badge bg-warning text-warning rounded-pill fw-medium fs-sm bg-opacity-25">Inactive</div>;
  return <div className="badge bg-warning text-warning rounded-pill fw-medium fs-sm bg-opacity-25">Inactive</div>;
}

export default SubscribersTable;
