import httpservice from "./httpservice";

class AuthService {
  signin(data = { email: "", password: "" }) {
    return httpservice.post("/admin/auth/auth-tokens", data);
  }
  forgotPassword(data = { email: "" }) {
    return httpservice.post("/admin/auth/password-reset-request", data);
  }
  resetPassword(data = { password: "", tolen: "" }) {
    return httpservice.put("/admin/auth/password-reset", data);
  }
}

export default new AuthService();
