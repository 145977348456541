import Cookies from "js-cookie";

const saveToken = (data) => {
  if (!data) return;
  Cookies.set("administrator_access_auth", data?.accessToken, {
    expires: 1 / 8,
    sameSite: "strict",
    secure: true,
  });
};

export function getToken() {
  return Cookies.get("administrator_access_auth");
}

export default saveToken;
