import httpservice from "./httpservice";

class User {
  async get() {
    return await httpservice.get("/admin/cp/account");
  }
  find(query = {}) {
    const params = {};
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    if (query?.keyword) params.keyword = query.keyword;
    return httpservice.get("/admin/cp/users", {
      params,
    });
  }
  async update(data) {
    const formData = new FormData();
    if (data.firstName) formData.append("firstName", data.firstName);
    if (data.lastName) formData.append("lastName", data.lastName);
    if (data.image) formData.append("avatarUpload", data.image);
    return await httpservice.put("/admin/cp/account", formData);
  }
  async changePassword(data) {
    return await httpservice.put("/admin/cp/account/password-change", data);
  }
}

export default new User();
