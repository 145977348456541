import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import scrollHandler from "../../utils/scrollHandler";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppTextField from "../../lib/components/AppTextField";
import { Button, IconButton, InputAdornment, Pagination } from "@mui/material";
import { ArrowBack, CheckCircle, Download, Search } from "@mui/icons-material";
import AppSelectField from "../../lib/components/AppSelectField";
import moment from "moment";
import { useMutation, useQuery } from "@tanstack/react-query";
import referralsService from "../../service/referralsService";
import PreloaderTable from "../../lib/components/PreloaderTable";
import RemDataTable from "./components/rem-data-table";
import errorHandler from "../../utils/errorHandler";
import CompletePayoutModal from "./components/complete-payout";
import ConfirmationDialog from "../../lib/components/ConfirmationDialog";
import { toast } from "react-toastify";
import pathnames from "../../constants/pathnames";

const initialMenu = {
  isPaid: false,
  progressEvent: null,
  downloadProgress: false,
  isCancelled: false,
};
const ViewPayouts = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isMenu, setIsMenu] = React.useState(initialMenu);
  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "20";
  const keyword = searchParams.get("keyword") || "";
  const status = searchParams.get("status") || "";

  const qKeys = [page, limit, status, id];
  const query = { page, limit, keyword, status };

  const payoutQuery = useQuery({
    retry: false,
    queryKey: ["payout", id],
    queryFn: () => referralsService.getPayout(id),
  });
  const remunerationsQuery = useQuery({
    retry: false,
    queryKey: ["remunerations", ...qKeys],
    queryFn: () => referralsService.getPayoutRemunerations(query, id),
  });

  const mutation = useMutation({
    mutationFn: (data) => referralsService.cancelPayout(data, id),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      toast.success("Payout cancelled successfully...");
      payoutQuery.refetch();
      remunerationsQuery.refetch();
      handleMenu("isCancelled", false);
    },
  });

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      remunerationsQuery.refetch();
    }, 1000);
    return () => clearTimeout(timeoutId);
    //eslint-disable-next-line
  }, [keyword]);

  const payout = payoutQuery?.data?.data?.data;
  const remData = remunerationsQuery?.data?.data;

  return (
    <AppLayout>
      <Header
        title="Remunerations"
        description="Get insights to Referrers that have been paid from this page"
      />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <IconButton
          className="bg-secondary-subtle mb-3"
          onClick={() => navigate(-1)}
        >
          <ArrowBack />
        </IconButton>
        <div className="row mb-3">
          <div className="col-12 col-md-3 col-lg-4 mb-3">
            <div>
              <AppTextField
                placeholder="Enter Text Here..."
                value={keyword}
                onChange={(e) => handleChange(e, "keyword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search className="text-secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="col-12 col-md-3 col-lg-2 mb-3">
            <div>
              <AppSelectField
                value={status}
                placeholder="Status"
                onChange={(e) => handleChange(e, "status")}
                items={[
                  { label: "All", value: "ALL" },
                  { label: "Pending", value: "PENDING" },
                  { label: "Paid", value: "PAID" },
                  { label: "Canceled", value: "CANCELED" },
                ]}
              />
            </div>
          </div>
          <div className="col-12 col-md-3 col-lg-6 mb-3">
            <div className="d-flex flex-wrap gap-4 justify-content-md-end">
              {["CANCELED", "PAID"].includes(payout?.status) ? null : (
                <Button
                  onClick={() => handleMenu("isCancelled", true)}
                  variant="outlined"
                  color="danger"
                  className={!payout ? "d-none" : "bg-white"}
                >
                  Cancel Payout
                </Button>
              )}
              <Button
                endIcon={<Download />}
                variant="outlined"
                color="secondary"
                onClick={downloadDisbursementSheet}
                className={!payout ? "d-none" : "bg-white"}
              >
                Download
              </Button>
              <Button
                component={Link}
                to={`${pathnames.PAYOUTS__CONFIRM}/${id}?limit=${remData?.totalDocs}`}
                disabled={["CANCELED", "PAID"].includes(payout?.status)}
                endIcon={<CheckCircle />}
                variant="contained"
                color="secondary"
                className={!payout || !remData?.totalDocs ? "d-none" : ""}
              >
                Complete Payout
              </Button>
            </div>
          </div>
        </div>
        <PreloaderTable isLoading={remunerationsQuery.isLoading} />
        <RemDataTable
          isLoading={remunerationsQuery.isLoading}
          data={remData?.data}
        />
        <div className="my-3">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div>
              <AppSelectField
                value={limit}
                placeholder="Limit"
                onChange={(e) => handleChange(e, "limit")}
                items={[
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                ]}
              />
            </div>
            <div>
              <Pagination
                page={Number(page)}
                count={remData?.totalPages}
                onChange={(_, value) => {
                  handleChange(value, "page");
                }}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
      <CompletePayoutModal
        id={id}
        isOpen={isMenu.isPaid}
        payout={payout}
        refetch={() => remunerationsQuery.refetch()}
        onClose={() => handleMenu("isPaid", false)}
      />
      <ConfirmationDialog
        id={id}
        isOpen={isMenu.isCancelled}
        title="Cancel Payout"
        actionTitle="Yes, cancel"
        onClose={() => handleMenu("isCancelled", false)}
        onSubmit={cancelPayout}
        isLoading={mutation.isPending}
        body={`Are you sure you want to cancel this payout? Select "<span class="fw-medium">Yes, cancel</span>" to continue.`}
      />
    </AppLayout>
  );

  function cancelPayout() {
    if (["CANCELED", "PAID"].includes(payout?.status)) {
      return;
    }
    mutation.mutate({});
  }

  async function downloadDisbursementSheet() {
    try {
      await referralsService.downloadPayout(id);
    } catch (ex) {
      errorHandler(ex?.response);
    }
  }

  // function markAsPaid() {
  //   if (["CANCELED", "PAID"].includes(payout?.status)) {
  //     return;
  //   }
  //   mutation.mutate({});
  // }

  function handleMenu(name, value) {
    setIsMenu((prev) => ({ ...prev, [name]: value }));
  }

  function handleChange(e, name = "") {
    const value = name.includes("date")
      ? moment(e).format("YYYY-MM-DD")
      : name === "page"
      ? e
      : e.target.value;
    searchParams.set(name, value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
      { scrollY: 0 }
    );
    if (name === "page") scrollHandler();
  }
};

export default ViewPayouts;
