import { Divider, Paper } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import pathnames from "../../../constants/pathnames";
import { ArrowForwardIos } from "@mui/icons-material";

const SubscribersByLocation = ({ data }) => {
  return (
    <Paper className="h-100 d-flex flex-column">
      <div className="d-flex p-3 gap-4 justify-content-between align-items-end">
        <div>
          <div className="fs-5 fw-medium">Subscribers by Location</div>
        </div>
      </div>
      <Divider />
      <div className="d-flex p-3 flex-column justify-content-between">
        <div className="h-100">
          {data?.map((item, index) => {
            let state = item?.state?.toLowerCase().replace(/ /g, "+");
            return (
              <div key={index} className="d-flex gap-4 mb-3">
                <div className="flex-grow-1 fw-medium">{item?.state}</div>
                <div>{item?.count}</div>
                <div>
                  <Link to={`${pathnames.ORGANIZATIONS}?state=${state}`}>
                    View details
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-auto">
        <Divider />
        <div className="p-3 text-end">
          <Link to={pathnames.ORGANIZATIONS}>
            See all <ArrowForwardIos className="fs-6" />
          </Link>
        </div>
      </div>
    </Paper>
  );
};

export default SubscribersByLocation;
