import React from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EmptyState from "../../../lib/components/EmptyState";
import moment from "moment";
import { moneyFormat } from "../../../utils/moneyFormat";
import pathnames from "../../../constants/pathnames";
import { Link } from "react-router-dom";

const ConfirmPayoutDataTable = ({
  data,
  isLoading,
  setReferrers,
  disabled,
}) => {
  const count = data?.length;
  const selectedCount = data?.filter((item) => item?.isChecked).length;
  if (isLoading) return null;
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className="bg-light">
            <TableRow>
              <TableCell>
                <span className="d-flex gap-1 align-items-center">
                  <Checkbox
                    color="secondary"
                    size="small"
                    disabled={disabled}
                    indeterminate={selectedCount > 0 && selectedCount < count}
                    checked={count === selectedCount}
                    onChange={(e) => handleSelectAll(e)}
                  />
                  <span>Referrer Name</span>
                </span>
              </TableCell>
              <TableCell>Remuneration ID</TableCell>
              <TableCell>Payout Date</TableCell>
              <TableCell>Total Amount Paid</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <TableRow
                key={item?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <span className="d-flex gap-1 align-items-center">
                    <Checkbox
                      color="secondary"
                      size="small"
                      disabled={disabled}
                      checked={item?.isChecked}
                      onChange={(e) => handleSelect(item, e)}
                    />
                    <Link
                      to={`${pathnames.REFERRERS}/${item?.referrerId}`}
                      className="fw-medium"
                    >
                      {item?.referrerMeta?.firstName}{" "}
                      {item?.referrerMeta?.lastName}
                    </Link>
                  </span>
                </TableCell>
                <TableCell>{item?.id}</TableCell>
                <TableCell>
                  {moment(item?.createdAt).format("Do MMM, YYYY")}
                </TableCell>
                <TableCell>{moneyFormat(item?.amount, "NGN")}</TableCell>
                <TableCell>
                  <Status status={item?.status} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </Paper>
  );

  function handleSelect(item, e) {
    const checked = e.target.checked;
    setReferrers(
      data?.map((r) => {
        if (r?.id === item?.id) return { ...r, isChecked: checked };
        return r;
      })
    );
  }

  function handleSelectAll(e) {
    const checked = e.target.checked;
    setReferrers(data?.map((r) => ({ ...r, isChecked: checked })));
  }
};

function Status({ status }) {
  if (status === "PAID")
    return (
      <div className="badge rounded-pill  fw-medium fs-sm bg-success text-success bg-opacity-25">
        Paid
      </div>
    );
  if (status === "CANCELED")
    return (
      <div className="badge bg-danger text-danger rounded-pill fw-medium fs-sm bg-opacity-25">
        Canceled
      </div>
    );
  if (status === "FAILED")
    return (
      <div className="badge bg-danger text-danger rounded-pill fw-medium fs-sm bg-opacity-25">
        Failed
      </div>
    );
  if (status === "PENDING")
    return (
      <div className="badge bg-warning text-warning rounded-pill fw-medium fs-sm bg-opacity-25">
        Pending
      </div>
    );
  if (status === "DRAFT")
    return (
      <div className="badge bg-info text-info rounded-pill fw-medium fs-sm bg-opacity-25">
        Draft
      </div>
    );
  return null;
}

export default ConfirmPayoutDataTable;
