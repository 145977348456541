import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UPGRADE_SUB_VALIDATION } from "../../../validations/organization";
import { useMutation, useQuery } from "@tanstack/react-query";
import organizationservice from "../../../service/organizationservice";
import errorHandler from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import AppSelectField from "../../../lib/components/AppSelectField";
import BTNLoader from "../../../lib/components/BTNLoader";

const defaultValues = {
  planId: "",
  reason: "",
  interval: "",
};

const UpgradeSubscription = ({ isOpen, onClose, refetch, id }) => {
  const formHook = useForm({
    resolver: yupResolver(UPGRADE_SUB_VALIDATION),
    mode: "all",
    defaultValues: defaultValues,
  });

  const errors = formHook.formState.errors;
  const control = formHook.control;
  const handleSubmit = formHook.handleSubmit;

  const plansQuery = useQuery({
    retry: false,
    enabled: isOpen,
    queryKey: ["subscription-plans"],
    queryFn: organizationservice.getSubscriptionPlans,
  });

  const mutation = useMutation({
    mutationFn: (data) => organizationservice.upgradeSubscription(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      toast.success("Subscription updated successfully.");
      refetch();
      onClose();
    },
  });

  async function save(data) {
    mutation.mutate({ ...data, organizationId: id });
  }

  const { isPending } = mutation;
  const { data } = plansQuery;
  const plans = data?.data?.data || [];

  return (
    <React.Fragment>
      <Dialog
        onClose={onClose}
        open={isOpen}
        maxWidth="xs"
        PaperProps={{
          className: "w-100",
          component: "form",
          onSubmit: handleSubmit(save),
        }}
      >
        <div className="d-flex justify-content-between align-items-center gap-4 p-3 py-2 pe-2">
          <div className="fs-5 fw-medium">Upgrade Subscription</div>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <Divider className="bg-secondary-subtle" />
        <DialogContent className="h-100 overflow-auto">
          <div className="mb-4">
            <label htmlFor="" className="d-block">
              Subscription Plan <span className="text-danger">*</span>
            </label>
            <Controller
              name="planId"
              control={control}
              render={({ field }) => (
                <AppSelectField
                  value={field.value}
                  placeholder="Select Plan"
                  onChange={(e) => field.onChange(e.target.value)}
                  helperText={errors["planId"]?.message}
                  error={!!errors["planId"]}
                  items={plans?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                />
              )}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="" className="d-block">
              Period<span className="text-danger">*</span>
            </label>
            <Controller
              name="interval"
              control={control}
              render={({ field }) => (
                <AppSelectField
                  value={field.value}
                  placeholder="Select Period"
                  onChange={(e) => field.onChange(e.target.value)}
                  helperText={errors["interval"]?.message}
                  error={!!errors["interval"]}
                  items={[
                    { label: "Monthly", value: "MONTHLY" },
                    { label: "Quarterly", value: "QUARTERLY" },
                    { label: "Bi-Annually", value: "BIANNUALLY" },
                    { label: "Annually", value: "ANNUALLY" },
                  ]}
                />
              )}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="" className="d-block">
              Reason
            </label>
            <Controller
              name="reason"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter reason"
                  value={field.value}
                  multiline
                  rows={3}
                  onChange={(e) => field.onChange(e.target.value)}
                  helperText={errors["reason"]?.message}
                  error={!!errors["reason"]}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isPending}
            startIcon={<BTNLoader isLoading={isPending} />}
            variant="contained"
            color={"secondary"}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default UpgradeSubscription;
