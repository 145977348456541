import React from "react";
import Cookies from "js-cookie";
import pathnames from "../constants/pathnames";

let idleTime = 1000 * 60 * 15;

const useWindowOnIdle = () => {
  React.useEffect(() => {
    let timeout;

    function clearCookies() {
      Cookies.remove("administrator_access_auth");
      window.location.replace(pathnames.SIGN_OUT);
    }

    function resetTimer() {
      clearTimeout(timeout);
      timeout = setTimeout(clearCookies, idleTime);
    }

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    timeout = setTimeout(clearCookies, idleTime);

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      clearTimeout(timeout);
    };
  }, []);

  return {};
};

export default useWindowOnIdle;
