const pathnames = {
  HOME: "/",
  AUTH: "/auth",
  SIGN_IN: "/signin",
  SIGN_OUT: "/signout",
  DASHBOARD: "/dashboard",
  NOTIFICATIONS: "/notifications",
  USERS: "/users",
  SETTINGS: "/settings",
  SETTINGS_PROFILE: "/settings/profile",
  SETTINGS_CHANGE_PASSWORD: "/settings/change-password",
  SETTINGS_CURRENCY_RATES: "/settings/currency-rates",
  SETTINGS_TAX_RATES: "/settings/tax-rates",
  ACCOUNT: "/account",
  PROFILE: "/account/profile",
  FORGOT_PASSWORD: "/account/recovery/forgot-password",
  FORGOT_PASSWORD_SUCCESS: "/account/recovery/forgot-password-success",
  RESET_PASSWORD: "/account/recovery/reset-password",
  ORGANIZATIONS: "/organizations",
  ORGANIZATIONS_ADMINS: "/organizations/administrators",
  ORGANIZATIONS_INVOICES: "/organizations/invoices",
  ADMINISTRATORS: "/administrators",
  PAYMENTS: "/payments",
  PAYOUTS: "/payouts",
  PAYOUTS__PROCESS: "/payouts/process",
  PAYOUTS__CONFIRM: "/payouts/confirm",
  PUSH_NOTIFICATIONS: "/push-notifications",
  REFERRERS: "/referrers",
  REFERRALS: "/referrers",
  REFERRALS_ORGANIZATIONS: "/referrers/earnings",
  REFERRALS_ORGANIZATIONS_PREVIEW: "/referrers/earnings/preview",
  ADMIN_LOGS: "/administrators/logs",
  ORGANIZATION_LOGS: "/organizations/logs",
};

export default pathnames;
