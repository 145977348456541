import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import BTNLoader from "../../../lib/components/BTNLoader";
import { useMutation } from "@tanstack/react-query";
import referralsService from "../../../service/referralsService";
import errorHandler from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import NumberFormatCustom from "../../../lib/components/CurrencyFormat";

const initialValues = {
  amount: "",
  type: "PERCENTAGE",
};

const MENU_ITEMS = [
  { label: "Payment Type", value: "" },
  { label: "Fixed", value: "FIXED" },
  { label: "Percentage", value: "PERCENTAGE" },
];
const ReferrerDiscountSettings = ({ isOpen, onClose, data, refetch, id }) => {
  const [values, setValues] = React.useState(initialValues);

  const mutation = useMutation({
    mutationFn: (data) => referralsService.updateReferrer(data, { id }),
    onError: ({ response }) => errorHandler(response),
    onSuccess: () => {
      toast.success("Discount updated successfully.");
      refetch();
      onClose();
    },
  });

  React.useEffect(() => {
    if (!data) return;
    setValues(data);
  }, [data]);

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      maxWidth="sm"
      PaperProps={{ component: "form", onSubmit: handleSubmit }}
    >
      <div className="d-flex justify-content-between gap-4 p-3 py-2 pe-2">
        <div>
          <div className="fs-5 fw-medium">Discount Settings</div>
          <div className="fs-sm text-secondary">
            Indicate how much discount subscribers receives
          </div>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </div>
      <Divider className="bg-secondary-subtle" />
      <DialogContent>
        <div className="mb-3">
          <FormControl fullWidth>
            <Select
              variant="outlined"
              size="small"
              fullWidth
              required
              displayEmpty
              value={values?.type}
              inputProps={{ className: "bg-white" }}
              onChange={(e) => handleChange("type", e.target.value)}
            >
              {MENU_ITEMS.map((item, i) => (
                <MenuItem key={i} value={item.value} disabled={!item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="mb-3">
          <label htmlFor="">
            Amount <span className="text-danger">*</span>
          </label>
          {values?.type === "FIXED" ? (
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              required
              color="secondary"
              value={values?.amount}
              onChange={(e) => handleChange("amount", e.target.value)}
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    <span>₦</span>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              type="number"
              required
              color="secondary"
              value={values?.amount}
              InputProps={{ inputProps: { min: 0, max: 100, step: 0.01 } }}
              onChange={(e) => handleChange("amount", e.target.value)}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} type="button">
          Cancel
        </Button>
        <Button
          disabled={mutation.isPending}
          startIcon={<BTNLoader isLoading={mutation.isPending} />}
          variant="contained"
          color="secondary"
          type="submit"
        >
          Save Settings
        </Button>
      </DialogActions>
    </Dialog>
  );

  function handleClose() {
    onClose();
  }

  function handleChange(name, value) {
    if (name === "type") {
      setValues((prev) => ({ ...prev, [name]: value, amount: "" }));
      return;
    }
    setValues((prev) => ({ ...prev, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (Number(values?.amount) < 0) {
      toast.error(`Amount cannot be less than 0`);
      return;
    }
    if (values?.type === "PERCENTAGE" && Number(values?.amount) > 100) {
      toast.error(`Amount cannot be greater than 100 when type is percentage`);
      return;
    }

    mutation.mutate({ signupDiscount: values });
  }
};

export default ReferrerDiscountSettings;
