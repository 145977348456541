import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import scrollHandler from "../../utils/scrollHandler";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppTextField from "../../lib/components/AppTextField";
import {
  Button,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ArrowBack, Search } from "@mui/icons-material";
import AppSelectField from "../../lib/components/AppSelectField";
import moment from "moment";
import { useMutation, useQuery } from "@tanstack/react-query";
import referralsService from "../../service/referralsService";
import PreloaderTable from "../../lib/components/PreloaderTable";
import pathnames from "../../constants/pathnames";
import { moneyFormat } from "../../utils/moneyFormat";
import EmptyState from "../../lib/components/EmptyState";
import UpdateRemuneration from "./components/update-rem";
import ConfirmationDialog from "../../lib/components/ConfirmationDialog";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";

const initialMenu = {
  confirm: false,
  referrer: null,
  remuneration: false,
};
const ProcessPayout = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isMenu, setIsMenu] = React.useState(initialMenu);

  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "20";
  const keyword = searchParams.get("keyword") || "";
  const status = searchParams.get("status") || "";

  const qKeys = [page, limit, status, id];
  const query = { page, limit, keyword, status };

  const payoutQuery = useQuery({
    retry: false,
    queryKey: ["payout", id],
    queryFn: () => referralsService.getPayout(id),
  });

  const remunerationsQuery = useQuery({
    retry: false,
    queryKey: ["remunerations", ...qKeys],
    queryFn: () => referralsService.getPayoutRemunerations(query, id),
  });

  const mutation = useMutation({
    mutationFn: (data) => referralsService.submitPayout(data, id),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      toast.success("Payout processed successfully...");
      navigate(`${pathnames.PAYOUTS}/${id}`, { replace: true });
    },
  });

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      remunerationsQuery.refetch();
    }, 1000);
    return () => clearTimeout(timeoutId);
    //eslint-disable-next-line
  }, [keyword]);

  const payout = payoutQuery?.data?.data?.data;
  const remData = remunerationsQuery?.data?.data;
  return (
    <AppLayout>
      <Header
        title="Remunerations"
        description="Get insights to Referrers that have been paid from this page"
      />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <IconButton
          className="bg-secondary-subtle mb-3"
          onClick={() => navigate(-1)}
        >
          <ArrowBack />
        </IconButton>
        <div className="row mb-3">
          <div className="col-12 col-md-3 col-lg-4 mb-3">
            <div>
              <AppTextField
                placeholder="Enter Text Here..."
                value={keyword}
                onChange={(e) => handleChange(e, "keyword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search className="text-secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="col-12 col-md-9 col-lg-8 mb-3">
            <div className="d-flex flex-wrap gap-4 justify-content-md-end">
              <Button
                variant="contained"
                color="secondary"
                disabled={["CANCELED", "PAID"].includes(payout?.status)}
                onClick={() => handleMenu("confirm", true)}
              >
                Process Payout
              </Button>
            </div>
          </div>
        </div>
        <PreloaderTable isLoading={remunerationsQuery.isLoading} />
        <DataTable
          handleMenu={handleMenu}
          isLoading={remunerationsQuery.isLoading}
          data={remData?.data}
        />
        <div className="my-3">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div>
              <AppSelectField
                value={limit}
                placeholder="Limit"
                onChange={(e) => handleChange(e, "limit")}
                items={[
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                ]}
              />
            </div>
            <div>
              <Pagination
                page={Number(page)}
                count={remData?.totalPages}
                onChange={(_, value) => {
                  handleChange(value, "page");
                }}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        type="confirm"
        isOpen={isMenu.confirm}
        title="Process Payout"
        actionTitle="Yes, process"
        onSubmit={processPayout}
        isLoading={mutation.isPending}
        onClose={() => handleMenu("confirm", false)}
        body={`Are you sure you want to process this payout? Select "<span class="fw-medium">Yes, process</span>" to continue.`}
      />
      <UpdateRemuneration
        id={id}
        referrer={isMenu.referrer}
        isOpen={isMenu.remuneration}
        refetch={() => remunerationsQuery.refetch()}
        onClose={() => handleMenu("remuneration", false)}
      />
    </AppLayout>
  );

  function processPayout() {
    if (["CANCELED", "PAID"].includes(payout?.status)) {
      return;
    }
    mutation.mutate({});
  }

  function handleChange(e, name = "") {
    const value = name.includes("date")
      ? moment(e).format("YYYY-MM-DD")
      : name === "page"
      ? e
      : e.target.value;
    searchParams.set(name, value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
      { scrollY: 0 }
    );
    if (name === "page") scrollHandler();
  }

  function handleMenu(name, value) {
    setIsMenu((prev) => ({ ...prev, [name]: value }));
  }
};

const DataTable = ({ data, isLoading, handleMenu }) => {
  if (isLoading) return null;
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className="bg-light">
            <TableRow>
              <TableCell>Referrer Name</TableCell>
              <TableCell>Amount Payable</TableCell>
              <TableCell>Payout Amount</TableCell>
              <TableCell>Processing Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <TableRow
                key={item?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Link
                    to={`${pathnames.REFERRERS}/${item?.referrerId}`}
                    className="fw-medium"
                  >
                    {item?.referrerMeta?.firstName}{" "}
                    {item?.referrerMeta?.lastName}
                  </Link>
                </TableCell>
                <TableCell>{moneyFormat(item?.amountPayable, "NGN")}</TableCell>
                <TableCell>{moneyFormat(item?.amount, "NGN")}</TableCell>
                <TableCell>
                  {moment(item?.paidAt).format("Do MMM, YYYY")}
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      handleMenu("referrer", item);
                      handleMenu("remuneration", true);
                    }}
                    variant="text"
                    color="secondary"
                  >
                    Edit Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyState isEmpty={!isLoading && !data?.length} />
    </Paper>
  );
};

export default ProcessPayout;
