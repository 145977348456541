import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import { useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { moneyFormat } from "../../utils/moneyFormat";
import PersonsIconCheck from "../../lib/assets/icons/referral__icon-4.svg";
import MoneyIcon from "../../lib/assets/icons/referral__icon-2.svg";
import moment from "moment";
import scrollHandler from "../../utils/scrollHandler";
import PreloaderTable from "../../lib/components/PreloaderTable";
import AppSelectField from "../../lib/components/AppSelectField";
import referralsService from "../../service/referralsService";
import { useQuery } from "@tanstack/react-query";
import EmptyState from "../../lib/components/EmptyState";

const ReferralOrganizationDetails = () => {
  const { id } = useParams();
  const currency = "NGN";
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const referrerId = searchParams.get("referrerId");
  const limit = searchParams.get("limit") || "20";
  const status = searchParams.get("status") || "";
  const keyword = searchParams.get("keyword") || "";

  const qKeys = [page, limit, status, id, referrerId];
  const queries = {
    page,
    limit,
    keyword,
    status,
    referralId: id,
    referrerId,
  };

  const referralQuery = useQuery({
    retry: false,
    queryKey: ["referral", id],
    queryFn: () => referralsService.getReferral({ id }),
  });

  const earningsQuery = useQuery({
    retry: false,
    queryKey: ["earnings", ...qKeys],
    queryFn: () => referralsService.getEarnings({ ...queries }),
  });

  const referral = referralQuery?.data?.data?.data;
  const commissionData = earningsQuery?.data?.data;

  return (
    <AppLayout>
      <Header
        title="Referrals"
        description="Get insights to users on Jureb's referral program here"
      />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <IconButton
          className="bg-secondary-subtle mb-3"
          onClick={() => navigate(-1)}
        >
          <ArrowBack />
        </IconButton>
        <div className="mb-5">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="border p-3 py-2 rounded h-100 bg-white">
                <div className="fs-5 fw-medium mb-2">
                  {referral?.organizationMeta?.name}
                </div>
                <div className="fs-sm mb-2">
                  Phone Number:{" "}
                  <span className="fw-medium">
                    {referral?.organizationMeta?.phone}
                  </span>
                </div>
                <div className="fs-sm mb-2 text-truncate">
                  Email:{" "}
                  <span className="fw-medium text-decoration-underline text-primary">
                    {referral?.organizationMeta?.email}
                  </span>
                </div>
                <div className="fs-sm mb-2">
                  Join Date:{" "}
                  <span className="fw-medium">
                    {moment(referral?.organizationMeta?.createdAt).format(
                      "Do MMM, YYYY"
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="h-100">
                <div className="border p-3 py-2 rounded mb-3 bg-white">
                  <StatsCard
                    title={"Total Times Subscribed"}
                    Icon={PersonsIconCheck}
                    amount={referral?.count?.subscription}
                  />
                </div>
                <div className="border p-3 rounded bg-white">
                  <StatsCard
                    title={"Total Commission Earned"}
                    Icon={MoneyIcon}
                    amount={moneyFormat(referral?.earning?.total, currency)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fw-medium fs-5 text-secondary mb-3">
          Recent subscriptions
        </div>
        <PreloaderTable isLoading={false} />
        <TableContainer component={Paper} className="border">
          <Table>
            <TableHead className="bg-light">
              <TableRow>
                <TableCell>Plan</TableCell>
                <TableCell>Period</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Commission Earned</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commissionData?.data?.map((item) => (
                <TableRow key={item?.id}>
                  <TableCell>
                    {item?.organizationMeta?.subscriptionPlanName}
                  </TableCell>
                  <TableCell>
                    {item?.organizationMeta?.subscriptionBillingInterval}
                  </TableCell>
                  <TableCell>
                    {moment(item?.organizationMeta?.subscriptionStartAt).format(
                      "Do MMM, YYYY"
                    )}
                  </TableCell>
                  <TableCell>
                    {moneyFormat(item?.organizationMeta?.amount, "NGN")}
                  </TableCell>
                  <TableCell>
                    {typeof item?.commission?.amount === "number"
                      ? moneyFormat(item?.commission?.amount, "NGN")
                      : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <EmptyState
            isEmpty={!earningsQuery.isLoading && !commissionData?.data?.length}
          />
        </TableContainer>
        <div className="my-3">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div>
              <AppSelectField
                value={limit}
                placeholder="Limit"
                onChange={(e) => handleChange(e, "limit")}
                items={[
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                ]}
              />
            </div>
            <div>
              <Pagination
                page={Number(page)}
                count={commissionData?.totalPages}
                onChange={(_, value) => {
                  handleChange(value, "page");
                }}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );

  function handleChange(e, name = "") {
    const value = name.includes("date")
      ? moment(e).format("YYYY-MM-DD")
      : name === "page"
      ? e
      : e.target.value;
    searchParams.set(name, value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
    if (name === "page") scrollHandler();
  }
};

function StatsCard({ title, amount, Icon }) {
  return (
    <div className="d-flex gap-3 align-items-center">
      <div className="flex-grow-1 d-flex flex-column">
        <div className="mb-2 flex-grow-1 fs-sm">{title}</div>
        <div className="fw-medium">{amount}</div>
      </div>
      <div>
        <img src={Icon} alt={title} width={40} height={40} />
      </div>
    </div>
  );
}

export default ReferralOrganizationDetails;
