import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import {
  Button,
  Chip,
  InputAdornment,
  Pagination,
  Tab,
  Tabs,
} from "@mui/material";
import PreloaderTable from "../../lib/components/PreloaderTable";
import moment from "moment";
import AppDateField from "../../lib/components/AppDateField";
import AppTextField from "../../lib/components/AppTextField";
import { Search } from "@mui/icons-material";
import pushNotificationService from "../../service/pushNotificationService";
import NotificationsDataTable from "./components/notifications-data-table";
import AppSelectField from "../../lib/components/AppSelectField";
import ConfirmationDialog from "../../lib/components/ConfirmationDialog";
import errorHandler from "../../utils/errorHandler";
import CreateNotifications from "./components/create-notification";
import scrollHandler from "../../utils/scrollHandler";

const tabList = [
  { label: "All", value: "ALL" },
  { label: "Sent", value: "SENT" },
  { label: "Scheduled", value: "SCHEDULED" },
];

const PushNotifications = () => {
  const navigate = useNavigate();
  const [isCreate, setIsCreate] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [selectedNotification, setSelectedNotification] = React.useState(null);

  const searchParams = new URLSearchParams(window.location.search);
  const page = searchParams.get("page") || "1";
  const limit = searchParams.get("limit") || "20";
  const keyword = searchParams.get("keyword") || "";
  const dateFrom = searchParams.get("dateFrom") || null;
  const dateTo = searchParams.get("dateTo") || null;
  const status = searchParams.get("status") || "";

  const qKeys = [page, limit, dateFrom, dateTo, status];
  const queries = { page, limit, keyword, dateFrom, dateTo, status };

  const notificationsQuery = useQuery({
    retry: false,
    queryKey: ["push-notifications", ...qKeys],
    queryFn: () => pushNotificationService.get({ ...queries }),
  });

  const statsQuery = useQuery({
    retry: false,
    queryKey: ["notifications-stats"],
    queryFn: pushNotificationService.getStats,
  });

  const mutation = useMutation({
    mutationFn: (data) => pushNotificationService.delete(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      notificationsQuery.refetch();
      statsQuery.refetch();
      setIsDelete(false);
    },
  });

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      notificationsQuery.refetch();
    }, 1000);
    return () => clearTimeout(timeoutId);
    //eslint-disable-next-line
  }, [keyword]);

  const { isPending: isLoading, data } = notificationsQuery;
  const { data: stats } = statsQuery;

  const notificationsData = data?.data;
  const statsData = stats?.data?.data;

  return (
    <AppLayout>
      <Header
        title="Push Notification"
        description="Send targeted messages to your audience"
      />
      <div className="p-3 bg-light" style={{ minHeight: "100%" }}>
        <div className="row mb-5">
          <div className="col-6 col-md-3 col-lg-4 mb-3">
            <div>
              <AppTextField
                placeholder="Enter Text Here..."
                value={keyword}
                onChange={(e) => handleChange(e, "keyword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search className="text-secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppDateField
                label="Date From"
                value={dateFrom}
                onChange={(e) => handleChange(e, "dateFrom")}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-3">
            <div>
              <AppDateField
                label="Date To"
                value={dateTo}
                minDate={dateFrom}
                onChange={(e) => handleChange(e, "dateTo")}
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-4 mb-3">
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => setIsCreate(true)}
                variant="contained"
                color="secondary"
              >
                New Message
              </Button>
            </div>
          </div>
        </div>
        <div className="border-bottom mb-5">
          <Tabs value={status || "ALL"} onChange={handleChangeTab}>
            {tabList?.map((item, index) => {
              let value = 0;
              if (statsData) {
                value = statsData[item?.value.toLowerCase()];
              }
              return (
                <Tab
                  key={index}
                  value={item?.value}
                  label={<TabLabel title={item?.label} label={value} />}
                />
              );
            })}
          </Tabs>
        </div>
        <PreloaderTable isLoading={isLoading} />
        <NotificationsDataTable
          isLoading={isLoading}
          data={notificationsData?.data}
          onDeleteNotification={onDeleteNotification}
        />
        <div className="my-3">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div>
              <AppSelectField
                value={limit}
                placeholder="Limit"
                onChange={(e) => handleChange(e, "limit")}
                items={[
                  { label: "20", value: "20" },
                  { label: "30", value: "30" },
                  { label: "40", value: "40" },
                  { label: "50", value: "50" },
                ]}
              />
            </div>
            <div>
              <Pagination
                page={Number(page)}
                count={notificationsData?.totalPages}
                onChange={(_, value) => {
                  handleChange(value, "page");
                }}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isDelete}
        title="Delete Notification"
        isLoading={mutation.isPending}
        onClose={() => setIsDelete(false)}
        onSubmit={handleDeleteNotification}
        body={`You have selected to delete this notification <span class="fw-medium">"${selectedNotification?.title}</span>". Select "<span class="fw-medium">Yes, delete</span>" to continue.`}
        actionTitle="Yes, delete"
      />
      <CreateNotifications
        isOpen={isCreate}
        onClose={() => setIsCreate(false)}
        refetch={notificationsQuery.refetch}
      />
    </AppLayout>
  );

  function handleChangeTab(_, value) {
    searchParams.set("status", value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }

  function handleChange(e, name = "") {
    const value = name.includes("date")
      ? moment(e).format("YYYY-MM-DD")
      : name === "page"
      ? e
      : e.target.value;
    searchParams.set(name, value);
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
      { scrollY: 0 }
    );
    if (name === "page") scrollHandler();
  }

  function onDeleteNotification(data) {
    setIsDelete(true);
    setSelectedNotification(data);
  }

  function handleDeleteNotification() {
    mutation.mutate(selectedNotification?.id);
  }
};

function TabLabel({ title, label }) {
  return (
    <div className="d-flex gap-3 align-items-center">
      <span>{title}</span>
      <Chip label={label} />
    </div>
  );
}

export default PushNotifications;
