import React from "react";
import notificationService from "../service/notificationService";
import { getToken } from "../utils/saveToken";
import userservice from "../service/userservice";

const accessToken = getToken();
const pathname = window.location.pathname;

const AppContext = React.createContext({});

const AppContextProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [refetch, setRefetch] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);

  React.useEffect(() => {
    getNotification(setNotifications);
  }, []);
  React.useEffect(() => {
    getUser(setUser);
  }, [refetch]);

  return (
    <AppContext.Provider
      value={{
        notifications,
        setNotifications,
        user,
        setUser,
        setRefetch,
        refetch: () => setRefetch((prev) => !prev),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

async function getNotification(setNotifications) {
  try {
    if (!pathname || !accessToken) return;
    if (pathname.includes("/auth")) return;
    const { data } = await notificationService.get();
    setNotifications(data?.data);
  } catch (error) {}
}

async function getUser(setUser) {
  try {
    if (!accessToken) return;
    const { data } = await userservice.get();
    setUser(data?.data);
  } catch (error) {}
}

export { AppContext, AppContextProvider };
