import React from "react";
import { getToken } from "../../utils/saveToken";
import { useNavigate } from "react-router-dom";
import pathnames from "../../constants/pathnames";

const Home = () => {
  const navigate = useNavigate();
  const accessToken = getToken();

  React.useEffect(() => {
    if (accessToken) navigate(pathnames.DASHBOARD, { replace: true });
    else navigate(pathnames.SIGN_IN, { replace: true });
  }, [accessToken, navigate]);

  return null;
};

export default Home;
