import httpservice from "./httpservice";

class FileDownload {
  async download(path, fileName, callback) {
    httpservice
      .axios({
        url: path,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          if (callback) {
            callback((prev) => ({ ...prev, progressEvent, downloading: true }));
          }
        },
      })
      .then((res) => {
        const href = URL.createObjectURL(res?.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(path);
      });
  }
}

export default new FileDownload();
