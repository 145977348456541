import { Divider, IconButton, Popover } from "@mui/material";
import React from "react";
import { AppContext } from "../../context/app-context";
import { ArrowForwardIosSharp, Close } from "@mui/icons-material";
import moment from "moment";
import { Link } from "react-router-dom";
import pathnames from "../../constants/pathnames";

const Notifications = ({ anchorEl, onClose }) => {
  const { notifications } = React.useContext(AppContext);
  return (
    <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClose}>
      <div className="bg-light" style={{ minWidth: 300 }}>
        <div className="d-flex p-3 pe-2 justify-content-between align-items-center gap-3">
          <div className="fw-medium fs-5">Notifications</div>
          <div>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        </div>
        <Divider />
        <div className="d-flex flex-column justify-content-between">
          <div
            className="p-3"
            style={{ height: 350, maxWidth: 400, overflowY: "auto" }}
          >
            {notifications?.map((item) => (
              <div
                key={item?.id}
                className="d-flex rounded bg-white p-3 border gap-5 mb-3 justify-content-between"
              >
                <div className="fs-sm">
                  <span className="fw-medium">{item?.replacers[0]?.name}</span>
                  <span className="">
                    {item?.text
                      ?.replace("$1", "")
                      .replace("$2", item?.replacers[1]?.name)
                      .replace("$3", item?.replacers[2]?.name)
                      .replace("$4", item?.replacers[3]?.name)}
                  </span>
                </div>
                <div className="fs-xs fst-italic text-end text-nowrap">
                  {moment(item?.createdAt).fromNow()}
                </div>
              </div>
            ))}
          </div>
          <div>
            <Divider />
            <Link to={pathnames.ADMIN_LOGS} className="d-block p-3 text-end">
              See all <ArrowForwardIosSharp className="fs-6" />
            </Link>
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default Notifications;
