import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./index.css";
import "./index.scss";
import { AppContextProvider } from "./context/app-context";
import App from "./App";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <App />
        <ToastContainer />
      </AppContextProvider>
    </QueryClientProvider>
  </React.Fragment>
);
reportWebVitals();
