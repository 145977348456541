import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { CREATE_ADMIN_VALIDATION } from "../../../validations/admins";
import administratorsService from "../../../service/administratorsService";
import errorHandler from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import BTNLoader from "../../../lib/components/BTNLoader";

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
};
const CreateUser = ({ isOpen, onClose, refetch }) => {
  const formHook = useForm({
    resolver: yupResolver(CREATE_ADMIN_VALIDATION),
    mode: "all",
    defaultValues: defaultValues,
  });

  const errors = formHook.formState.errors;
  const control = formHook.control;
  const handleSubmit = formHook.handleSubmit;
  const reset = formHook.reset;

  const mutation = useMutation({
    mutationFn: (data) => administratorsService.create(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      onCreateSuccess(data);
    },
  });

  async function save(data) {
    mutation.mutate(data);
  }
  const { isPending } = mutation;
  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        maxWidth="sm"
        PaperProps={{
          className: "w-100",
          component: "form",
          onSubmit: handleSubmit(save),
        }}
      >
        <div className="d-flex justify-content-between align-items-center gap-4 p-3 py-2 pe-2">
          <div className="fs-5 fw-medium">New User</div>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <Divider className="bg-secondary-subtle" />
        <DialogContent className="h-100 overflow-auto">
          <div className="mb-4">
            <label className="d-block">
              First Name
              <span className="text-danger">*</span>
            </label>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  placeholder="Enter First Name"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  helperText={errors["firstName"]?.message}
                  error={!!errors["firstName"]}
                />
              )}
            />
          </div>
          <div className="mb-4">
            <label className="d-block">
              Last Name
              <span className="text-danger">*</span>
            </label>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  placeholder="Enter Last Name"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  helperText={errors["lastName"]?.message}
                  error={!!errors["lastName"]}
                />
              )}
            />
          </div>
          <div className="mb-4">
            <label className="d-block">
              Email
              <span className="text-danger">*</span>
            </label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  type="email"
                  size="small"
                  variant="outlined"
                  placeholder="Enter email address"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  helperText={errors["email"]?.message}
                  error={!!errors["email"]}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isPending}
            startIcon={<BTNLoader isLoading={isPending} />}
            variant="contained"
            color={"secondary"}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  function onCreateSuccess(data) {
    toast.success(`Administrator created successfully.`);
    refetch();
    reset(defaultValues);
    onClose();
  }
};

export default CreateUser;
