import { Divider, Paper } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import pathnames from "../../../constants/pathnames";
import { ArrowForwardIos } from "@mui/icons-material";
import moment from "moment";

const UserLogs = ({ data }) => {
  return (
    <Paper className="h-100 d-flex flex-column">
      <div className="d-flex p-3 gap-4 justify-content-between align-items-end">
        <div>
          <div className="fs-5 fw-medium">User Logs</div>
        </div>
      </div>
      <Divider />
      <div className="d-flex py-3 flex-column justify-content-between">
        <div className="h-100">
          {data?.map((item, index) => {
            return (
              <div
                key={index}
                style={{ background: index % 2 === 0 ? "#f1f1f1" : "" }}
                className="p-3 d-flex gap-4 justify-content-between"
              >
                <div
                  className="fs-sm text-truncate"
                  title={`${item?.replacers[0]?.name} ${item?.text
                    ?.replace("$1", "")
                    .replace("$2", item?.replacers[1]?.name)
                    .replace("$3", item?.replacers[2]?.name)
                    .replace("$4", item?.replacers[3]?.name)}`}
                >
                  <span className="fw-medium">{item?.replacers[0]?.name}</span>
                  {item?.text
                    ?.replace("$1", "")
                    .replace("$2", item?.replacers[1]?.name)
                    .replace("$3", item?.replacers[2]?.name)
                    .replace("$4", item?.replacers[3]?.name)}
                </div>
                <div className="fs-xs text-nowrap">
                  {moment(item?.createdAt).fromNow()}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-auto">
        <Divider />
        <div className="p-3 text-end">
          <Link to={pathnames.ORGANIZATION_LOGS}>
            See all <ArrowForwardIos className="fs-6" />
          </Link>
        </div>
      </div>
    </Paper>
  );
};

export default UserLogs;
