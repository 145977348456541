import React from "react";
import Cookies from "js-cookie";
import pathnames from "../../constants/pathnames";

const SignOut = () => {
  React.useEffect(() => {
    Cookies.remove("administrator_access_auth");
    window.location.replace(pathnames.SIGN_IN);
  }, []);
  return null;
};

export default SignOut;
