import React from "react";

function UsersIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle
        cx="3.404"
        cy="3.404"
        r="3.404"
        stroke="currentColor"
        strokeWidth="1.5"
        transform="matrix(-1 0 0 1 15.617 5)"
        {...props}
      ></circle>
      <path
        stroke="currentColor"
        strokeWidth="1.5"
        d="M6.255 16.86c0-.733.46-1.386 1.15-1.632v0a14.294 14.294 0 019.615 0v0c.69.246 1.15.9 1.15 1.631v1.12a1.66 1.66 0 01-1.895 1.644l-.334-.048a26.363 26.363 0 00-7.457 0l-.333.048a1.66 1.66 0 01-1.896-1.644v-1.12z"
        {...props}
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M17.32 11.903a2.675 2.675 0 000-5.35M20.249 18.005l.262.038A1.305 1.305 0 0022 16.75v-.88c0-.575-.362-1.088-.904-1.282a11.23 11.23 0 00-1.65-.45M6.68 11.903a2.675 2.675 0 010-5.35M3.751 18.005l-.262.038A1.305 1.305 0 012 16.75v-.88c0-.575.362-1.088.904-1.282.54-.193 1.091-.343 1.65-.45"
        {...props}
      ></path>
    </svg>
  );
}

export default UsersIcon;
