function scrollHandler(id = "section-right") {
  try {
    const element = document.getElementById(id);
    element.scroll(0, 0);
  } catch (error) {
    console.error(error);
  }
}

export default scrollHandler;
