import React from "react";
import { Button } from "@mui/material";
import { Close, KeyboardArrowUp } from "@mui/icons-material";
import MobileScreenImg from "../../../lib/assets/images/Android.png";
import JurebIcon from "../../../lib/assets/images/jureb-icon.png";

export default function PreviewNotification({
  isPreview,
  data,
  setIsMobileView,
  isMobileView,
}) {
  if (isPreview)
    return (
      <div>
        <MobileView isOpen={isMobileView} data={data} />
        <DesktopView isOpen={!isMobileView} data={data} />
        <div className="d-flex my-4 gap-3 justify-content-center align-items-center">
          <Button
            type="button"
            variant="text"
            onClick={() => setIsMobileView(false)}
          >
            Web Browser
          </Button>
          <Button
            type="button"
            variant="text"
            onClick={() => setIsMobileView(true)}
          >
            Mobile Application
          </Button>
        </div>
      </div>
    );
  return null;
}

export function DesktopView({ data, isOpen }) {
  if (isOpen)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div
          className="bg-white w-100 mx-3 rounded-4 p-2 px-3 shadow"
          style={{ maxWidth: 320 }}
        >
          <div className="d-flex mb-1 justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <span className="fw-medium fs-sm">Jureb</span>
            </div>
            <Close className="text-secondary fs-sm" />
          </div>
          <div className="d-flex gap-3 mb-1 align-items-center">
            {!data?.image ? null : (
              <div className="overflow-hidden rounded-2">
                <img
                  style={{ width: 50, height: 50, objectFit: "cover" }}
                  src={data?.image}
                  alt={data?.title}
                />
              </div>
            )}
            <div>
              <div
                style={{ maxWidth: 200 }}
                className="fw-medium text-one-line fw-medium fs-xs"
              >
                {data?.title}
              </div>
              <div
                className="text-one-line fs-xs"
                style={{ maxWidth: 200 }}
                dangerouslySetInnerHTML={{ __html: data?.message }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  return null;
}

export function MobileView({ data, isOpen }) {
  if (isOpen)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div
          className="rounded overflow-hidden"
          style={{
            maxWidth: 320,
            height: 480,
            backgroundImage: `url(${MobileScreenImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="d-flex h-100 justify-content-center align-items-center">
            <div className="bg-white w-100 mx-3 rounded-4 p-2 px-3 shadow">
              <div className="d-flex mb-1 justify-content-between align-items-center">
                <div className="fs-xs d-flex align-items-center">
                  <img
                    style={{ width: 14, height: 14, objectFit: "contain" }}
                    src={JurebIcon}
                    alt={"Jureb"}
                  />{" "}
                  <span className="ms-1">Jureb • now</span>
                </div>
                <KeyboardArrowUp className="text-secondary fs-sm" />
              </div>
              <div className="d-flex gap-4 mb-1 justify-content-between align-items-center">
                <div>
                  <div
                    className="fw-medium text-one-line fw-medium fs-xs"
                    style={{ maxWidth: 200 }}
                  >
                    {data?.title}
                  </div>
                  <div
                    className="text-one-line fs-xs"
                    style={{ maxWidth: 200 }}
                    dangerouslySetInnerHTML={{ __html: data?.message }}
                  ></div>
                </div>
                {!data?.image ? null : (
                  <div className="overflow-hidden rounded-2">
                    <img
                      style={{ width: 50, height: 50, objectFit: "cover" }}
                      src={data?.image}
                      alt={data?.title}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  return null;
}
