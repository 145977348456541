import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, Divider, IconButton, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { toast } from "react-toastify";
import referralsService from "../../../service/referralsService";
import errorHandler from "../../../utils/errorHandler";
import BTNLoader from "../../../lib/components/BTNLoader";

const initialValues = {
  firstName: "",
  lastName: "",
  code: "",
};
const CreateReferrer = ({ onClose, isOpen, refetch }) => {
  const [values, setValues] = React.useState(initialValues);
  const mutation = useMutation({
    mutationFn: (data) => referralsService.create(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: () => {
      toast.success("Referrer created successfully.");
      onClose();
      refetch();
    },
  });
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      maxWidth="sm"
      PaperProps={{ component: "form", onSubmit: handleSubmit, className: "w-100", style: { maxWidth: 500 } }}
    >
      <div className="d-flex justify-content-between gap-4 p-3 py-2 pe-2">
        <div>
          <div className="fs-5 fw-medium">Create Referrer</div>
          <div className="fs-sm text-secondary">Fill the fields below to add a referrer</div>
        </div>
        <div>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </div>
      <Divider className="bg-secondary-subtle" />
      <DialogContent>
        <div className="row mb-4 align-items-center">
          <div className="col-12 col-md-4">
            <label className="d-block text-md-end">
              First Name: <span className="text-danger">*</span>
            </label>
          </div>
          <div className="col-12 col-md-8">
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              type="text"
              required
              color="secondary"
              value={values?.firstName}
              onChange={(e) => handleChange("firstName", e.target.value)}
            />
          </div>
        </div>
        <div className="row mb-4 align-items-center">
          <div className="col-12 col-md-4">
            <label className="d-block text-md-end">
              Last Name: <span className="text-danger">*</span>
            </label>
          </div>
          <div className="col-12 col-md-8">
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              type="text"
              required
              color="secondary"
              value={values?.lastName}
              onChange={(e) => handleChange("lastName", e.target.value)}
            />
          </div>
        </div>
        <div className="row mb-4 align-items-center">
          <div className="col-12 col-md-4">
            <label className="d-block text-md-end">
              Code: <span className="text-danger">*</span>
            </label>
          </div>
          <div className="col-12 col-md-8">
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              type="text"
              required
              color="secondary"
              value={values?.code}
              onChange={(e) => handleChange("code", e.target.value)}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} type="button">
          Cancel
        </Button>
        <Button
          disabled={mutation.isPending}
          startIcon={<BTNLoader isLoading={mutation.isPending} />}
          variant="contained"
          color="secondary"
          type="submit"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  function handleSubmit(e) {
    e.preventDefault();
    mutation.mutate(values);
  }

  function handleChange(name, value) {
    setValues((prev) => ({ ...prev, [name]: value }));
  }
};

export default CreateReferrer;
