import React from "react";
import Layout from "./components/Layout";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import taxservice from "../../service/taxservice";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import errorHandler from "../../utils/errorHandler";
import { Add, ErrorOutline } from "@mui/icons-material";
import AppSelectField from "../../lib/components/AppSelectField";
import countries from "../../lib/components/Helper/countries";
import TaxDataTable from "./components/tax-data-table";
import PreloaderTable from "../../lib/components/PreloaderTable";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import BTNLoader from "../../lib/components/BTNLoader";

const TaxRates = () => {
  const navigate = useNavigate();
  const [taxRates, setTaxRates] = React.useState([]);
  const searchParams = new URLSearchParams(window.location.search);
  const countryCode = searchParams.get("country-code") || "234";

  const taxQuery = useQuery({
    retry: false,
    queryKey: ["tax-rates", countryCode],
    queryFn: () => taxservice.get(countryCode),
  });

  const mutation = useMutation({
    mutationFn: (data) => taxservice.update(data),
    onError: ({ response }) => errorHandler(response),
    onSuccess: ({ data }) => {
      taxQuery.refetch();
      toast.success("Tax-Rates saved successfully.");
    },
  });

  const { data, isFetching: isLoading } = taxQuery;
  const taxData = data?.data?.data;

  React.useEffect(() => {
    setTaxRates(taxData?.taxes || []);
  }, [taxData]);

  return (
    <AppLayout>
      <Header
        title="Settings"
        description="Customize your account preferences and general settings here."
      />
      <Layout>
        <div className="p-4">
          <div className="d-flex mb-4 gap-3 align-items-center">
            <div className="flex-grow-1 text-primary fs-5 fw-medium">
              <ErrorOutline /> Select the taxes required
            </div>
            <div style={{ maxWidth: 200 }}>
              <AppSelectField
                placeholder={"Country"}
                value={countryCode}
                onChange={(e) => handleChange(e, "country-code")}
                items={countries.map((item) => ({
                  label: item?.name,
                  value: item?.code,
                }))}
              />
            </div>
          </div>
          <PreloaderTable isLoading={isLoading} />
          <form action="" onSubmit={save}>
            <TaxDataTable
              data={taxRates}
              isLoading={isLoading}
              setTaxRates={setTaxRates}
              handleDeleteTax={handleDeleteTax}
              handleChangeTaxDate={handleChangeTaxDate}
              handleChangeTaxName={handleChangeTaxName}
              handleChangeTaxRate={handleChangeTaxRate}
              handleChangeTaxAction={handleChangeTaxAction}
            />
            <div className="mb-3">
              <Button
                type="button"
                onClick={handleAddTax}
                color="secondary"
                startIcon={<Add />}
              >
                Add New Tax
              </Button>
            </div>
            <div className={`text-end`}>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={mutation.isPending}
                startIcon={<BTNLoader isLoading={mutation.isPending} />}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </Layout>
    </AppLayout>
  );

  function save(e) {
    e.preventDefault();
    mutation.mutate({ countryCode, taxRates });
  }

  function handleAddTax() {
    const data = {
      id: new Date().getTime(),
      name: "",
      action: false,
      date: null,
      rate: "",
    };
    setTaxRates((prev) => [...prev, data]);
  }
  function handleDeleteTax(id) {
    const filtered = taxRates?.filter((item) => item?.id !== id);
    setTaxRates(filtered);
  }

  function handleChangeTaxDate(id, e) {
    const rates = taxRates?.map((item) => {
      if (item?.id === id) {
        return { ...item, date: e };
      }
      return item;
    });
    setTaxRates(rates);
  }

  function handleChangeTaxName(id, e) {
    const rates = taxRates?.map((item) => {
      if (item?.id === id) {
        return { ...item, name: e.target.value };
      }
      return item;
    });
    setTaxRates(rates);
  }
  function handleChangeTaxRate(id, e) {
    const rates = taxRates?.map((item) => {
      if (item?.id === id) {
        return { ...item, rate: e.target.value };
      }
      return item;
    });
    setTaxRates(rates);
  }
  function handleChangeTaxAction(id, e) {
    const rates = taxRates?.map((item) => {
      if (item?.id === id) {
        return { ...item, action: e.target.checked };
      }
      return item;
    });
    setTaxRates(rates);
  }

  function handleChange(e, name = "") {
    const value = e.target.value;
    searchParams.set(name, value);
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  }
};

export default TaxRates;
