import React from "react";

const NotFound = () => {
  React.useEffect(() => {
    window.document.title = "404 - Page Not Found";
  }, []);
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh" }} className="d-flex align-items-center">
        <div className="p-5 w-100">
          <h1 className="display-3 text-center mb-2 fw-bold">404</h1>
          <div className="display-6 text-center mb-2">Page Not Found</div>
          <p className="text-center fs-5">
            The requested page could not be found.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
