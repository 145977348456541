import pathnames from "./constants/pathnames";
import ForgotPassword from "./modules/authentication/forgot-password";
import ForgotPasswordSuccess from "./modules/authentication/forgot-password.success";
import ResetPassword from "./modules/authentication/reset-password";
import SignIn from "./modules/authentication/signin";
import Dashboard from "./modules/dashboard";
import Home from "./modules/home";
import Organizations from "./modules/organizations";
import OrganizationDetails from "./modules/organizations/organization-details";
import SingleInvoice from "./modules/organizations/invoices-single";
import Users from "./modules/users";
import SingleUser from "./modules/users/users-single";
import OrganizationAdministrators from "./modules/organizations/administrators";
import Administrators from "./modules/admin";
import Payments from "./modules/payments";
import PushNotifications from "./modules/push-notifications";
import UserProfile from "./modules/settings/user-profile";
import UserPassword from "./modules/settings/user-password";
import TaxRates from "./modules/settings/tax-rates";
import CurrencyRates from "./modules/settings/currency-rates";
import SignOut from "./modules/authentication/signout";
import NotFound from "./modules/404";
import SingleAdministrator from "./modules/admin/users-single";
import AdminLogs from "./modules/admin/logs";
import OrganizationLogs from "./modules/organizations/logs";
import Referrers from "./modules/referrals/referrers";
import ReferrerDetails from "./modules/referrals/referrer-details";
import ReferralOrganizationDetails from "./modules/referrals/earnings-preview";
import Payouts from "./modules/payouts";
import ViewPayouts from "./modules/payouts/view-payout";
import ProcessPayout from "./modules/payouts/process-payout";
import ConfirmPayout from "./modules/payouts/confirm-payout";

const routes = [
  { title: "Home", private: false, path: pathnames.HOME, Component: Home },
  {
    title: "Sign In",
    private: false,
    path: pathnames.SIGN_IN,
    Component: SignIn,
  },
  {
    title: "Sign Out",
    private: false,
    path: pathnames.SIGN_OUT,
    Component: SignOut,
  },
  {
    title: "Forgot Password",
    private: false,
    path: pathnames.FORGOT_PASSWORD,
    Component: ForgotPassword,
  },
  {
    title: "Forgot Password Success",
    private: false,
    path: pathnames.FORGOT_PASSWORD_SUCCESS,
    Component: ForgotPasswordSuccess,
  },
  {
    title: "Reset Password",
    private: false,
    path: pathnames.RESET_PASSWORD,
    Component: ResetPassword,
  },
  {
    title: "Dashboard",
    private: true,
    path: pathnames.DASHBOARD,
    Component: Dashboard,
  },
  {
    title: "Organizations",
    private: true,
    path: pathnames.ORGANIZATIONS,
    Component: Organizations,
  },
  {
    title: "Organizations",
    private: true,
    path: `${pathnames.ORGANIZATIONS}/:id`,
    Component: OrganizationDetails,
  },
  {
    title: "Organizations Administrators",
    private: true,
    path: `${pathnames.ORGANIZATIONS_ADMINS}/:id`,
    Component: OrganizationAdministrators,
  },
  {
    title: "View Single Invoice",
    private: true,
    path: `${pathnames.ORGANIZATIONS_INVOICES}/:id`,
    Component: SingleInvoice,
  },
  {
    title: "Users",
    private: true,
    path: pathnames.USERS,
    Component: Users,
  },
  {
    title: "User Details",
    private: true,
    path: `${pathnames.USERS}/:id`,
    Component: SingleUser,
  },
  {
    title: "Referrals",
    private: true,
    path: pathnames.REFERRERS,
    Component: Referrers,
  },
  {
    title: "Referral Details",
    private: true,
    path: `${pathnames.REFERRERS}/:id`,
    Component: ReferrerDetails,
  },
  {
    title: "Referral Organizations",
    private: true,
    path: `${pathnames.REFERRALS_ORGANIZATIONS_PREVIEW}/:id`,
    Component: ReferralOrganizationDetails,
  },

  {
    title: "Administrators",
    private: true,
    path: pathnames.ADMINISTRATORS,
    Component: Administrators,
  },
  {
    title: "Administrators",
    private: true,
    path: `${pathnames.ADMINISTRATORS}/:id`,
    Component: SingleAdministrator,
  },
  {
    title: "Payments",
    private: true,
    path: pathnames.PAYMENTS,
    Component: Payments,
  },
  {
    title: "Payouts",
    private: true,
    path: pathnames.PAYOUTS,
    Component: Payouts,
  },
  {
    title: "Remuneration",
    private: true,
    path: `${pathnames.PAYOUTS}/:id`,
    Component: ViewPayouts,
  },
  {
    title: "Process Payouts",
    private: true,
    path: `${pathnames.PAYOUTS__PROCESS}/:id`,
    Component: ProcessPayout,
  },
  {
    title: "Confirm Payouts",
    private: true,
    path: `${pathnames.PAYOUTS__CONFIRM}/:id`,
    Component: ConfirmPayout,
  },
  {
    title: "Push Notifications",
    private: true,
    path: pathnames.PUSH_NOTIFICATIONS,
    Component: PushNotifications,
  },
  {
    title: "Admin Profile",
    private: true,
    path: pathnames.SETTINGS_PROFILE,
    Component: UserProfile,
  },
  {
    title: "Change Password",
    private: true,
    path: pathnames.SETTINGS_CHANGE_PASSWORD,
    Component: UserPassword,
  },
  {
    title: "Tax Rates",
    private: true,
    path: pathnames.SETTINGS_TAX_RATES,
    Component: TaxRates,
  },
  {
    title: "Currency Rates",
    private: true,
    path: pathnames.SETTINGS_CURRENCY_RATES,
    Component: CurrencyRates,
  },
  {
    title: "Admin Logs",
    private: true,
    path: pathnames.ADMIN_LOGS,
    Component: AdminLogs,
  },
  {
    title: "Organization Logs",
    private: true,
    path: pathnames.ORGANIZATION_LOGS,
    Component: OrganizationLogs,
  },
  {
    title: "404 - Not Found Page",
    private: false,
    path: "*",
    Component: NotFound,
  },
];

export default routes;
