import httpservice from "./httpservice";

class Tax {
  async create(data) {
    return await httpservice.post("/admin/cp/taxes/rates", data);
  }
  async get(data) {
    const params = { countryCode: data };
    return await httpservice.get("/admin/cp/taxes/rates/single", { params });
  }
  async update(data) {
    const formData = { countryCode: data?.countryCode };
    formData.taxes = data?.taxRates?.map((item) => ({
      name: item?.name,
      action: item?.action,
      date: item?.date,
      rate: Number(item?.rate),
    }));

    return await httpservice.put("/admin/cp/taxes/rates", formData);
  }
  async delete(id) {
    const params = { id };
    return await httpservice.delete("/admin/cp/taxrates/delete", { params });
  }
}

export default new Tax();
