import { Add, Close, Delete, FileCopyOutlined } from "@mui/icons-material";
import { Button, Dialog, DialogContent, Divider, IconButton } from "@mui/material";
import React from "react";
import BTNLoader from "../../../lib/components/BTNLoader";
import { useMutation } from "@tanstack/react-query";
import referralsService from "../../../service/referralsService";
import errorHandler from "../../../utils/errorHandler";
import { toast } from "react-toastify";

const ReferrerCodes = ({ referrer, isOpen, onClose, refetch, codes }) => {
  const [code, setCode] = React.useState("");
  const [isAddNew, setIsAddNew] = React.useState(false);
  const [isAdding, setIsAdding] = React.useState(false);

  const mutation = useMutation({
    mutationFn: (data) => referralsService.updateCode(data, referrer?.id),
    onError: ({ response }) => {
      errorHandler(response);
      setIsAdding(false);
    },
    onSuccess: ({ data }) => {
      refetch();
      setCode("");
      if (isAdding) toast.success("Referrer code added successfully.");
      else toast.success("Referrer code removed successfully.");
      setIsAdding(false);
    },
  });

  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth="sm" PaperProps={{ className: "w-100" }}>
      <div className="d-flex justify-content-between gap-4 p-3 py-2 pe-2">
        <div>
          <div className="fs-5 fw-medium">Referrer Codes</div>
        </div>
        <div>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </div>
      <Divider className="bg-secondary-subtle" />
      <DialogContent>
        {codes?.map((item, index) => {
          return (
            <CodeItem
              key={index}
              item={item}
              copyToClipboard={copyToClipboard}
              onRemove={() => handleRemoveCode(item)}
              isDisableDelete={codes?.length === 1}
            />
          );
        })}
        {!isAddNew ? null : (
          <div className="row mb-4">
            <div className="col-10">
              <form className="mb-4" onSubmit={save}>
                <div class="input-group mb-3">
                  <input
                    required
                    type="text"
                    autoFocus
                    className="form-control"
                    placeholder="Enter Referrer Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    aria-label="Referral code"
                  />
                  <Button type="submit" color="secondary" variant="contained" disabled={isAdding}>
                    {isAdding ? <BTNLoader isLoading /> : "Save"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
        <Button color="secondary" variant="text" endIcon={<Add />} onClick={() => setIsAddNew(true)}>
          Add New Code
        </Button>
      </DialogContent>
    </Dialog>
  );

  function handleRemoveCode(code) {
    if (codes?.length === 1) {
      toast.error("Referrer code cannot be deleted");
      return;
    }
    mutation.mutate({ codeRemove: code });
  }
  function save(e) {
    e.preventDefault();
    if (codes?.includes(code)) {
      toast.error("Referrer code already exist");
      return;
    }
    setIsAdding(true);
    mutation.mutate({ code: code });
  }

  function copyToClipboard(id) {
    try {
      const text = document.getElementById(id).innerHTML;
      navigator.clipboard.writeText(text);
      toast.success("Referrer code copied");
    } catch (error) {
      console.error("CLIPBOARD BODY:", error);
    }
  }
};
function CodeItem({ item, onRemove, copyToClipboard, isDisableDelete }) {
  return (
    <div className="row mb-4">
      <div className="col-10">
        <div class="input-group w-100">
          <div id={`share-code-${item}`} className="form-control" aria-label="Referral Code">
            {item}
          </div>
          <Button variant="contained" onClick={() => copyToClipboard(`share-code-${item}`)}>
            <FileCopyOutlined />
          </Button>
        </div>
      </div>
      <div className="col-2">
        <div>
          <IconButton onClick={onRemove} disabled={isDisableDelete}>
            <Delete className={isDisableDelete ? "text-secondary-subtle" : "text-danger"} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default ReferrerCodes;
