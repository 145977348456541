import React from "react";
import AppLayout from "../../lib/components/Layout/app-layout";
import Header from "../../lib/components/Header";
import { Button, IconButton, Paper, Skeleton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBack, Print } from "@mui/icons-material";
import Logo from "../../lib/assets/images/jureb-logo.png";
import { useQuery } from "@tanstack/react-query";
import organizationservice from "../../service/organizationservice";
import moment from "moment";
import { moneyFormat } from "../../utils/moneyFormat";
import ReactToPrint from "react-to-print";

const SingleInvoice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const printRef = React.useRef();

  const invoicesQuery = useQuery({
    retry: false,
    queryKey: ["single-invoice"],
    queryFn: () => organizationservice.getInvoice({ id }),
  });

  const { data: invoiceRes, isPending } = invoicesQuery;
  const invoiceData = invoiceRes?.data?.data;
  return (
    <AppLayout>
      <Header
        title="Invoice"
        description="Get insights to accounts on Jureb here"
      />
      <div className="p-3" style={{ minHeight: "100%" }}>
        <IconButton
          className="bg-secondary-subtle mb-3"
          onClick={() => navigate(-1)}
        >
          <ArrowBack />
        </IconButton>
        <InvoicePreloader isLoading={isPending} />
        {isPending ? null : (
          <div style={{ maxWidth: 650 }} className="mx-auto">
            <Paper className="py-5 mb-1" ref={printRef}>
              <div className="d-flex justify-content-center mb-2">
                <img src={Logo} alt="Jureb" />
              </div>
              <div className="text-center fw-medium fs-4 mb-4">
                Transaction Details
              </div>

              <div className="d-flex border-bottom p-3">
                <div className="col-6">
                  <div className="fw-medium text-primary">Payment ID</div>
                </div>
                <div className="col-6">
                  <div>{invoiceData?.paymentId}</div>
                </div>
              </div>
              <div className="d-flex border-bottom p-3">
                <div className="col-6">
                  <div className="fw-medium text-primary">Company Name</div>
                </div>
                <div className="col-6">
                  <div>{invoiceData?.organizationMeta?.name}</div>
                </div>
              </div>
              <div className="d-flex border-bottom p-3">
                <div className="col-6">
                  <div className="fw-medium text-primary">Plan</div>
                </div>
                <div className="col-6">
                  <div>{invoiceData?.subscriptionMeta?.planName}</div>
                </div>
              </div>
              <div className="d-flex border-bottom p-3">
                <div className="col-6">
                  <div className="fw-medium text-primary">
                    Subscription Period
                  </div>
                </div>
                <div className="col-6">
                  <div>{invoiceData?.subscriptionMeta?.billingInterval}</div>
                </div>
              </div>
              <div className="d-flex border-bottom p-3">
                <div className="col-6">
                  <div className="fw-medium text-primary">Transaction Date</div>
                </div>
                <div className="col-6">
                  <div>
                    {moment(invoiceData?.paidAt).format("Do MMM, YYYY")}
                  </div>
                </div>
              </div>
              <div className="d-flex border-bottom p-3">
                <div className="col-6">
                  <div className="fw-medium text-primary">Amount Charged</div>
                </div>
                <div className="col-6">
                  <div> {moneyFormat(invoiceData?.amountPaid)}</div>
                </div>
              </div>
            </Paper>
            <div className="mb-5 p-3 bg-light border rounded text-center">
              <ReactToPrint
                trigger={() => (
                  <Button startIcon={<Print />}>Print Receipt</Button>
                )}
                content={() => printRef.current}
              />
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

function InvoicePreloader({ isLoading }) {
  if (isLoading)
    return (
      <Paper style={{ maxWidth: 650 }} className="mx-auto py-5 mb-5">
        <div className="d-flex justify-content-center mb-2">
          <img src={Logo} alt="Jureb" />
        </div>
        <div className="text-center fw-medium fs-4 mb-4">
          Transaction Details
        </div>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
          <div key={item} className="d-flex border-bottom p-3">
            <div className="col-6">
              <Skeleton variant="text" width={100} />
            </div>
            <div className="col-6">
              <Skeleton variant="text" width={100} />
            </div>
          </div>
        ))}
      </Paper>
    );
  return null;
}

export default SingleInvoice;
